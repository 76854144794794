import React from 'react';
import {useTranslation} from 'react-i18next';
import {TextField} from '@material-ui/core';
import {IReportingUpdate} from '../../ReportingCampaignCard';
import {Platforms} from '../../../../../../model/platform/Platforms';

interface Props {
    updateData: IReportingUpdate;
    isAgency?: boolean;
    isButtonDisabled?: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, isNumber: boolean) => void;
    socialPlatform?: Platforms;
}

export const reportingFields = ['views', 'reach', 'impressions', 'likes', 'comments', 'clicks', 'stickerEngagements', 'swipeUpLinks', 'shares', 'saves', 'watchTime', 'dislikes', 'nbPublishedPosts'];

const ReportingBody = ({
                           handleChange,
                           isButtonDisabled = false,
                           updateData,
                           socialPlatform,
                           isAgency = false,
                       }: Props) => {
    const {t} = useTranslation();
    return (
        <div className={'my-3'}>
            <h5>{t('influencer.reporting.automaticReporting')}</h5>
            <div className="d-flex align-items-center flex-wrap" style={{columnGap: "12px"}}>
                {reportingFields?.filter(field => socialPlatform?.[field as unknown as keyof Platforms])?.map((field) =>
                    <div className="px-0 w-md-fit" style={{width: 'calc(50% - 6px)'}}>
                        <TextField
                            key={field}
                            name={field}
                            required
                            value={updateData?.[field as keyof IReportingUpdate]}
                            className=" my-2"
                            onChange={(event) => handleChange(event as React.ChangeEvent<HTMLInputElement>, true)}
                            label={t(`influencer.reporting.${field}`)}
                            type="number"
                            InputProps={{inputProps: {min: 0}}}
                            disabled={isButtonDisabled}
                            variant="outlined"
                            size="small"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReportingBody;
