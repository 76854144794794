import React, {SetStateAction} from 'react';
import {Col, Row} from 'react-bootstrap';
import {TermsOfServiceAlert} from '../../../shared/components/alert/TermsOfServiceAlert';
import {Pagination} from '../../../shared/components/Pagination';
import {IMyCampaignsFilterProps} from '../campaign/dto/IMyCampaigns';
import ExploreOffersCard from './components/desktop/ExploreOffersCard';
import PlannerReports from './components/desktop/PlannerReportsCard';
import SupportDashboardCard from './components/desktop/SupportDashboardCard';
import {IMyOffers} from './dto/IDashboard';
import CreatorSuccessCard from '../../agency/influencers/components/CreatorSuccessCard';
import {ICampaignOverview} from '../../../model/briefing/overview/ICampaignOverview';
import {ContractList} from './components/desktop/ContractList';
import OfferList from './components/desktop/OfferList';
import {IOfferWithCampaign} from '../profile/profile-view';
import {InvoiceDetailAlert} from '../../../shared/components/alert/InvoiceDetailAlert';
import InvoiceCard from './components/desktop/InvoiceCard';

interface IDesktopDashboard {
    myOffers: IMyOffers;
    offers?: IOfferWithCampaign[];
    contractCampaigns?: ICampaignOverview[];
    filterParams: IMyCampaignsFilterProps;
    setFilterParams: React.Dispatch<SetStateAction<IMyCampaignsFilterProps>>;
    totalPages: number;
    handlePages: (updatePage: number) => void;
    isAgency?: boolean;
    isInvoiceModalOpen?: boolean;
    setIsInvoiceModalOpen?: React.Dispatch<SetStateAction<boolean>>;


}

const DesktopDashboard = ({
                              myOffers,
                              offers,
                              setFilterParams,
                              filterParams,
                              handlePages,
                              totalPages,
                              isAgency,
                              contractCampaigns,
                              isInvoiceModalOpen,
                              setIsInvoiceModalOpen,
                          }: IDesktopDashboard) => {
    return (
        <div className="d-none d-md-block">
            <Row>
                <TermsOfServiceAlert/>
                <InvoiceDetailAlert isBillingModalOpened={isInvoiceModalOpen}
                                    setIsBillingModalOpened={setIsInvoiceModalOpen}/>
                <Col xl={3} className="d-flex">
                    <ExploreOffersCard myOffers={myOffers as IMyOffers}/>
                </Col>
                <Col xl={5} className="mt-3 mt-xl-0 d-flex">
                    <PlannerReports myOffers={myOffers as IMyOffers} filterParams={filterParams}
                                    setFilterParams={setFilterParams}/>
                </Col>
                <Col sm={6} xl={2} className="mt-3 mt-xl-0">
                    <InvoiceCard isAgency={!!isAgency} myOffers={myOffers}/>
                </Col>
                <Col sm={6} xl={2} className="mt-3 mt-xl-0">
                    {isAgency ?
                        <CreatorSuccessCard isOnDashboard={true}/> :
                        <SupportDashboardCard/>
                    }
                </Col>
                {!!contractCampaigns?.length &&
                    <Col xs={12} className="mt-4 pr-0">
                        <ContractList campaigns={contractCampaigns}/>
                    </Col>
                }
                <Col xs={12} className="mt-4 pr-0">
                    <OfferList offers={offers} isAgency={isAgency} filterParams={filterParams}
                               setFilterParams={setFilterParams}/>
                </Col>
            </Row>
            {totalPages > 1 && (
                <Pagination
                    page={filterParams.page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                />
            )}
        </div>
    );
};

export default DesktopDashboard;
