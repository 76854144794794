import React, {useState} from 'react';
import Header from '../shared/layout/components/header/Header';
import {Container} from 'react-bootstrap';
import LeftSideMenu from '../shared/layout/components/menu/LeftSideMenu';
import {useLocation} from 'react-router-dom';
import {MessageBubble} from '../shared/components/message/MessageBubble';
import {useSelector} from 'react-redux';
import {IAllStates} from '../store/rootReducer';
import InfluencerHeader from '../shared/layout/components/header/InfluencerHeader';
import {CampaignHeader} from './brand/campaign/components/CampaignHeader';
import {UserRoles} from '../enum/UserRoles';
import {AgencyHeader} from './agency/components/AgencyHeader';
import {useMediaQuery} from '@mui/material';
import {BootstrapBreakpoints} from '../enum/BootstrapBreakpoints';

interface LayoutItems {
    children: JSX.Element;
    campaignTabs?: JSX.Element;
}

export function Layout({children}: LayoutItems) {
    const [isProfileSideBarOpen, setProfileSideBar] = useState(false);
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    const {pathname} = useLocation();
    const isMobile = useMediaQuery(`(max-width: ${BootstrapBreakpoints.SM}px)`);
    const {BRAND, BRAND_MASTER, AGENCY, AGENCY_MASTER} = UserRoles;
    const isMessageScreen = pathname?.includes('brand/campaign/messages/') || pathname?.includes('influencer/messages') || pathname?.includes('agency/messages');
    const isCampaignOpen = pathname?.includes('brand/campaign');
    const containerRoutes = ['/influencer/offers', '/influencer/explore', '/settings', '/agency/explore', '/agency/offers'];
    const isContainerFluid = containerRoutes.includes(pathname);
    const brandMobile = (userType === BRAND_MASTER || userType === BRAND) && isMobile;
    const agencyDisabledRoutes = ['/settings/edit', '/agency/influencer/profile'];
    const hideAgencyHeader = agencyDisabledRoutes.some(item => pathname.includes(item));
    return (
        <>
            <Header
                isProfileSideBarOpen={isProfileSideBarOpen}
                setProfileSideBar={setProfileSideBar}
            />
            {userType === 'influencer' && <InfluencerHeader/>}
            {isCampaignOpen && <CampaignHeader/>}
            {([AGENCY, AGENCY_MASTER].includes(userType as UserRoles) && !hideAgencyHeader) && <AgencyHeader/>}
            <Container fluid className="d-flex pl-0 pr-0">
                <LeftSideMenu/>
                <Container fluid={!isContainerFluid}
                           className={`pt-md-${isMessageScreen ? '0' : '5'} pt-3 pb-5 mb-5 p-xl-4 mb-md-0`}
                           onClick={() => setProfileSideBar(false)}
                >
                    {children}
                </Container>
            </Container>
            {(!isMessageScreen && !brandMobile) && <MessageBubble/>}
        </>
    );
};


