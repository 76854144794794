import axios, {CancelTokenSource} from 'axios';
import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Form, Modal, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {PlannerStatusEnum} from '../../../../../enum/PlannerStatusEnum';
import {IPicture} from '../../../../../model/shared/IPicture';
import {ConfirmationModal} from '../../../../../shared/components/alert/ConfirmationModal';
import {ErrorToast, SuccessToast} from '../../../../../utils/toasters';
import ReportingFooterButtons from '../../../../agency/reporting/components/ReportingFooterButtons';
import UploadingModal from '../../../planner/components/UploadingModal';
import {InfluencerPlannerService} from '../../../planner/service';
import {InfluencerReportingService} from '../../service';
import {IReportingUpdate, ISelectedEvent} from '../ReportingCampaignCard';
import ReportingBody, {reportingFields} from './components/ReportingBody';
import ReportingHeader from './components/ReportingHeader';
import ReportingLeftCard from './components/ReportingLeftCard';
import ReportingScreenshotExample from './components/ReportingScreenshotExample';
import ReportingScreenshots from './components/ReportingScreenshots';
import YourContent from './components/YourContent';

interface IReportingModalProps {
    isAdditional: boolean;
    isModalOpened: boolean;
    handleCloseModal: () => void;
    selectedOfferItem: ISelectedEvent;
    setSelectedOfferItem: React.Dispatch<SetStateAction<ISelectedEvent | undefined>>;
    setUpdateReports: React.Dispatch<SetStateAction<boolean>>;
}

const initUpdateState = {
    clicks: 0,
    campaignId: 0,
    comments: 0,
    eventId: 0,
    likes: 0,
    offerItemId: 0,
    reach: 0,
    saves: 0,
    shares: 0,
    postingDate: '',
    postingLink: '',
    stickerEngagements: 0,
    swipeUpLinks: 0,
    views: 0,
    watchTime: 0,
};

const ReportingModal = ({
                            isAdditional,
                            isModalOpened,
                            handleCloseModal,
                            selectedOfferItem,
                            setSelectedOfferItem,
                            setUpdateReports,
                        }: IReportingModalProps) => {
    const {t} = useTranslation();
    const [uploadData, setUploadData] = useState({
        loaded: 0,
        from: 0
    });
    const [selectedFile, setSelectedFile] = useState<IPicture>();
    const [isAttachmentClicked, setIsAttachmentClicked] = useState(false);
    const statusesToDisplayButton = [PlannerStatusEnum.REPORTED, PlannerStatusEnum.APPROVED, PlannerStatusEnum.FEEDBACK_LOOP];
    const [updateData, setUpdateData] = useState<IReportingUpdate>(initUpdateState);
    const [source, setSource] = useState<undefined | CancelTokenSource>(axios.CancelToken.source());
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [errorFile, setErrorFile] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const isVerified = selectedOfferItem?.event?.isVerified;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, isNumber = true) => {
        const {name, valueAsNumber, value} = event.target;
        setUpdateData((prev) => ({...prev, [name]: isNumber ? valueAsNumber : value}));
    };

    const cancelApiCall = () => {
        source?.cancel('API request cancelled');
        setSource(undefined);
    };
    const progressHandler = (e: ProgressEvent) => {
        setUploadData({
            loaded: e.loaded,
            from: e.total
        });
    };

    const updateReportingAction = async () => {
        const formData = new FormData();
        const isUpdate = selectedOfferItem?.event?.status === PlannerStatusEnum.REPORTED;
        const reportItemId = selectedOfferItem?.event?.reportItems?.[0]?.id ?? 0;
        formData.append('body', JSON.stringify({...updateData, reportItemId}));
        if (!!updateData?.file?.length) { // check if it's update and have files in arr
            for (const item of updateData?.file) {
                formData.append('files', (item as string | Blob));
            }
        } else {
            setErrorFile(true);
            return;
        }

        if (!!updateData?.attachments?.length) {
            for (const item of updateData?.attachments) {
                formData.append('attachments', (item as string | Blob));
            }
        }
        setIsFileUploading(true);
        await InfluencerReportingService.manipulateInfluencerReporting(formData, isUpdate ? 'put' : 'post', source?.token as any, (e: ProgressEvent) => progressHandler(e)).then(response => {
            if (response === '') {
                handleCloseModal();
                setUpdateReports(prev => !prev);
                setSelectedOfferItem(undefined);
                setUpdateData(initUpdateState);
                SuccessToast(t('influencer.reporting.successUpdatedReporting'));
            }
        }).catch(error => ErrorToast(error)).finally(() => setIsFileUploading(false));
    };
    const handleSubmitData = async (event: React.FormEvent) => {
        event.preventDefault();
         if (!isVerified) {
             return updateReportingAction();
         }
        if (!updateData?.file?.length) {
            setErrorFile(true);
            return;
        }
        setShowConfirmModal(true);
    };
    const getReportItemValue = () => {
        const storage: any = {};
        reportingFields.forEach(reportingKey => {
            storage[reportingKey] = (selectedOfferItem?.event?.reportItems[0] as any)?.[reportingKey];
        });
        return storage;
    };
    const deleteImageHandler = async () => {
        if (isAttachmentClicked) {
            await InfluencerPlannerService.deleteAttachment(selectedFile?.id ?? 0).then(response => {
                SuccessToast(t('influencer.reporting.successDeleteImg'));
                setIsAttachmentClicked(false);
                setUpdateReports(prev => !prev);
            }).catch(error => ErrorToast(error));
        } else {
            await InfluencerReportingService.deleteReportingImage(selectedFile?.id ?? 0).then(response => {
                SuccessToast(t('influencer.reporting.successDeleteImg'));
                setUpdateReports(prev => !prev);
                setSelectedOfferItem((prev: any) => ({
                    ...prev,
                    event: {
                        ...prev?.event,
                        reportItems: [
                            response,
                        ],
                    },
                }));
            }).catch(error => ErrorToast(error));
        }
    };

    useEffect(() => {
        if (!!selectedOfferItem?.event?.id && !isAdditional) {
            setUpdateData(prev => ({
                ...prev,
                campaignId: selectedOfferItem?.campaign?.id,
                eventId: selectedOfferItem?.event?.id,
                offerItemId: selectedOfferItem?.offerItem?.id,
                postingDate: selectedOfferItem?.event?.postingDate,
                postingLink: selectedOfferItem?.event?.postingLink,
                ...getReportItemValue(),
            }));
        }
        // eslint-disable-next-line
    }, [selectedOfferItem?.event?.id, isAdditional]);

    useEffect(() => {
        if (!selectedOfferItem?.event) {
            setUpdateData(prev => ({...prev, file: []}));
        }
        setErrorFile(false);
        // eslint-disable-next-line
    }, [selectedOfferItem]);

    const isFeedbackLoopDisabled = selectedOfferItem?.campaign?.isReviewed;
    const isFormDisabled = isFeedbackLoopDisabled && !statusesToDisplayButton?.includes(selectedOfferItem?.event?.status as PlannerStatusEnum);
    return (
        <>
            <UploadingModal show={isFileUploading} uploadData={uploadData} closeModal={() => setIsFileUploading(false)}
                            cancelApiCall={cancelApiCall}/>
            <Modal
                show={isModalOpened}
                centered
                size="xl"
                onHide={handleCloseModal}
                keyboard={false}
            >
                <Form onSubmit={handleSubmitData}>
                    {selectedOfferItem?.event && (
                        <Modal.Body>
                            <Row className={'overflow-auto max-h-60vh-sm'}>
                                <ReportingHeader updateData={updateData} selectedOfferItem={selectedOfferItem}/>
                                <ReportingLeftCard selectedOfferItem={selectedOfferItem} updateData={updateData}
                                                   setUpdateData={setUpdateData}/>

                                <div className="col-md-9">
                                    <ReportingScreenshotExample
                                        classes={'w-fit-content px-md-5'}
                                        platformItemId={selectedOfferItem?.offerItem?.socialPlatform?.id}
                                    />
                                    <ReportingScreenshots
                                        setSelectedFile={setSelectedFile as React.Dispatch<SetStateAction<IPicture>>}
                                        status={selectedOfferItem?.event?.status}
                                        isButtonDisabled={isFormDisabled}
                                        setUpdateData={setUpdateData}
                                        errorFile={errorFile}
                                        reportItems={selectedOfferItem?.event?.reportItems}
                                    />
                                    {selectedOfferItem?.event?.status === PlannerStatusEnum.REPORTED && selectedOfferItem?.event?.isVerified &&
                                        <ReportingBody
                                            isButtonDisabled={false}
                                            socialPlatform={selectedOfferItem?.event?.offerItem?.socialPlatform}
                                            updateData={updateData} handleChange={handleChange}/>}

                                    <YourContent selectedAttachments={selectedOfferItem?.event?.attachments}
                                                 offerEvent={selectedOfferItem?.event}
                                                 eventId={selectedOfferItem?.event?.id} isUploadPossible
                                                 setSelectedOfferItem={setSelectedOfferItem}
                                                 setUpdateData={setUpdateData}
                                                 hideExternalSearch={isFeedbackLoopDisabled}
                                                 showUpload={!isFeedbackLoopDisabled}
                                                 isFeedbackLoop={isFeedbackLoopDisabled}
                                                 setIsAttachmentClicked={setIsAttachmentClicked}
                                                 setSelectedFile={setSelectedFile as React.Dispatch<SetStateAction<IPicture>>}
                                                 classes={'px-0'}
                                                 isButtonDisabled={isFormDisabled}
                                    />
                                </div>
                            </Row>
                            <div
                                className="border-top d-flex align-items-center justify-content-end d-md-none">
                                <div className="pt-3">
                                    <ReportingFooterButtons statusesToDisplayButton={statusesToDisplayButton}
                                                            handleCloseModal={handleCloseModal}
                                                            isFeedbackLoopDisabled={isFeedbackLoopDisabled}
                                                            selectedOfferItem={selectedOfferItem}/>
                                </div>
                                
                            </div>
                        </Modal.Body>
                    )}
                    <Modal.Footer className={'d-none d-md-flex'}>
                        <ReportingFooterButtons statusesToDisplayButton={statusesToDisplayButton}
                                                handleCloseModal={handleCloseModal}
                                                isFeedbackLoopDisabled={isFeedbackLoopDisabled}
                                                selectedOfferItem={selectedOfferItem}/>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ConfirmationModal
                title={t('influencer.reporting.confirm_update_reporting')}
                description={t('influencer.reporting.confirm_update_reporting_desc')}
                show={showConfirmModal}
                closeModal={() => setShowConfirmModal(false)}
                action={updateReportingAction}
                iconUrl="/icons/placeholders/upload.svg"
            />
            <ConfirmationModal
                title={t('influencer.planner.deleteImage')}
                description={`${t('influencer.planner.deleteImageDesc')} ${selectedFile?.externalLink ?? selectedFile?.title ?? selectedFile?.originalName}`}
                action={deleteImageHandler}
                show={!!selectedFile?.id} closeModal={() => setSelectedFile(undefined)}
            />
        </>
    );
};

export default ReportingModal;
