import {TextField} from '@material-ui/core';
import _debounce from 'lodash/debounce';
import React, {SetStateAction, useEffect, useState} from 'react';
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Campaign} from '../../../../../../model/campaign/Campaign';
import {CustomAutocomplete} from '../../../../../../shared/components/Autocomplete';
import {IAllStates} from '../../../../../../store/rootReducer';
import {ErrorToast} from '../../../../../../utils/toasters';
import {IMessageQuery} from '../../dto/IMessage';
import {MessageService} from '../../service';
import {UserRoles} from '../../../../../../enum/UserRoles';
import {getCorrectFilterKeys} from '../../../../../../shared/functions/Functions';
import {ThreadTypeEnum} from '../../../../../../store/enums/enumsRedux';

interface IMessageFiltersProps {
    filterParams: IMessageQuery;
    count: {
        count: number;
        nbUnreadThreads: number;
    };
    setFilterParams: React.Dispatch<SetStateAction<IMessageQuery>>;
}

export interface ILocation {
    campaignId: number;
    isAgencyCreator?: boolean;
}

export const MessageFilters = ({filterParams, setFilterParams, count}: IMessageFiltersProps) => {
    const {t} = useTranslation();
    const {state} = useLocation();
    const history = useHistory();
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    const {AGENCY, INFLUENCER, AGENCY_MASTER} = UserRoles;
    const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
    const changeFilterHandler = _debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterParams((prev) => ({...prev, [event.target.name]: event.target.value?.trim()}));
    }, 200);

    const changeCampaignHandler = (paramKey: string, paramValue: string | string[] | number) => {
        setFilterParams(prev => ({...prev, campaignId: Boolean(paramValue) ? +paramValue : '', title: ''}));
    };


    const changeMessageFilter = (event: React.MouseEvent<HTMLDivElement>) => {
        const eventName = event.currentTarget.id;
        if (eventName === 'all') {
            setFilterParams((prev) => ({...prev, isSent: false, isDeleted: false, isUnread: false}));
        } else if (eventName === 'isSent') {
            setFilterParams((prev) => {
                return (
                    {...prev, [eventName]: !(prev as any)[eventName], isDeleted: false, isUnread: false}
                );
            });
        } else if (eventName === 'isDeleted') {
            setFilterParams((prev) => {
                return (
                    {...prev, [eventName]: !(prev as any)[eventName], isSent: false, isUnread: false}
                );
            });
        } else {
            setFilterParams((prev) => {
                return (
                    {...prev, [eventName]: true, isSent: false, isDeleted: false}
                );
            });
        }
    };
    useEffect(() => {
        if (userType === INFLUENCER || userType === AGENCY || userType === AGENCY_MASTER) {
            MessageService.getInfluencerCampaigns({
                ...filterParams,
                campaignId: filterParams?.campaignId || (state as ILocation)?.campaignId || ''
            }).then(response => {
                setCampaigns(response.data);
                if ((state as ILocation)?.campaignId || filterParams?.campaignId) {
                    setSelectedCampaign(response?.data?.find(campaign => campaign.id === (+(state as ILocation)?.campaignId) || Number(filterParams?.campaignId)))
                } else {
                    setSelectedCampaign(undefined);
                }
                history.replace({});

            }).catch((error) => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [userType, JSON.stringify(filterParams)]);
    return (
        <Row className="row w-100 align-items-center m-0 p-2 bg-light">
            <Col md={12} className="d-flex align-items-center flex-column p-0">
                <div className="d-flex align-items-center justify-content-between mb-3 w-100">
                    <div className="d-flex align-items-center flex-wrap">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-bottom`}
                                >
                                    {t('brand.campaign.messages.inbox')}
                                </Tooltip>
                            }
                        >
                            <div
                                className={`inbox-button my-2 mr-2 ${(!filterParams?.isSent && !filterParams?.isDeleted && !filterParams?.isUnread) ? 'active' : ''}`}
                                onClick={changeMessageFilter} id="all">
                                <i className="fi fi-rr-inbox-in"/>
                                {!filterParams?.isSent && !filterParams?.isDeleted && !filterParams?.isUnread &&
                                    <span className="pl-1">
                                ({count?.count})
                            </span>}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-bottom`}
                                >
                                    {t('brand.campaign.messages.unread')}
                                </Tooltip>
                            }
                        >
                            <div className={`inbox-button m-0 mr-2 ${filterParams?.isUnread ? 'active' : 'unread'}`}
                                 onClick={changeMessageFilter}
                                 id="isUnread">
                                <i className="fi fi-rr-eye-crossed"/>
                                {filterParams?.isUnread &&
                                    <span className="pl-1">
                                    ({count?.nbUnreadThreads})
                                </span>
                                }
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-bottom`}
                                >
                                    {t('brand.campaign.messages.sent')}
                                </Tooltip>
                            }
                        >
                            <div className={`inbox-button m-0 mr-2 ${filterParams?.isSent ? 'active' : ''}`}
                                 onClick={changeMessageFilter}
                                 id="isSent">
                                <i className="fi fi-rr-inbox-out"/>
                                {filterParams?.isSent &&
                                    <span className="pl-1">
                                ({count?.count})
                            </span>}
                            </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-bottom`}
                                >
                                    {t('brand.campaign.messages.deleted')}
                                </Tooltip>
                            }
                        >
                            <div className={`inbox-button m-0 mr-2 ${filterParams?.isDeleted ? 'active' : ''}`}
                                 onClick={changeMessageFilter}
                                 id="isDeleted">
                                <i className="fi fi-rr-trash-can-list"/>
                                {filterParams?.isDeleted &&
                                    <span className="pl-1">
                                ({count?.count})
                            </span>}
                            </div>
                        </OverlayTrigger>
                    </div>


                </div>
                {((userType === INFLUENCER || userType === AGENCY || userType === AGENCY_MASTER) && (!filterParams?.types?.includes(ThreadTypeEnum.AGENCY_CREATORS))) &&
                    <CustomAutocomplete dataArray={campaigns} isWithImage={false} inputLabel="title" classes="mb-2"
                                        disableClearable={false}
                                        isMultiple={false} changeEventKey="title" setFilterState={setFilterParams}
                                        key={selectedCampaign?.id}
                                        queryFilterKey="title" defaultArray={selectedCampaign as unknown as Campaign[]}
                                        inputLabelTranslation={t('brand.campaign.messages.chooseCampaign')}
                                        handleChangeParams={changeCampaignHandler}/>}
                <TextField
                    label={`${t('brand.campaign.messages.searchConversations')}`}
                    variant="outlined"
                    onChange={changeFilterHandler}
                    name="keyPhrase"
                    size="small"
                    fullWidth
                    className="my-2 my-md-0"
                />
            </Col>
        </Row>
    );
};
