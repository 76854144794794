import {createSlice} from '@reduxjs/toolkit';
import {ICampaignOverview} from '../../model/briefing/overview/ICampaignOverview';
import {IOfferItemsRequest} from '../../model/offer/IOfferItems';
import {User} from '../../model/user/User';
import { CampaignStatusEnum } from '../../enum/CampaignStatusEnum';

export interface IInitialChildOffer {
    price: number;
    offerItems: IOfferItemsRequest[];
}
export interface IChildOfferStore {
    selectedUser?: User;
    activeTabModal: string;
    campaign?: ICampaignOverview;
    campaignId: number;
    userId: number;
    isEditable?: boolean;
    user?: User;
    initialChildOffer: IInitialChildOffer;
    canWriteComment?: boolean;
    isCharityOrPrivate: boolean;
    isCharity: boolean;
    isTransparentPricing: boolean;
    hasUnreadComments: boolean;
    canBeDeleted: boolean;
}

const initialChildOfferState: IChildOfferStore = {
    selectedUser: undefined,
    activeTabModal: '0',
    campaign: undefined,
    user: undefined,
    userId: 0,
    campaignId: 0,
    isEditable: true,
    initialChildOffer: {
        price: 0,
        offerItems: [],
    },
    canWriteComment: false,
    isCharityOrPrivate: false,
    isCharity: false,
    isTransparentPricing: false,
    hasUnreadComments: false,
    canBeDeleted: false,
};

export const childOfferBrand = createSlice({
    name: 'childOfferBrand',
    initialState: initialChildOfferState,
    reducers: {
        setInitialState: (state, action) => {
            return initialChildOfferState;
        },
        setActiveTab: (state, action) => {
            const activeTabModal = action.payload;
            if (activeTabModal === '4') {
                return {...state, activeTabModal, hasUnreadComments: false};
            }
            return {...state, activeTabModal};
        },
        setCampaignId: (state, action) => {
            const campaignId = action.payload;
            return {...state, campaignId};
        },
        setUserId: (state, action) => {
            const userId = action.payload;
            return {...state, userId};
        },

        setOfferModalDetails: (state, action) => {
            const campaign: ICampaignOverview = action.payload;
            const offerItems: IOfferItemsRequest[] = [];
            campaign.offerPlaceholder?.totalPlatforms?.map((platform => (
                platform.offerItems.map((item) => (
                    offerItems.push(
                        {
                            mainPlatformId: platform.id,
                            socialPlatformId: item.socialPlatform.id,
                            nbPosts: item.nbPosts ?? 0,
                            price: item.singlePostPrice,
                            // in this case old price I replaced with single post price,
                            // because I will never have old price and need singlePostPrice for calculations,
                            // and for nbPosts multiplying for prices.
                            oldPrice: item.singlePostPrice,
                        })
                ))
            )));
            return {
                ...state,
                campaign: action.payload,
                isEditable: campaign?.offerPlaceholder?.flags?.isEditable,
                initialChildOffer: {
                    ...state.initialChildOffer,
                    offerItems,
                },
                isCharityOrPrivate: (campaign?.status === CampaignStatusEnum.PRIVATE || campaign?.isCharity) ? true : false,
                isCharity: campaign?.isCharity,
                canWriteComment: campaign?.offerPlaceholder?.flags?.isEditable,
                isTransparentPricing: campaign?.isTransparentPricing,
                hasUnreadComments: campaign?.offers[0]?.hasUnreadComments,
                canBeDeleted: campaign?.offerPlaceholder?.flags?.canBeDeleted,
            };
        },
        setOfferModalStatus: (state, action) => {
            const offerModalStatus = action.payload;
            return {...state, offerModalStatus};
        },
        setProfileInfo: (state, action) => {
            const user = action.payload;
            return {...state, user};
        },
        setOfferItems: (state, action) => {
            const offerItems = action.payload;
            return {...state, initialChildOffer: {...state.initialChildOffer, offerItems}};
        },
        setSelectedUser: (state, action) => {
            return {...state, selectedUser: action.payload};
        },
    },
});

export const {
    setInitialState,
    setActiveTab,
    setOfferModalDetails,
    setOfferModalStatus,
    setCampaignId,
    setUserId,
    setOfferItems,
    setProfileInfo,
    setSelectedUser,
} = childOfferBrand.actions;
