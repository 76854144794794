import React from 'react';
import CardLayout from '../../../../shared/components/card/CardLayout';
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {IPerformanceSummary, IReportingTasksSummary} from '../dto/IReporting';
import {FiInfo} from 'react-icons/fi';
import {useTranslation} from 'react-i18next';
import {numberFormatter} from '../../../../utils/helpers/numberFormater';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';

interface ReportingOverviewProps {
    reportingTasks: IReportingTasksSummary;
    performance: IPerformanceSummary;
}

const ReportingOverview = ({reportingTasks, performance}: ReportingOverviewProps) => {
    const {t} = useTranslation();
    return (
        <CardLayout>
            <Row>
                <Col className="d-flex justify-content-between align-items-center border-right-lg px-0 pr-lg-2" lg={4}>
                    <div className="px-1">
                        <h5 className="mb-0 text-primary">{reportingTasks?.totalCampaigns ?? 0}</h5>
                        <span>{t('influencer.reporting.campaigns')}</span>
                    </div>
                    <div className="px-1">
                        <h5 className="mb-0 text-primary">{reportingTasks?.reportsDue ?? 0}/{reportingTasks?.todoReportsDue ?? 0}</h5>
                        <div className="d-flex align-items-center">
                            {t('influencer.reporting.reportsDue')}
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="reportsDue-tooltip">
                                                {t(`influencer.reporting.reportsDueTooltip`)}
                                            </Tooltip>}>
                                <FiInfo size={14} className="cursor-pointer ml-1"/>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="px-1">
                        <h5 className="mb-0 text-primary">{reportingTasks?.placements ?? 0}</h5>
                        <div className="d-flex align-items-center">
                            {t('influencer.reporting.placements')}
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="placements-tooltip">
                                                {t(`influencer.reporting.placementsTooltip`)}
                                            </Tooltip>}>
                                <FiInfo size={14} className="cursor-pointer ml-1"/>
                            </OverlayTrigger>
                        </div>
                    </div>

                </Col>
                <Col className="d-flex flex-lg-row flex-wrap justify-content-between align-items-center px-0 mt-lg-0 mt-3 pl-lg-2" lg={8}>
                    <div className="px-1">
                        <h5 className="mb-0 text-primary">
                            {numberFormatter(performance?.touchPoints ?? 0)}
                        </h5>
                        <div className="d-flex align-items-center">
                            {t('influencer.reporting.touchpoints')}
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="touchpoints-tooltip">
                                                {t(`influencer.reporting.touchpointsTooltip`)}
                                            </Tooltip>}>
                                <FiInfo size={14} className="cursor-pointer ml-1"/>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="px-1">
                        <h5 className="mb-0 text-primary">{priceSplitter(performance?.impressions) ?? 0}</h5>
                        <div className="d-flex align-items-center">
                            {t('influencer.reporting.impressions')}
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="impressions-tooltip">
                                                {t(`influencer.reporting.impressionsTooltip`)}
                                            </Tooltip>}>
                                <FiInfo size={14} className="cursor-pointer ml-1"/>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="px-1">
                        <h5 className="mb-0 text-primary euro-symbol">{priceSplitter(performance?.cpm ?? 0)}</h5>
                        <div className="d-flex align-items-center">
                            {t('influencer.reporting.cpm')}
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="cpm-tooltip">
                                                {t(`influencer.reporting.cpmTooltip`)}
                                            </Tooltip>}>
                                <FiInfo size={14} className="cursor-pointer ml-1"/>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="px-1">
                        <h5 className="mb-0 text-primary euro-symbol">{priceSplitter(performance?.reachCpm)}</h5>
                        <div className="d-flex align-items-center">
                            {t('influencer.reporting.reachCpm')}
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="totalEarnings-tooltip">
                                                {t(`influencer.reporting.reachCpmTooltip`)}
                                            </Tooltip>}>
                                <FiInfo size={14} className="cursor-pointer ml-1"/>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="px-1">
                        <h5 className="mb-0 text-primary">{priceSplitter(performance?.clicks) ?? 0}</h5>
                        <div className="d-flex align-items-center">
                            {t('influencer.reporting.clicks_total')}
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="clicks-tooltip">
                                                {t(`influencer.reporting.clicksTooltip`)}
                                            </Tooltip>}>
                                <FiInfo size={14} className="cursor-pointer ml-1"/>
                            </OverlayTrigger>
                        </div>
                    </div>

                    <div className="px-1 pt-3 pt-md-0">
                        <h5 className="mb-0 text-primary euro-symbol">{priceSplitter(Math.round(performance?.totalEarnings) ?? 0)}</h5>
                        <div className="d-flex align-items-center">
                            {t('influencer.reporting.totalEarnings')}
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="totalEarnings-tooltip">
                                                {t(`influencer.reporting.totalEarningsTooltip`)}
                                            </Tooltip>}>
                                <FiInfo size={14} className="cursor-pointer ml-1"/>
                            </OverlayTrigger>
                        </div>
                    </div>
                </Col>
            </Row>
        </CardLayout>
    );
};

export default React.memo(ReportingOverview);