import React, {lazy, Suspense} from 'react';
import {useLocation} from 'react-router-dom';
import {ErrorBoundary} from '../../shared/components/ErrorBoundary';
import LoadingScreen from '../../shared/components/LoadingScreen';
import {Layout} from '../Layout';
import {Route, Switch, Redirect} from 'react-router';
import {AgencyMessages} from './messages';
import AgencyInfluencers from './influencers';

const UserAccount = lazy(() => import('../shared/user'));
const AgencyInvoices = lazy(() => import('./invoices'));
const AgencyDashboard = lazy(() => import('./dashboard'));
const AgencyExplore = lazy(() => import('./explore'));
const AgencyOfferManagement = lazy(() => import('./offer'));
const AgencyPlanner = lazy(() => import('./planner'));
const AgencyReporting = lazy(() => import('./reporting'));
const AgencyAllCampaigns = lazy(() => import('./campaigns'));
const AgencyBriefingDetails = lazy(() => import('./campaigns/briefing/AgencyBriefingOverview'));
const ContentCreators = lazy(() => import('./content-creators'));
const DeclinedOffers = lazy(() => import('../influencer/declined-offers'));
const InfluencerProfile = lazy(() => import('../influencer/profile/profile-view'));
const ForbiddenPage = lazy(() => import('../../authentication/403'));

export function AgencyRoutes() {
    const {pathname} = useLocation();
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Layout>
                <ErrorBoundary key={pathname}>
                        <Switch>
                            <Route path="/settings/" component={UserAccount}/>
                            <Route path="/agency/invoices" component={AgencyInvoices}/>
                            <Route path="/agency/influencers" component={AgencyInfluencers}/>
                            <Route path="/agency/dashboard" component={AgencyDashboard}/>
                            <Route path="/agency/explore" component={AgencyExplore}/>
                            <Route path="/agency/offers" component={AgencyOfferManagement}/>
                            <Route path="/agency/planner" component={AgencyPlanner}/>
                            <Route path="/agency/reporting" component={AgencyReporting}/>
                            <Route path="/agency/messages" component={AgencyMessages}/>
                            <Route path="/agency/campaigns" component={AgencyAllCampaigns}/>
                            <Route path="/agency/campaign/:id/overview" component={AgencyBriefingDetails}/>
                            <Route path="/agency/content-creators" component={ContentCreators}/>
                            <Route path="/agency/offer/declined" component={DeclinedOffers}/>
                            <Route path="/agency/influencer/profile/:id" component={InfluencerProfile}/>
                            <Route path="/agency/influencer/edit/:id" component={InfluencerProfile}/>
                            <Route path="/403" component={ForbiddenPage}/>
                            <Redirect from="/public/campaign/briefing/:uuid" to="/agency/explore"/>
                            <Redirect from="/" to="/agency/influencers"/>
                        </Switch>
                </ErrorBoundary>
            </Layout>
        </Suspense>
    );
}