import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MenuItem, TextField} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import ToggleSwitch from '../../../../shared/components/ToggleSwitch';
import {StepperButtons} from './StepperButtons';
import {IRegisterStepperProps} from './Step1';
import {IRegisterBillingInfos} from '../dto/IRegister';
import {AuthService} from '../../../service';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {useHistory} from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {validateVAT} from '../../../../shared/functions/Functions';
import {FiInfo} from 'react-icons/fi';

const initialState: IRegisterBillingInfos = {
  userId: 0,
  contact: {
    invoiceFirstName: '',
    invoiceLastName: '',
    companyName: '',
    phone: '',
    street: '',
    city: '',
    zip: '',
    province: '',
    country: '',
    smallBusiness: false,
    uidNumber: '',
    companyRegister: '',
    email: '',
  },
  bank: {
    accountCountry: '',
    accountPhone: '',
    accountBankName: '',
    accountIban: '',
    accountSwift: '',
    accountPaymentType: '',
  },
};

export const Step5 = ({stepIdNumber, handleBackStep}: IRegisterStepperProps) => {
  const {t} = useTranslation();
  const history = useHistory();
  const [currentBillings, setCurrentBillings] = useState<IRegisterBillingInfos>(initialState);
  const {country} = useSelector((states: IAllStates) => states.enums);
  const savedUser = useSelector((state: IAllStates) => state.registration);
  const [vatError, setVatError] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isBank = false) => {
    const {name, value} = event.target;
    setCurrentBillings(prev => (
      isBank ? {
        ...prev, bank: {
          ...prev.bank,
          [name]: value,
        },
      } : {
        ...prev, contact: {
          ...prev.contact,
          [name]: value,
        },
      }));
    setVatError(false);
  };

  const changePhoneHandler = (value: string) => {
    setCurrentBillings(prev => ({
      ...prev, bank: {
        ...prev?.bank,
        accountPhone: value,
      },
    }));
    setVatError(false);
  };

  const handleNextStep = () => {
    AuthService.updateUserBillingInfos(currentBillings).then(response => {
      history.push(`/brand/register/${stepIdNumber + 1}?token=${response?.verificationUUID}`);
      SuccessToast(t('auth.register.brand.step5.success'));
    }).catch(error => ErrorToast(error));
  };
  useEffect(() => {
    if (savedUser?.id) {
      const dataToSave = {
        userId: savedUser?.id,
        contact: {
          ...savedUser?.brandCompany?.contact,
          invoiceFirstName: savedUser?.brandCompany?.contact?.invoiceFirstName ?? savedUser?.firstName ?? '',
          invoiceLastName: savedUser?.brandCompany?.contact?.invoiceLastName ?? savedUser?.lastName ?? '',
          companyName: savedUser?.brandCompany?.contact?.companyName ?? savedUser?.brandCompany?.displayName ?? '',
          email: savedUser?.brandCompany?.contact?.email ?? savedUser?.email ?? '',
        },
        bank: {
          ...savedUser?.brandCompany?.bank,
          accountPhone: savedUser?.brandCompany?.bank?.accountPhone ?? savedUser?.contact?.phone ?? ''
        },
      };
      setCurrentBillings(dataToSave as IRegisterBillingInfos);
    }
  }, [savedUser]);
  function onSubmitAction(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!validateVAT(currentBillings.contact.uidNumber as unknown as string)) {
      setVatError(true);
      return;
    }
    handleNextStep();
  }
    return (
    <Form onSubmit={(e) => onSubmitAction(e)}>
      <div className="row text-left">
        <div className="col-md-6">
          <h5>{t('auth.register.brand.step4.receiver')}</h5>
          <TextField
            label={t('auth.register.brand.step2.firstName')}
            variant="outlined"
            name="invoiceFirstName"
            value={currentBillings?.contact?.invoiceFirstName ?? ''}
            onChange={changeHandler}
            size="small"
            required
            className="w-100 my-2"
          />
          <TextField
            label={t('auth.register.brand.step2.lastName')}
            variant="outlined"
            size="small"
            value={currentBillings?.contact?.invoiceLastName ?? ''}
            onChange={changeHandler}
            name="invoiceLastName"
            required
            className="w-100 my-2"
          />
          <TextField
            label={t('auth.register.brand.step4.companyName')}
            variant="outlined"
            size="small"
            value={currentBillings?.contact?.companyName ?? savedUser?.brandCompany?.displayName ?? ''}
            onChange={changeHandler}
            name="companyName"
            required
            className="w-100 my-2"
          />
          <PhoneInput
            country="de"
            placeholder={t('auth.register.brand.step2.phoneNumber')}
            value={currentBillings?.bank?.accountPhone ?? ''}
            onChange={changePhoneHandler}
            inputClass="w-100 my-2"
          />
          <TextField
            label="E-mail"
            variant="outlined"
            value={currentBillings?.contact?.email ?? ''}
            onChange={changeHandler}
            size="small"
            name="email"
            required
            type="email"
            className="w-100 my-2"
          />
          <h5 className="mt-3">{t('brand.account.billing_information_modal.billing_details')}</h5>
          <div className="d-flex align-items-center">
           <TextField
            label={t('auth.register.brand.step4.vatNumber')}
            variant="outlined"
            name="uidNumber"
            value={currentBillings?.contact?.uidNumber ?? ''}
            onChange={changeHandler}
            size="small"
            className="w-100 my-2"
            required
            error={vatError}
            helperText={vatError ? 'Incorrect input' : ''}
          />
           <OverlayTrigger placement="top"
                           overlay={<Tooltip
                               id="vat-tooltip">
                             {t(`brand.account.billing_information_modal.vat_tooltip`)}
                           </Tooltip>}>
             <FiInfo size={14} className="cursor-pointer ml-1"/>
           </OverlayTrigger>
         </div>
          {/*{currentBillings?.bank?.accountCountry === 'AT' &&*/}
          {/*    <>*/}
          {/*<div className="d-flex justify-content-between align-items-center">*/}
          {/*  <label className="mb-0" htmlFor="smallBusiness">*/}
          {/*    {t('auth.register.brand.step4.smallBusinessOwner')}*/}
          {/*  </label>*/}
          {/*  <ToggleSwitch onChange={() => setCurrentBillings(prev => ({*/}
          {/*    ...prev, contact: {*/}
          {/*      ...prev.contact,*/}
          {/*      smallBusiness: !prev.contact?.smallBusiness,*/}
          {/*    },*/}
          {/*  }))} small id="smallBusiness"*/}
          {/*                checked={!!currentBillings?.contact?.smallBusiness}/>*/}
          {/*</div>*/}
          {/*<span className="text-muted">*/}
          {/*             {t('auth.register.brand.step4.smallBusinessOwnerDescription')}*/}
          {/*                             </span>*/}
          {/*{!currentBillings?.contact?.smallBusiness &&*/}
          {/*    <TextField*/}
          {/*        label={t('auth.register.brand.step4.companyRegister')}*/}
          {/*        variant="outlined"*/}
          {/*        name="companyRegister"*/}
          {/*        value={currentBillings?.contact?.companyRegister ?? ''}*/}
          {/*        onChange={changeHandler}*/}
          {/*        size="small"*/}
          {/*        className="w-100 my-2"*/}
          {/*    />}*/}
          {/*    </>}*/}
        </div>
        <div className="col-md-6">
          <h5>{t('auth.register.brand.step4.address')}</h5>
          <TextField
            label={t('auth.register.brand.step4.street')}
            name="street"
            variant="outlined"
            required
            size="small"
            value={currentBillings?.contact?.street ?? ''}
            onChange={changeHandler}
            className="w-100 my-2"
          />
          <TextField
            label={t('auth.register.brand.step4.city')}
            name="city"
            value={currentBillings?.contact?.city ?? ''}
            onChange={changeHandler}
            variant="outlined"
            required
            size="small"
            className="w-100 my-2"
          />
          <TextField
            label={t('auth.register.brand.step4.postalCode')}
            variant="outlined"
            name="zip"
            value={currentBillings?.contact?.zip ?? ''}
            onChange={changeHandler}
            size="small"
            required
            className="w-100 my-2"
          />
          <TextField
            label={t('auth.register.brand.step4.state')}
            value={currentBillings?.contact?.province ?? ''}
            onChange={changeHandler}
            name="province"
            variant="outlined"
            required
            size="small"
            className="w-100 my-2"
          />
          <div className="my-2">
            <TextField
              value={currentBillings?.bank?.accountCountry}
              onChange={e => (changeHandler(e, true))}
              size="small"
              name="accountCountry"
              required
              label={t('general.address.country')}
              select
              variant="outlined"
              fullWidth
            >
              {country.map((countryName) => (
                <MenuItem key={countryName}
                          value={countryName}>{t(`general.countries.${countryName}`)}</MenuItem>
              ))
              }
            </TextField>
          </div>
        </div>
        <div className="col-12 mt-4">
          <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep}
                          handleBackStep={handleBackStep}/>
        </div>
      </div>
    </Form>
  );
};
