import {TextField as CustomMuiTextField} from '@material-ui/core';
import {Autocomplete, Box} from '@mui/material';
import React, {SyntheticEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {BsExclamationOctagon} from 'react-icons/bs';
import {BiRefresh} from 'react-icons/bi';
import {useSelector} from 'react-redux';
import Flag from 'react-world-flags';
import {useDebouncedCallback} from 'use-debounce';
import {IContentModal} from '../../app/brand/campaign/book/offer/dto/IContentModal';
import {IAllStates} from '../../store/rootReducer';
import {getCorrectColor} from '../functions/Functions';
import {CustomImageHandler} from './CustomImageHandler';

interface iAutocompleteProps {
    dataArray: any[];
    defaultArray?: any[] | string | number | null;
    setFilterState?: any;
    isWithImage: boolean;
    isLongSelected?: boolean;
    isMultiple?: boolean;
    inputLabel: string;
    changeEventKey?: string;
    photoLabel?: string;
    selectedStateImage?: string;
    queryFilterKey: string;
    inputLabelTranslation: string;
    classes?: string;
    handleChangeParams: (paramKey: string, paramValue: string | string[] | number) => void;
    isFreeSolo?: boolean;
    handleSpace?: any;
    hasError?: boolean;
    isChipAutocomplete?: boolean;
    disableClearable?: boolean;
    isOfferEnum?: boolean;
    isDisabled?: boolean;
    isRequired?: boolean;
    isManagingModal?: boolean;
    haveDots?: boolean;
    status?: string;
    limitTags?: number;
    isStatusLabelVisible?: boolean;
}

const statusesWithIcon = ['proposed'];

export const CustomAutocomplete = ({
                                       isManagingModal,
                                       dataArray = [],
                                       defaultArray,
                                       setFilterState,
                                       isWithImage,
                                       inputLabel,
                                       photoLabel,
                                       selectedStateImage,
                                       handleChangeParams,
                                       changeEventKey,
                                       queryFilterKey,
                                       classes,
                                       inputLabelTranslation,
                                       handleSpace,
                                       isLongSelected = false,
                                       hasError = false,
                                       isMultiple = false,
                                       isDisabled = false,
                                       isOfferEnum = false,
                                       isChipAutocomplete = false,
                                       isFreeSolo = false,
                                       disableClearable = false,
                                       isRequired,
                                       haveDots,
                                       limitTags,
                                       isStatusLabelVisible = true,
                                       status
                                   }: iAutocompleteProps) => {
    const {userType} = useSelector((state: IAllStates) => state.auth.user) ?? {};
    const {t} = useTranslation();
    const debounced = useDebouncedCallback(
        (key, value) => {
            setFilterState && setFilterState((prev: any) => ({...prev, [key]: value}));
        },
        // delay in ms
        200,
    );
    return (
        <Autocomplete
            disablePortal
            freeSolo={isFreeSolo}
            disabled={isDisabled}
            options={dataArray}
            value={defaultArray}
            limitTags={limitTags ?? 1}
            multiple={isMultiple}
            getOptionLabel={(option) => getCorrectKey(inputLabel, option, t)}
            size="small"
            disableClearable={disableClearable}
            openOnFocus={true}
            sx={{
                '.MuiOutlinedInput-root': {
                    padding: '0px 9px !important',
                },
                '.Mui-focused': {
                    padding: '9px !important',
                    paddingRight: '60px !important',
                },
                '.MuiAutocomplete-endAdornment': {
                    // top: 'auto',
                },
                '.MuiAutocomplete-tag': {
                    marginTop: '8px',
                },
            }}
            className={`w-100 ${classes ?? ''}`}
            renderOption={(props, option) => {
                return (
                    <Box
                        component="li"
                        sx={{'& > img': {mr: 1, flexShrink: 0}}}
                        {...props}
                        key={option?.key || option[changeEventKey as keyof typeof option]}
                    >
                        {photoLabel === 'countryFlag' && (
                            <div className="country-flag mr-2">
                                <Flag code={option}/>
                            </div>
                        )}
                        {(isWithImage && photoLabel !== 'countryFlag') && (
                            <CustomImageHandler
                                altTag="logo"
                                classes={`user-img-rounded ${!!isManagingModal ? 'ml-2' : ''}`}
                                photoPath={getCorrectKey(photoLabel as string, option, t) as string}
                            />
                        )}

                        {isOfferEnum && <div>
                            {statusesWithIcon.includes(option) ? (
                                <span>
                                    {option === 'proposed' ? <BsExclamationOctagon className="proposed-icon mr-1"/> :
                                        <BiRefresh className="proposed-icon text-dark mr-1"/>}
                                </span>
                            ) : (
                                <span className={`calendar-button-dot ${getCorrectColor(option)}`}/>
                            )}
                        </div>
                        }
                        {haveDots && (
                            <div
                                className={`offer-bullet dashboard-dot position-static align-middle mr-1 ${option?.offers[0]?.status === 'completed' ? 'bg-primary' : 'bg-danger'}`}></div>
                        )}
                        <span className="word-break-word align-middle">{getCorrectKey(inputLabel, option, t)}</span>

                        {((option?.offers?.[0]?.isLongTerm || option?.isLongTerm) && isStatusLabelVisible) &&
                            <div
                                className={`custom-badge long-term ml-1 ${queryFilterKey === 'platformId' && 'd-none'}`}>
                                {t(`brand.campaign.briefing.overview_card.${!userType?.includes('brand') ? 'linked' : 'long_term'}`)}
                            </div>}
                        {((option?.offers?.[0]?.isAddonsOnly || option?.isAddonsOnly) && isStatusLabelVisible) &&
                            <div
                                className={`custom-badge additional ml-1 ${queryFilterKey === 'platformId' && 'd-none'}`}>
                                {t('influencer.offer_management.modal.additional')}
                            </div>}
                    </Box>
                );
            }}
            renderInput={(params) => (
                <div style={{position: 'relative'}}>
                    {(isWithImage && selectedStateImage) && params.inputProps.value && (
                        <CustomImageHandler photoPath={selectedStateImage as string} altTag="User Profile"
                                            classes="selected-input-logo"/>
                    )}
                    {(isLongSelected) && params.inputProps.value && (
                        <div className={'custom-badge long-term ml-1 selected-long-term'}>
                            {t(`brand.campaign.briefing.overview_card.${!userType?.includes('brand') ? 'linked' : 'long_term'}`)}
                        </div>
                    )}
                    <CustomMuiTextField
                        {...params}
                        label={inputLabelTranslation}
                        onKeyDown={handleSpace}
                        required={isRequired}
                        error={hasError}
                        variant="outlined"
                        onChange={(e) => {
                            debounced(changeEventKey, e.target.value);
                        }}
                        inputProps={{
                            ...params.inputProps,
                            value: inputLabel === 'hashtag' ? (
                                typeof params?.inputProps?.value === 'string' && params?.inputProps?.value?.includes(' ')! ? params?.inputProps?.value?.replace(' ', '_') : params?.inputProps?.value
                            ) : (
                                isChipAutocomplete && (params?.inputProps?.value as string)?.includes(' ') ?
                                    '' : params.inputProps.value
                            ),
                            style: {
                                paddingLeft: `${(isWithImage && selectedStateImage) && params.inputProps?.value ? '30px' : '0px'}`,
                                paddingRight: `${!disableClearable ? '4rem' : 0}`,
                            },
                        }}
                    />
                </div>
            )}
            onChange={(_event: SyntheticEvent, selectedState: IContentModal | null) => {
                handleChangeParams(queryFilterKey, getCorrectQueryValue(selectedState, inputLabel, isMultiple, queryFilterKey));
            }}
        />
    );
};

const getCorrectKey = (value: string, obj: any, t?: any) => {
    switch (value) {
        case 'user.displayName':
            return !!obj?.user?.displayName?.length ? obj?.user?.displayName : `${obj?.user?.firstName} ${obj?.user?.lastName}`;
        case 'user.profilePhoto':
            return obj?.user?.profilePhoto?.path;
        case 'name':
            return obj?.name;
        case 'path':
            return obj?.path;
        case 'firstName':
            return `${obj?.firstName} ${obj?.lastName}`;
        case 'displayName':
            return !!obj?.displayName?.length ? obj?.displayName : `${obj?.firstName} ${obj?.lastName}`;
        case 'profilePhoto.path':
            return obj?.profilePhoto?.path;
        case 'socialPlatform.path':
            return obj?.socialPlatform?.path;
        case 'gender':
            return t(`general.gender.${obj}`);
        case 'socialPlatform.name':
            return t(`general.platforms.${obj?.socialPlatform?.name}`);
        case 'Publication Period':
            return obj ? t(`brand.campaign.briefing.step8.${obj}`) : '';
        case 'Campaign Duration':
            return t(`brand.campaign.briefing.step8.${obj}`);
        case 'country':
            return t(obj === '' ? '' : `general.countries.${obj}`);
        case 'label':
            return obj?.label;
        case 'industry':
            return !!obj ? t(`general.industry.${obj}`) : '';
        case 'nbEmployees':
            return !!obj ? t(`general.nbEmployees.${obj}`) : '';
        case 'position':
            return t(!obj ? '' : `general.positions.${obj}`);
        case 'title':
            return !!obj?.title ? obj?.title : '-';
        case 'nbCreators':
            return t(`general.nbCreators.${obj}`);
        case 'plannerStatus':
            return t(`brand.campaign.manage.${obj}`);
        case 'campaign_status':
            return t(`general.offerStatus.${obj}`);
        case 'campaign_filter_status':
            return t(`general.campaign_filter_status.${obj}`);
        case 'decline_reason':
            return t(`general.decline_reasons.${obj}`);
        case 'locale':
            return obj ? t(`general.global_preferences.${obj}`) : '';
        default:
            return obj;
    }
};

const getCorrectQueryValue = (value: any, key: string, isMultiple: boolean, queryFilterKey?: string) => {
    switch (key) {
        case 'user.displayName':
            if (queryFilterKey === 'offerId') {
                return value?.offerId;
            } else if (queryFilterKey === 'userIds') {
                const allIds = value?.map((item: any) => item?.user?.id);
                const removeDuplicates = allIds.filter((item: any, index: any) => allIds.indexOf(item) === index);
                
                return removeDuplicates;
            } else if (queryFilterKey === 'offerIds') {
                const allIds = value?.map((item: any) => item?.id);
                const removeDuplicates = allIds.filter((item: any, index: any) => allIds.indexOf(item) === index);
                
                return removeDuplicates;
            }
            return value ? value?.user.id : '';
        case 'name':
        case 'displayName':
        case 'platformId':
        case 'title':
        case 'firstName':
        case 'socialPlatform.name':
            if (queryFilterKey === 'offerId') {                
                return value?.offerId;
            }
            
            if (queryFilterKey === 'campaignIdPlanner') {                
                return value;
            }
            return isMultiple ? value?.map((value: { id: number }) => value.id) : value ? value.id : '';
        case 'audienceLocation':
            return value?.map((value: string) => value);
        case 'label':
            return value?.label;
        case 'offerId':
            return value;
        default:
            return value !== null ? value : '';
    }
};
