import AlertBox from './AlertBox';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import {AuthService} from '../../../authentication/service';
import {actions} from '../../../store/authentication/authRedux';
import {ErrorToast} from '../../../utils/toasters';
import BillingInfoModal from '../../../app/shared/user/components/BillingInfoModal';

interface IInvoiceDetailAlertProps {
    isBillingModalOpened?: boolean;
    setIsBillingModalOpened?: React.Dispatch<React.SetStateAction<boolean>>;
}
export function InvoiceDetailAlert({isBillingModalOpened, setIsBillingModalOpened}: IInvoiceDetailAlertProps) {
    const {t} = useTranslation();
    const {user: {invoiceDataAlert}} = useSelector((state: IAllStates) => state.auth);
    const dispatch = useDispatch();

    const updateInvoiceAlert = () => {
        AuthService.updateInvoiceAlert()
            .then(() => {
                if (setIsBillingModalOpened) {
                    setIsBillingModalOpened(true);
                }
                dispatch(actions.updateInvoiceAlert(false));
            })
            .catch(err => ErrorToast(err));
    };

    return (
        <>
            {invoiceDataAlert &&
                <AlertBox image="/icons/invoice-data-warning.svg"
                          hasButton
                          classCol="col-12 mb-4"
                          variant="danger"
                          title={t('influencer.dashboard.invoice_alert_title')}
                          desc={t('influencer.dashboard.invoice_alert_desc')}
                          textButton={t('general.button.check_invoice')}
                          functionBtn={updateInvoiceAlert}
                />
            }
        </>
    );
}
