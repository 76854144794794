import i18n from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProtectedRoute } from '../authentication/ProtectedRoute';
import { AuthService } from '../authentication/service';
import { UserRoles } from '../enum/UserRoles';
import LoadingScreen from '../shared/components/LoadingScreen';
import { useSearchQuery } from '../shared/hooks/useSearchQuery';
import { actions } from '../store/authentication/authRedux';
import { EnumService } from '../store/enums/enumService';
import enumsSlice from '../store/enums/enumsRedux';
import registrationSlice from '../store/register/brand/registrationSlice';
import { IAllStates } from '../store/rootReducer';
import { ErrorToast } from '../utils/toasters';
import { AgencyRoutes } from './agency/routes';
import { BrandRoutes } from './brand/routes';
import { InfluencerRoutes } from './influencer/routes';
import { ImpersonateRoutes } from './shared/impersonate/routes';
import GlobalPreferencesModal from './shared/user/components/GlobalPreferencesModal';
import { UserService } from './shared/user/service';
import { AppReloadVersionModal } from '../shared/components/AppReloadVersionModal';
import { SuspendedUserModal } from '../shared/components/alert/SuspendedUserModal';
import { PublicRoutes } from './public/routes';

function App() {
    const lang = useSearchQuery('lang');
    const { user } = useSelector((state: IAllStates) => state.auth, shallowEqual);
    const { isLoading } = useSelector((state: IAllStates) => state.loading);
    const [isGlobalPreferencesOpened, setIsGlobalPreferencesOpened] = useState(false);
    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const campaignId = sessionStorage.getItem('activeCampaignId');
    const {activeCampaignId} = useSelector((state: IAllStates) => state.selectedCampaign, shallowEqual);

    useEffect(() => {
        const fetchEnums = async () => {
            try {
                const data = await EnumService.getEnums();
                dispatch(enumsSlice.actions.setEnums(data));
            } catch (error) {
                ErrorToast(error);
            }
        };
        fetchEnums();
    }, [dispatch]);


    useEffect(() => {
        const fetchPersonalInfo = async () => {
            if (token) {
                try {
                    const data = await AuthService.getPersonalInfo({ campaignId: activeCampaignId, token: JSON.parse(token) ?? '' });
                    dispatch(actions.setUser(data));
                } catch (error) {
                    ErrorToast(error);
                }
            }
        };
        fetchPersonalInfo();
    }, [dispatch, token, activeCampaignId]);

    useEffect(() => {
        const fetchGlobalPreferences = async () => {
            if (user?.id) {
                try {
                    const response = await UserService.getGlobalPreference();
                    const areNotificationsOff = !response.isNotificationByEmailRequested && !response.isNotificationInBrowserRequested && response.isUnsubscribed && !response.isNotificationByMentionRequested;
                    const globalPreferencesPastTime = localStorage.getItem('globalPreferencesPastTime');
                    const today = new Date();
                    const savedDate = globalPreferencesPastTime ? JSON.parse(globalPreferencesPastTime) : new Date(today.setDate(today.getDate() - 31));
                    const diffTime = moment(new Date()).diff(savedDate, 'day');
                    if (areNotificationsOff && diffTime > 29) {
                        setIsGlobalPreferencesOpened(true);
                        localStorage.setItem('globalPreferencesPastTime', JSON.stringify(new Date()));
                    }
                } catch (error) {
                    ErrorToast(error);
                }
            }
            setShowSuspendedModal(user?.isSuspended ?? false);
            return () => setShowSuspendedModal(false);
        };
        fetchGlobalPreferences();
    }, [user?.id]);

    useEffect(() => {
        const languageToSave = lang || user?.locale;
        i18n.changeLanguage(languageToSave);
    }, [user, lang]);

    useEffect(() => {
        document.body.classList.toggle('influencer-side', user?.userType === UserRoles.INFLUENCER);
    }, [user?.userType]);

    useEffect(() => {
        document.title = 'App | influence.vision';
    }, []);

    useEffect(() => {
        dispatch(registrationSlice.actions.clearRegisterUser());
    }, [dispatch]);

    const renderRoutes = () => {
        switch (user?.userType) {
            case UserRoles.INFLUENCER:
                return <ProtectedRoute><InfluencerRoutes /></ProtectedRoute>;
            case UserRoles.AGENCY:
            case UserRoles.AGENCY_MASTER:
                return <ProtectedRoute><AgencyRoutes /></ProtectedRoute>;
            case UserRoles.BRAND:
            case UserRoles.BRAND_MASTER:
                return <ProtectedRoute><BrandRoutes /></ProtectedRoute>;
            default:
                return <ProtectedRoute><></></ProtectedRoute>;
        }
    };

    return (
        <>
            <GlobalPreferencesModal show={isGlobalPreferencesOpened} errorMessage={t('general.updateNotifications')}
                closeModal={() => setIsGlobalPreferencesOpened(false)} />
            <SuspendedUserModal show={showSuspendedModal} />
            <PublicRoutes />
            {renderRoutes()}
            <ImpersonateRoutes />
            <ToastContainer />
            {isLoading && <LoadingScreen />}
            <AppReloadVersionModal />
        </>
    );
}

export default App;
