import {TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import {UploadImage} from '../../../../shared/components/UploadImage';
import {IAllStates} from '../../../../store/rootReducer';
import {multipartFormData} from '../../../../utils';
import {AUTH_BRAND_URL} from '../../../../utils/endpoints';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {StepperButtons} from '../../brand/components/StepperButtons';
import {IRegisterPersonalDetails} from '../../brand/dto/IRegister';
import {IRegisterStepperProps} from './Step1';

export const Step3 = ({stepIdNumber, handleBackStep}: IRegisterStepperProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    const [currentUser, setCurrentUser] = useState<IRegisterPersonalDetails>({
        position: '',
        contact: {
            phone: '',
        },
        firstName: '',
        id: 0,
        lastName: '',
    });
    const {UserPositionEnum} = useSelector((state: IAllStates) => state.enums);
    const savedData = useSelector((state: IAllStates) => state.registration);
    const changePositionHandler = (paramKey: string, paramValue: string | string[] | number) => {
        setCurrentUser((prev: any) => ({
            ...prev,
            position: paramValue,
        }));
    };

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, isContact = false) => {
        const {name, value} = event.target;
        if (isContact) {
            setCurrentUser(prev => ({
                ...prev, contact: {
                    ...prev?.contact,
                    [name]: value,
                },
            }));
        } else {
            setCurrentUser(prev => ({...prev, [name]: value}));
        }
    };

    const changePhoneHandler = (value: string) => {
        setCurrentUser(prev => ({
            ...prev, contact: {
                ...prev?.contact,
                phone: value,
            },
        }));
    };

    const handleNextStep = () => {
        multipartFormData(files, `${AUTH_BRAND_URL}/personal-details`, JSON.stringify({
            ...currentUser,
            id: savedData?.id,
        })).then(response => {
            if (response) {
                history.push(`/brand/employee/register/${stepIdNumber + 1}?token=${response?.data?.verificationUUID}`);
                SuccessToast(t('auth.register.brand.step3.success'));
            }
        }).catch(error => ErrorToast(error));
    };

    useEffect(() => {
        if (savedData?.id) {
            setCurrentUser(savedData);
        }
        // eslint-disable-next-line
    }, [savedData?.id]);

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            handleNextStep();
        }}>
            <div className="row justify-content-center">
                <div className="col-md-7">
                    <UploadImage saveFile={setFiles}
                                 isDeleteHidden={true}
                                 previewImage={currentUser?.profilePhoto?.path}
                                 acceptedFormats="image/jpeg, image/png, image/jpg"
                                 isPreviewArray={false}
                                 isMultiple={false}/>
                </div>

                <div className="col-md-6">
                    <TextField
                        label={t('auth.register.brand.step2.firstName')}
                        variant="outlined"
                        name="firstName"
                        onChange={changeHandler}
                        size="small"
                        required
                        value={currentUser?.firstName ?? ''}
                        className="w-100 my-2"
                    />
                </div>
                <div className="col-md-6">
                    <TextField
                        label={t('auth.register.brand.step2.lastName')}
                        variant="outlined"
                        name="lastName"
                        onChange={changeHandler}
                        size="small"
                        value={currentUser?.lastName ?? ''}
                        required
                        className="w-100 my-2"
                    />
                </div>
                <div className="col-md-6">
                    <PhoneInput
                        country="de"
                        value={currentUser?.contact?.phone ?? ''}
                        onChange={changePhoneHandler}
                        inputClass="w-100 my-2"
                    />
                </div>

                <div className="col-md-6 my-2">

                    <CustomAutocomplete dataArray={UserPositionEnum ?? []}
                                        defaultArray={currentUser?.position as string}
                                        isWithImage={false}
                                        key={savedData?.id}
                                        inputLabel="position"
                                        isMultiple={false}
                                        photoLabel=""
                                        queryFilterKey=""
                                        inputLabelTranslation={t('auth.register.brand.step3.position_req')}
                                        handleChangeParams={changePositionHandler}/>
                </div>
                <div className="col-12 mt-4">
                    <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep} isBackHidden={true}
                                    handleBackStep={handleBackStep}/>
                </div>

            </div>
        </Form>
    );
};
