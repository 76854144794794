import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {CampaignService} from '../service';
import {Campaign} from '../../../../model/campaign/Campaign';
import {ErrorToast} from '../../../../utils/toasters';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {useTranslation} from 'react-i18next';
import {
    AiFillCloseCircle,
} from 'react-icons/ai';
import {Button, Col} from 'react-bootstrap';

import {useDispatch} from 'react-redux';
import {actions} from '../../../../store/authentication/authRedux';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import {MobileMenu} from '../../../../shared/layout/components/MobileMenu';
import {
    checkCampaignTabsVisibility,
    formatDate, getFirstPreviewImage,
    truncateString,
} from '../../../../shared/functions/Functions';
import {selectedCampaignSlice} from '../../../../store/brand/activeCampaignSlice';
import {CampaignHeaderButtons} from './CampaignHeaderButtons';

export function CampaignHeader() {
    const {t} = useTranslation();
    const location = useLocation();
    const tabs = [
        `${t('brand.campaign.tabs.dashboard')}-dashboard`,
        `${t('brand.campaign.tabs.book')}-book`,
        `${t('brand.campaign.tabs.contracts')}-contracts`,
        `${t('brand.campaign.tabs.options')}-options`,
        `${t('brand.campaign.tabs.manage')}-manage`,
        `${t('brand.campaign.tabs.measure')}-measure`,
        `${t('brand.campaign.tabs.messages')}-messages`,
    ];
    const history = useHistory();
    const [key, setKey] = useState<string>('dashboard');
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [filterParams, setFilterParams] = useState({
        page: 1,
        perPage: 5,
        title: '',
    });
    const [selectedCampaignId, setSelectedCampaignId] = useState(0);
    const [campaign, setCampaign] = useState<Campaign>();
    const id = location.pathname.split('/')[4];
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        CampaignService.getCampaign(+id)
            .then((data) => {
                if (data?.isDeleted) {
                    handleEdit(+id, Number(data?.briefingStep ?? 1), data?.isBriefingComplete);
                }
                sessionStorage.setItem('activeCampaignId', String(data.id));
                dispatch(selectedCampaignSlice.actions.setActiveCampaign(data));
                dispatch(selectedCampaignSlice.actions.setActiveCampaignId(String(data.id)))
                setCampaign(data);
                setSelectedCampaignId(0);
            })
            .catch((error) => ErrorToast(error));
        return () => {
            sessionStorage.removeItem('activeCampaignId');
            dispatch(selectedCampaignSlice.actions.setActiveCampaignId(undefined));
        };
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        CampaignService.getCampaigns(filterParams)
            .then((data) => {
                setCampaigns(data.data);
            })
            .catch((error) => ErrorToast(error));
        // eslint-disable-next-line
    }, [JSON.stringify(filterParams)]);

    const addToShortcuts = (id?: number) => {
        CampaignService.addToShortcuts(id ?? 0).then((response) => {
            dispatch(actions.updateFavoriteCampaign(response));
            setCampaign({...campaign!, isFavorite: !campaign?.isFavorite});
        });
    };

    const handleEdit = (campaignId: number, briefingStep: number, isFinished = false) => {
        if (isFinished) {
            history.push(`/brand/create/campaign/${campaignId}/overview`);
        } else {
            history.push(`/brand/create/campaign/${campaignId}/step${briefingStep}`);
        }
    };
    const handleShow = () => {
        setShow(!show);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setKey(newValue);
        history.push(`${InternalRoutes.BRAND_CAMPAIGN}/${newValue}/${id}`);
    };

    function openCampaignDashboard() {
        if (selectedCampaignId > 0) {
            if (key !== 'messages') {
                // setKey('dashboard');
                history.push(
                    `${InternalRoutes.BRAND_CAMPAIGN}/${key}/${selectedCampaignId}`,
                );
            } else {
                history.push(
                    `${InternalRoutes.BRAND_CAMPAIGN}/messages/${selectedCampaignId}`, {fromMessageScreen: true}
                );
            }
            setFilterParams({...filterParams, title: ''});
            handleShow();
        }
    }

    useEffect(() => {
        const currentURL = location.pathname.split('/')[3];
        setKey(currentURL);
        // eslint-disable-next-line
    }, [JSON.stringify(location.pathname)]);

    const changeCampaignHandler = (paramKey: string, paramValue: string | string[] | number) => {
        setSelectedCampaignId(paramValue as number);
    };

    return (
        <>
            <div
                id="header2"
                className={`row position-relative campaign-information align-items-center bg-white d-none d-md-flex tabs-container w-100 mx-0`}
            >
                {(campaign?.isLongTerm || !!campaign?.parent?.id) &&
                    <div className="diagonal-text">
                        <span className="diagonal-text-sm">
                            {t(`brand.campaign.briefing.overview_card.${campaign?.isLongTerm ? 'long_term' : 'linked'}`)}
                        </span>
                    </div>
                }
                <div className="col-lg-6 ">
                    <div className="d-flex align-items-center justify-content-start">
                        {(campaign?.isLongTerm || !!campaign?.parent?.id) &&
                            getFirstPreviewImage('style_uploaded_image', campaign?.campaignImages, 'campaign-icon ml-3')
                        }
                        <div className="pr-2 mt-1">
                            <h5 className="title mb-1 ml-1">{truncateString(campaign?.title ?? '-', 60)}</h5>
                            <div className="d-flex align-items-center">
                                <p className={`status ${campaign?.status} ml-1`}>
                                    {t(`general.campaign_status.${campaign?.status}`)}
                                </p>
                                {campaign?.publishFrom && campaign?.publishTo && <p className="mb-0 font-12 ml-2">
                                    {formatDate(campaign?.publishFrom as string)} -{' '}
                                    {formatDate(campaign?.publishTo as string)}
                                </p>}
                                <p className="mb-0 text-primary d-none d-md-block cursor-pointer text-uppercase ml-2"
                                   onClick={() => handleEdit(Number(id), Number(campaign?.briefingStep ?? 1), campaign?.isBriefingComplete)}>
                                    {t('general.editBriefing')}
                                </p>
                            </div>
                        </div>
                        <div className="position-relative mx-2">
                            <div className="d-flex align-items-center justify-content-around">
                                <CampaignHeaderButtons campaign={campaign} setShow={setShow} addToShortcuts={addToShortcuts}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 h-100 d-none d-lg-flex pt-3 justify-content-end">
                    <TabContext value={key}>
                        <Box className="h-100" sx={{borderBottom: 0}}>
                            <TabList
                                TabIndicatorProps={{style: {background: 'var(--primary)'}}}
                                className="h-100 m-auto "
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                            >
                                {tabs?.map((tab: string, i) => {
                                    return (
                                        <Tab
                                            key={i}
                                            label={<>{tab?.split('-')[0]}</>}
                                            value={tab?.split('-')[1]}
                                            hidden={!checkCampaignTabsVisibility(tab?.split('-')[1])}
                                        />
                                    );
                                })}
                            </TabList>
                        </Box>
                    </TabContext>
                </div>
                {show && (
                    <div className="position-absolute w-30 ">
                        <div
                            className="row align-items-center position-relative justify-content-between default-border-lighter default-radius bg-white p-2 ">
                            <Col md={9}>
                                <CustomAutocomplete dataArray={campaigns} isWithImage={false} inputLabel="title"
                                                    changeEventKey="title" setFilterState={setFilterParams}
                                                    queryFilterKey="title"
                                                    inputLabelTranslation={t('general.changeCampaign')}
                                                    handleChangeParams={changeCampaignHandler}/>
                            </Col>
                            <Col className="p-0" md={3}>
                                <Button
                                    className="w-100"
                                    onClick={() => openCampaignDashboard()}
                                    size="sm"
                                >
                                    Change
                                </Button>
                            </Col>
                            <AiFillCloseCircle
                                className="closeIcon scale-size-hover"
                                onClick={() => setShow(false)}
                            />
                        </div>
                    </div>
                )}
            </div>
            {/*CAMPAIGN TABS ON MOBILE DEVICES*/}
            <MobileMenu tabs={tabs} classes={'d-lg-none shadow'} handleChange={handleChange} activeTabKey={key}/>
        </>
    );
}

export const HeartFillIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Filled" fill="#952cb7" viewBox="0 0 24 24" width={30} height={30}>
            <path
                d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"/>
        </svg>
    );
};
