import React, { useState } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMediaQuery } from '@mui/material';
import { truncateString } from '../../functions/Functions';
import {BootstrapBreakpoints} from '../../../enum/BootstrapBreakpoints';

interface IStatisticCard {
    number: string | number;
    text: any;
    classes?: string;
}

export function StatisticCard({ number, text, classes }: IStatisticCard) {
    const [shouldShowOverlay, setShouldShowOverlay] = useState(false);
    const isMobile = useMediaQuery(`(max-width: ${BootstrapBreakpoints.MD}px)`);
    const isLongText = text.length > 10;

    const handleToggle = () => {
        if (isLongText && isMobile) {
            setShouldShowOverlay(prevState => !prevState);
        }
    };

    const displayText = isLongText && isMobile ? truncateString(text, 10) : text;

    return (
        <Card className={`${classes}`}>
            <Card.Body className="text-center p-3">
                <h6 className="mb-1">{number}</h6>
                <OverlayTrigger
                    placement="top"
                    onToggle={handleToggle}
                    show={shouldShowOverlay}
                    overlay={<Tooltip id="statistic-tooltip">{text}</Tooltip>}
                >
                      <span className={`text-primary ${(isLongText && isMobile) ? 'cursor-pointer' : ''}`}>
                          {displayText}
                      </span>
                </OverlayTrigger>
            </Card.Body>
        </Card>
    );
}