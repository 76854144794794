import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Campaign} from '../../../../../model/campaign/Campaign';
import EmptyState from '../../../../../shared/components/placeholder/EmptyState';
import CardView from '../desktop/CardView';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {UserRoles} from '../../../../../enum/UserRoles';
import {IOfferWithCampaign} from '../../../profile/profile-view';
import CardViewOffer from '../desktop/CardViewOffer';

interface Props {
    campaigns?: Campaign[];
    offers?: IOfferWithCampaign[];
    hasContracts?: boolean;
}

const MyCampaigns = ({campaigns, offers, hasContracts = false}: Props) => {
    const {t} = useTranslation();
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);
    const history = useHistory();

    const navigateToMyCampaigns = () => {
        [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles) ?
            history.push('/agency/campaigns') :
            history.push('/influencer/campaigns');
    };

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                {selectedInfluencer?.id ?
                    <h4>{t('influencer.dashboard.campaigns_from', {firstName: selectedInfluencer?.firstName})}</h4> :
                    <h4>{t('influencer.dashboard.yourCampaigns')}</h4>
                }
                {!hasContracts &&
                    <p onClick={navigateToMyCampaigns} className="text-uppercase text-muted text-underline mb-0">
                        {t('influencer.header.myCampaigns')}
                    </p>
                }
            </div>
            {!!offers?.length ?
                <CardViewOffer offers={offers}/>
                : <div className="card mt-2">
                    <div className="card-body">
                        <EmptyState/>
                    </div>
                </div>
            }
        </>
    );
};

export default MyCampaigns;
