import React, { CSSProperties } from 'react'
import { BiDownload } from 'react-icons/bi';
import { Col, Row } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
import { getCorrectImageThumbnail, getPptxUrl } from '../../functions/Functions';
import { IPicture } from '../../../model/shared/IPicture';
import { CustomImageHandler } from '../CustomImageHandler';
import PlayButtonOverlay from '../PlayButtonOverlay';

interface IPdfPlaceholder {
    styleContent?: CSSProperties;
    classContent?: string;
    classIcon?: string;
    classImage?: string;
    file: any;
    hideText?: boolean;
    files?: IPicture[];
    nameFancybox?: string;
    handleDownload?: (...args: any) => void; 
}

export const getFileType = (fileName: string, path?: string, isLink?: string) => {
  const extension = (fileName?.split('.').pop() || '').toLowerCase();

  if (isLink) return {type: 'link', imgSrc: '/tempAssets/videoPlaceholder.svg' };

  switch (extension) {
    case 'pdf':
      return { type: 'pdf', iconClass: 'fi fi-rr-file-pdf text-danger', imgSrc: '/icons/files/file-pdf.svg' };
    case 'doc':
    case 'docx':
      return { type: 'word', iconClass: 'fi fi-rr-file-word text-primary', imgSrc: '/icons/files/file-word.svg' };
    case 'xls':
    case 'xlsx':
      return { type: 'excel', iconClass: 'fi fi-rr-file-excel text-success', imgSrc: '/icons/files/file-excel.svg' };
    case 'ppt':
    case 'pptx':
      return { type: 'powerpoint', iconClass: 'fi fi-rr-file-powerpoint text-warning', imgSrc: '/icons/files/file-powerpoint.svg' };
    case 'jpg':
    case 'png':
    case 'jpeg':
      return { type: 'image', iconClass: 'fi fi-rr-picture text-muted', imgSrc: path };
    case 'mp4':
      return { type: 'video', iconClass: 'fi fi-rr-file-video text-muted', imgSrc: path };
    case 'm4a':
    case 'mp3':
      return { type: 'audio', iconClass: 'fi fi-rr-file-audio text-muted', imgSrc: '/icons/files/file-audio.svg' };
    default:
      return { type: 'other', iconClass: 'fi fi-rr-file text-muted', imgSrc: '/icons/files/file.svg' };
  }
};

export default function FilePlaceholder({
  classIcon, 
  classContent, 
  styleContent,
  classImage = 'img-cover', 
  handleDownload, 
  file,
  hideText = false,
  files,
  nameFancybox,
}: IPdfPlaceholder) {
  const isPDF = file?.mime === 'application/pdf' || file?.type === 'application/pdf';
  const isImage = getFileType(file?.name).type === 'image';
  const isVideo = getFileType(file?.name).type === 'video';
  const isAudio = getFileType(file?.name).type === 'audio';

  return (
    <>
      <div className="px-2 px-md-0">
          <a
              data-fancybox={`gallery${nameFancybox ? '-'+nameFancybox : ''}`}
              data-type={isImage ? 'image' : isVideo ? '' : 'iframe'}
              className="cursor-pointer w-100"
              data-src={(isImage || isVideo || isAudio) ? file?.path : isPDF ? file?.path : getPptxUrl(file?.path)}
              data-download-src={file?.path}
              data-thumb={(isImage || isVideo) ? getCorrectImageThumbnail(!!file?.externalLink, file?.originalName ?? '', file?.thumbPath ?? '', file?.path) : getFileType(file?.name).imgSrc}
          >
              <div className={`box-shadow d-flex align-items-center justify-content-center default-radius overflow-hidden ${classContent ?? ''}`}
                  style={styleContent}
              >
                {(isImage || isVideo) ? (
                  <>
                    {isVideo ? (
                      <PlayButtonOverlay classParent='max-height-100 h-100' classBtn='w-50 h-50 font-25'>
                        <CustomImageHandler
                          photoPath={getCorrectImageThumbnail(!!file?.externalLink, file?.originalName ?? '', file?.thumbPath ?? '', file?.path)}
                          altTag={file?.title ?? file?.originalName}
                          thumbnailPath={file?.thumbnail}
                          style={{height: '100%', width: '100%'}}
                          classes={classImage}
                          placeholder={getCorrectImageThumbnail(!!file?.externalLink, file?.originalName ?? '', file?.thumbPath ?? '', file?.path)}
                        />
                      </PlayButtonOverlay>
                    ) : (
                      <CustomImageHandler
                        photoPath={getCorrectImageThumbnail(!!file?.externalLink, file?.originalName ?? '', file?.thumbPath ?? '', file?.path)}
                        altTag={file?.title ?? file?.originalName}
                        thumbnailPath={file?.thumbnail}
                        classes={classImage ?? ''}
                        style={{height: '100%', width: '100%'}}
                        placeholder={getCorrectImageThumbnail(!!file?.externalLink, file?.originalName ?? '', file?.thumbPath ?? '', file?.path)}
                      />
                    )}
                  </>
                  
                ) : (
                  <i className={`${getFileType(file?.name).iconClass} d-flex ${classIcon ?? 'font-50'}`}/>
                )}

              </div>
        </a>

          {handleDownload && (
            <a href="#2" className="cursor-pointer position-absolute"
                         style={{top: '-5px', right: 20}}
                         onClick={(e) => {
                            e.preventDefault();
                            handleDownload?.();
                        }}>
                <div className="top-right-icon upload-icon scale-size-hover">
                    <BiDownload size={15} className="text-primary"/>
                </div>
            </a>
          )}
      </div>

      {!hideText && (
        <Row className='align-items-center justify-content-center flex-nowrap my-1'>
          <Col xs={files && files.length > 1 ? 'auto' : 12}>
            <p className='text-muted text-center mb-0'>
              {file?.originalName ?? file?.title}
            </p>
          </Col>

          {files && files.length > 1 && (
              <Tooltip id="white-tooltip" title={<>
                  {files?.slice(1)?.map((file: any) => (
                      <div className="mx-1 my-2 text-dark" key={file?.id}>
                          {file?.originalName ?? file?.title}
                      </div>
                  ))}
              </>} placement="right" className="cursor-pointer">
                  <span className="icon-round bg-dark font-weight-semi-bold"
                          style={{width: 33, minWidth: 33}}>
                      + {files.length - 1}
                  </span>
              </Tooltip>
          )}
        </Row>
      )}
    </>
  )
}
