import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BsCardChecklist, BsSpeedometer2} from 'react-icons/bs';
import {useHistory, useLocation} from 'react-router-dom';
import {Campaign} from '../../../model/campaign/Campaign';
import {CustomAutocomplete} from '../../../shared/components/Autocomplete';
import usePagination from '../../../shared/hooks/usePagination';
import {ErrorToast} from '../../../utils/toasters';
import ReportingPerCampaign from './components/ReportingPerCampaign';
import {IPerformanceSummary, IReportingSummary, IReportingTasksSummary} from './dto/IReporting';
import {InfluencerReportingService} from './service';
import AlertBox from '../../../shared/components/alert/AlertBox';
import ReportingOverview from './components/ReportingOverview';

interface ILocation {
    campaignId: number;
}

const InfluencerReporting = () => {
    const history = useHistory();
    const {state} = useLocation();
    const {t} = useTranslation();
    const {filterParams, setFilterParams} = usePagination({
        perPage: 10,
        otherKeys: {
            campaignId: (state as ILocation)?.campaignId ?? '',
            isCompleted: false,
        },
    });
    const [updateReports, setUpdateReports] = useState(false);
    const [summary, setSummary] = useState<IReportingSummary>();
    const [showItems, setShowItems] = useState({
        showReporting: window.innerWidth > 600,
        showPerformance: window.innerWidth > 600,
    });
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);

    const [selectedFilterCampaign, setSelectedFilterCampaign] = useState<Campaign>();

    const changeCampaignFilterHandler = (paramKey: string, paramValue: string | string[] | number) => {
        const selectedCampaign = campaigns?.find(campaign => campaign?.offerId === +paramValue)
        setFilterParams((prev: any) => ({
            ...prev,
            page: 1,
            campaignId: selectedCampaign?.id,
            isLongTerm: selectedCampaign?.offers?.[0]?.isLongTerm ?? null
        }));
        setSelectedFilterCampaign(selectedCampaign)
    };
    const handleShowFields = (key: 'showReporting' | 'showPerformance') => {
        setShowItems(prev => ({...prev, [key]: !prev[key]}));
    }
    useEffect(() => {
        InfluencerReportingService.getSummary(filterParams).then(response => {
            setSummary(response);
        }).catch(error => ErrorToast(error));
    }, [updateReports, campaigns]);


    useEffect(() => {
        InfluencerReportingService.getReportingCampaigns({...filterParams, page: 1})
            .then(response => {
                if ((state as ILocation)?.campaignId) {
                    const campaignId = (state as ILocation)?.campaignId;
                    setSelectedFilterCampaign(response?.data?.find((campaign: Campaign) => campaign.id === campaignId))
                }
                setCampaigns(response.data?.map((campaign: Campaign) => ({
                    ...campaign,
                    offerId: campaign?.offers?.[0]?.id
                })));
            }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [filterParams]);

    
    return (
        <Row className="pb-5">
            <AlertBox image="/icons/img-warning.svg"
                      hasButton
                      classCol="col-12 mb-4"
                      variant="primary"
                      title={t('influencer.invoice.invoice_alert_title')}
                      desc={t('influencer.invoice.invoice_alert_desc')}
                      textButton={t('influencer.invoice.go_to')}
                      functionBtn={() => history.push('/influencer/invoices')}
            />
            <Col xs={12} className="my-3 mt-md-0">
                <h4>{t('influencer.reporting.reporting_overview')}</h4>
                <ReportingOverview
                    reportingTasks={summary?.reportingTasksSummary as IReportingTasksSummary}
                    performance={summary?.performanceSummary as IPerformanceSummary}
                />
            </Col>
            <Col xs={12} className="order-1 order-md-3 mt-md-3">
                <div className="d-flex align-items-center justify-content-start flex-wrap mb-3">
                    <div className="w-md-30 w-100 d-flex">
                        {/*<div className="d-flex d-md-none align-items-center">*/}
                        {/*    <Button variant={`${!showItems?.showReporting ? 'outline-secondary' : 'primary'}`}*/}
                        {/*            onClick={() => handleShowFields('showReporting')}>*/}
                        {/*        <BsCardChecklist size={25}/>*/}
                        {/*    </Button>*/}
                        {/*    <Button variant={`${!showItems?.showPerformance ? 'outline-secondary' : 'primary'}`}*/}
                        {/*            className="mx-2"*/}
                        {/*            onClick={() => handleShowFields('showPerformance')}>*/}
                        {/*        <BsSpeedometer2 size={25}/>*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                        <CustomAutocomplete dataArray={campaigns} isWithImage={false} inputLabel="title"
                                            setFilterState={setFilterParams} disableClearable={false}
                                            changeEventKey="name" queryFilterKey="offerId"
                                            key={selectedFilterCampaign?.id}
                                            isLongSelected={selectedFilterCampaign?.offers?.[0]?.isLongTerm}
                                            classes="w-100"
                                            defaultArray={selectedFilterCampaign as unknown as Campaign[]}
                                            inputLabelTranslation={t('influencer.planner.selectCampaign')}
                                            handleChangeParams={changeCampaignFilterHandler}/>
                    </div>
                    <div className="mt-2 mt-md-0">
                        <Button
                            size="sm"
                            className="mx-md-2 mr-2"
                            variant={filterParams?.isCompleted ? 'outline-primary' : 'primary'}
                            onClick={() => setFilterParams((prevState: any) => ({...prevState, isCompleted: false, page: 1}))}
                        >
                            {t(`general.campaign_status.ongoing`)}{` (${summary?.reportingTasksSummary?.ongoingCampaigns})`}
                        </Button>
                        <Button
                            size="sm"
                            variant={filterParams?.isCompleted ? 'primary' : 'outline-primary'}
                            onClick={() => setFilterParams((prevState: any) => ({...prevState, isCompleted: true, page: 1}))}
                        >
                            {t(`general.campaign_status.completed`)}{` (${summary?.reportingTasksSummary?.completedCampaigns})`}
                        </Button>
                    </div>
                </div>
            </Col>
            <Col xs={12} className="order-4">
                <ReportingPerCampaign handleShowFields={handleShowFields} filterParams={filterParams}
                                      setFilterParams={setFilterParams}
                                      setUpdateReports={setUpdateReports as React.Dispatch<SetStateAction<boolean>>}
                                      updateReports={updateReports}/>
            </Col>
        </Row>
    );
};

export default InfluencerReporting;
