import {User} from '../user/User';
import {Brand} from '../user/Brand';
import {Platforms} from '../platform/Platforms';
import {IPicture} from '../shared/IPicture';
import {Country} from '../shared/Country';
import {ICampaignCategories} from './CampaignCategory';
import {IBookedPlatforms} from '../platform/IBookedPlatforms';
import {IBudget} from '../shared/IBudget';
import {IFeedItems} from '../user/IFeedItems';
import {IOffer} from '../offer/IOffer';
import {ICompany} from '../../app/shared/user/dto/ICompany';
import {ICampaignGoals} from '../../app/brand/campaign/briefing/dto/ICreateCampaign';
import {IMainPlatforms} from '../briefing/step1/IMainPlatforms';
import {IBundle} from '../options/IBundle';
import {IDefault} from '../shared/IDefault';
import {IContract} from '../shared/IContract';
import {IPerformanceSummary, IReportingSummary} from '../../app/influencer/reporting/dto/IReporting';
import { CampaignStatusEnum } from '../../enum/CampaignStatusEnum';

export interface IReportingCampaign {
    acceptedUser: User;
    campaigns: Campaign[];
    notCompletedCampaigns: number;
    completedCampaigns: number;
}

export interface Campaign extends IDefault {
    adDescription: string;
    title: string;
    publishTo: string;
    approvalStatus: string;
    status: CampaignStatusEnum;
    profileTags: string;
    postingDeadline: string;
    platforms: Platforms[];
    offersCount: number;
    offers: IOffer[];
    grossBudget: number;
    campaignImages: IPicture[];
    totalSpent: number;
    hasManage: boolean;
    hasBooking: boolean;
    hasBundle: boolean;
    hasMeasure: boolean;
    hasMessages: boolean;
    budgetPercentage: number;
    reportCount: number;
    brand: Brand;
    user: User;
    users: User[];
    company: ICompany;
    totalReporting: ITotalReporting;
    isFavorite: boolean;
    isDeleted: boolean;
    isPicked: boolean;
    assignedUsers: User[];
    url: string;
    hashtags: string;
    useContent: string;
    date: string;
    briefingStep: string;
    publishFrom: string;
    gender: string;
    isReviewed: boolean;
    isBriefingComplete: boolean;
    ageMin: number;
    ageMax: number;
    typeDescription: string;
    reviewDays: number;
    creators: number;
    netBudget: number;
    feeAmount: number;
    feePercentage: number;
    audience: string;
    useContentText: string;
    package: string;
    isCharity: boolean;
    isInfluencersNotificationSent: boolean;
    offerId?: number;
    fee: number;
    countries: Country[];
    campaignCategories: ICampaignCategories[];
    totalBookedPosts: number;
    bookedPlatforms: IBookedPlatforms[];
    feedItems: IFeedItems[];
    influencersBooked: number;
    bookedInfluencers: number;
    offersDeclined: number;
    reportedInfluencers: number;
    totalViews?: number;
    totalReach?: number;
    engagementScore: number;
    invoices: IInvoices | IInvoices[];
    campaignGoal: ICampaignGoals;
    mainPlatforms: IMainPlatforms[];
    canViewBooking?: boolean;
    canDelete?: boolean;
    canDuplicate?: boolean;
    canViewMeasure?: boolean;
    bundle?: Partial<IBundle>;
    isTransparentPricing?: boolean;
    isLongTerm: boolean;
    contracts: IContract[];
    parent: Campaign;
    partnerCompanies: ICompany[];
    children: ILinkedCampaigns[];
    uuid?: string;
    usedPosts?: number;
    bookedPosts?: number;
    linkedCompleted?: number;
    postingsCompleted?: number;
}

export interface ILinkedCampaigns extends IDefault {
    assignedUsers: User[];
    campaignImages: IPicture[];
    influencersBooked: number;
    partnerCompanies: ICompany[];
    publishFrom: string;
    publishTo: string;
    status: string;
    approvalStatus: string;
    title: string;
    usedPostings: number;
    uuid?: string;
    briefingStep?: string;
    isBriefingComplete?: boolean;
    isFavorite: boolean;
    canDuplicate: boolean;
    canDelete: boolean;
    reportingSummary?: IReportingDashboard;

}

export interface IInvoices extends IDefault {
    date: string
    mime: string | null;
    name: string;
    originalName: string
    path: string;
}

export interface IReportStats {
    clicks: string;
    totalClicks: string;
    comments: string;
    likes: string;
    reach: string;
    reportCount: string;
    saves: string;
    shares: string;
    impressions: number;
    engagements: number;
    stickerEngagements: string;
    swipeUpLinks: string;
    views: string;
    watchTime: string;
}

interface ITotalReporting {
    dueDate: string;
    totalCalendar: string;
    totalPosts: string;
    canAddReporting: boolean;
    reportStats: IReportStats[];
}

export interface IUserNotification extends IDefault {
    isViewed: boolean
    offerId: number
    campaignId: number
    param: number
    translateBody: string
    translateBodyLong: string
    translateKey: string;
    sender?: User;
    senderCampaign?: Campaign;
    type: ENotificationType
    updatedAt: string
}

export enum ENotificationType {
    OFFER = 'offer',
    OFFER_LONG = 'offer_long',
    CONTRACT = 'contract',
    OFFER_COMMENT = 'offer_comment',
    CONTRACT_COMMENT = 'contract_comment',
    PLANNER = 'planner',
}

export interface IBookingStats {
    totalMatchedInfluencers: number;
    offersReceived: number;
    influencersBooked: number;
    offersDeclined: number;
    feedItems: IFeedItems[];
    budget: IBudget;
    totalContracts: number;
    contractUsedPostings: number;
    totalContractPostings: number;
    reach: number;
    avgCpm: number;
}

export interface IReportingDashboard {
    clickTrough: number;
    clicks: number;
    comments: number;
    engagementRate: number;
    engagements: number;
    impressions: number;
    likes: number;
    reach: number;
    reportCount: number;
    saves: number;
    shares: number;
    stickerEngagements: number;
    swipeUpLinks: number;
    totalExecutedPrice: number;
    totalLinkClicks: number;
    views: number;
    watchTime: number;
}

