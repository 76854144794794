import React from 'react';
import {Card} from 'react-bootstrap';
import {Campaign} from '../../../../model/campaign/Campaign';
import {IMyCampaignItem} from '../../campaign/dto/IMyCampaigns';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {checkOfferType, formatDate} from '../../../../shared/functions/Functions';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';
import {
    MessagesIcon,
    PlannerIcon,
    ReportingIcon,
} from '../../../../shared/Icons';
import {DOWNLOAD_INVOICE} from '../../../../utils/endpoints';
import {downloadFileAPI} from '../../../../utils';

interface ICampaignCard {
    campaign: Campaign | IMyCampaignItem;
    isSeeYourCampaign?: boolean;
    isInvoice?: boolean;
    replaceButtons?: boolean;
    deleteInvoice?: (...args: any) => void;
    isAgency?: boolean;
}

export default function CampaignCard({
                                         campaign,
                                         isSeeYourCampaign,
                                         isInvoice,
                                         replaceButtons,
                                         deleteInvoice,
                                         isAgency,
                                     }: ICampaignCard) {
    const {t} = useTranslation();
    const history = useHistory();
    const isCompleted = campaign?.offers[0]?.status === 'completed'
    const navigateHandler = (route: string, campaignId: number) => {
        history.push(route, {
            campaignId: campaignId,
        });
    };

    const navigateToCampaignOverview = (campaignId: number) => {
        history.push(`/${isAgency ? 'agency' : 'influencer'}/campaign/${campaignId}/overview`);
    };

    const downloadInvoice = (id: number, originalName: string) => {
        downloadFileAPI(DOWNLOAD_INVOICE + '/' + id);
    };

    return (
        <Card className="p-2 h-100 d-flex flex-column justify-content-between overflow-hidden">
            <div className="d-flex align-items-start justify-content-between">
                {(campaign.offers[0]?.isLongTerm || campaign.offers[0]?.isOnTop || campaign.offers[0]?.isAddonsOnly) &&
                    <div className="diagonal-text">
                                    <span className="diagonal-text-sm">
                                        {t(`brand.campaign.briefing.overview_card.${checkOfferType(campaign.offers[0]?.isLongTerm, campaign.offers[0]?.isOnTop, campaign.offers[0]?.isAddonsOnly)}`)}
                                    </span>
                    </div>
                }
                <div
                    className="position-relative w-50 cursor-pointer"
                    onClick={() => navigateToCampaignOverview(campaign?.id)}
                >
                    <CustomImageHandler
                        style={{height: 130}}
                        classes="img-cover default-radius-sm w-100 shadow"
                        thumbnailPath={campaign?.campaignImages?.[0]?.thumbnail}
                        photoPath={campaign?.campaignImages?.[0]?.path}
                        altTag="Campaign logo"
                    />
                    {!isInvoice && (
                        <span
                            className={`status campaign-status-dashboard ${
                                campaign?.status || campaign?.offers[0]?.status
                            } position-absolute`}
                        >
                            {t(`general.campaign_status.${
                                campaign?.status || campaign?.offers[0]?.status
                            }`)}
                        </span>
                    )}
                </div>
                <div className="w-50 px-3 d-flex flex-column h-100 justify-content-between">
                    <h5
                        className="cursor-pointer font-weight-bold"
                        onClick={() => navigateToCampaignOverview(campaign?.id)}
                    >
                        {campaign?.title ?? '-'}
                    </h5>
                    <div className="d-flex flex-column ">
                        <span className="text-muted font-weight-bold">
                            {campaign?.company?.displayName ?? '-'}
                        </span>
                    </div>
                </div>
            </div>
            <div className="d-flex mt-2">
                {campaign?.offers?.[0]?.status !== 'completed' && (
                    <div className="col-4 p-0">
                        <div className="m-auto w-max-content text-center">
                            <div className="text-primary font-weight-extra-bold">
                                {formatDate(campaign?.postingDeadline ?? '')}
                            </div>
                            <div>{t('brand.campaign.briefing.step3.postingDeadline')}</div>
                        </div>
                    </div>
                )}
                <div
                    className={
                        campaign?.offers?.[0]?.status !== 'completed'
                            ? 'col-4 p-0'
                            : 'col-6 p-0'
                    }
                >
                    <div
                        className={
                            campaign?.offers?.[0]?.status !== 'completed'
                                ? 'm-auto w-maxContent  pr-md-0 text-center'
                                : ''
                        }
                    >
                        <span
                            className={`${
                                !campaign.isCharity && 'euro-symbol'
                            } text-primary font-weight-extra-bold`}
                        >
                        {campaign.isCharity
                            ? t('brand.account.price_list_modal.barter_deals')
                            : priceSplitter(Math.round(campaign?.offers?.[0]?.price ?? 0))}
                        </span>
                        <div>
                            {!campaign.isCharity &&
                                t('brand.campaign.book.offer.modal.price')}
                        </div>
                    </div>
                </div>
                <div
                    className={
                        campaign?.offers?.[0]?.status !== 'completed' ? 'col-4 p-0' : 'ml-3'
                    }
                >
                    <div className="m-auto w-max-content text-center">
                        <div className="text-primary font-weight-extra-bold">
                            {campaign?.offers?.[0]?.totalNbPosts ?? 0}
                        </div>
                        <div>{t('influencer.explore.contentPieces')}</div>
                    </div>
                </div>
            </div>
            {!replaceButtons ? (
                <>
                    {((!!isSeeYourCampaign &&
                            campaign?.offers?.[0]?.status === 'accepted') ||
                        !isSeeYourCampaign) && (
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div
                                onClick={() =>
                                    !isCompleted && navigateHandler(isAgency ? '/agency/planner' : '/influencer/planner', campaign?.id)
                                }
                                className={`card py-3 d-flex align-items-center flex-column justify-content-center position-relative ${isCompleted ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                            >
                                <PlannerIcon width="20" height="20" color="#9ea5ab"/>
                                <span className="font-10">
                                    {t('influencer.campaign.tabs.planner')}
                                </span>
                                {campaign?.offers?.[0]?.redDots?.plannerDot && (
                                    <div id="bullet" className="offer-bullet dashboard-dot"/>
                                )}
                            </div>
                            <div
                                onClick={() =>
                                    navigateHandler(isAgency ? '/agency/reporting' : '/influencer/reporting', campaign?.id)
                                }
                                className="cursor-pointer card py-3 mx-2 d-flex align-items-center flex-column justify-content-center position-relative"
                            >
                                <ReportingIcon width="20" height="20" color="#9ea5ab"/>
                                <span className="font-10">
                                    {t('influencer.campaign.tabs.reporting')}
                                </span>
                                {campaign?.offers?.[0]?.redDots?.reportingDot && (
                                    <div id="bullet" className="offer-bullet dashboard-dot"/>
                                )}
                            </div>
                            <div
                                onClick={() =>
                                    navigateHandler(isAgency ? '/agency/messages' : '/influencer/messages', campaign?.id)
                                }
                                className="cursor-pointer card py-3 d-flex align-items-center flex-column justify-content-center position-relative"
                            >
                                <MessagesIcon width="20" height="20" color="#9ea5ab"/>
                                <span className="font-10">
                                    {t('influencer.campaign.tabs.messages')}
                                </span>
                                {campaign?.offers?.[0]?.redDots?.messagesDot && (
                                    <div id="bullet" className="offer-bullet dashboard-dot"/>
                                )}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="col-3 p-0">
                        <button
                            className="btn btn-outline-gray w-100 text-uppercase"
                            disabled={!!campaign?.invoices[0]?.isPaid}
                            onClick={() =>
                                deleteInvoice?.({
                                    show: true,
                                    id: campaign?.invoices[0]?.id,
                                    title: campaign?.title,
                                })
                            }
                        >
                            {t('general.button.delete')}
                        </button>
                    </div>
                    <div className="pl-2 w-100 pr-0">
                        <button
                            className="btn btn-outline-primary w-100"
                            onClick={() =>
                                downloadInvoice(
                                    campaign?.invoices[0]?.id,
                                    campaign?.invoices[0]?.originalName
                                )
                            }
                        >
                            {t('influencer.invoice.invoice')}
                        </button>
                    </div>
                </div>
            )}
        </Card>
    );
}
