import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BiBookmark, BiGift} from 'react-icons/bi';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import usePagination from '../../../../shared/hooks/usePagination';
import {ErrorToast} from '../../../../utils/toasters';
import {ICompany} from '../../../shared/user/dto/ICompany';
import {InfluencerExploreApi} from '../service';
import {IExploreFilterProps} from './ExploreSort';
import {AgencyService} from '../../../agency/service';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';

const ExploreHeader = ({exploreFilters, setExploreFilters, nbPickedOffers, isAgencyPage, nbReverseOffers}: IExploreFilterProps) => {
    const {t} = useTranslation();
    const {filterParams, setFilterParams} = usePagination({});
    const [companyList, setCompanyList] = useState<ICompany[]>([]);
    const changeAutocompleteHandler = (paramKey: string, paramValue: string | string[] | number) => {
        setExploreFilters(prev => ({...prev, [paramKey]: paramValue}));
        setFilterParams((prev: any) => ({...prev, displayName: ''}));
    };
    const {redDots} = useSelector((state: IAllStates) => state.redDots);

    const filterHandler = (filter: 'isFavorite' | 'isPicked' | 'isCharity') => {
        setExploreFilters(prev => ({...prev, [filter]: !prev?.[filter], page: 1}));
    };

    useEffect(() => {
        if (isAgencyPage) {
            AgencyService.getAllCompanies(filterParams).then(response => {
                setCompanyList(response.data);
            }).catch(error => ErrorToast(error));
        } else {
            InfluencerExploreApi.getAllCompanies(filterParams).then(response => {
                setCompanyList(response.data);
            }).catch(error => ErrorToast(error));
        }
    }, [filterParams]);

    const getNotificationNumber = (tabKey: string) => {
        const dotMap: { [key: string]: number } = {
            'exploreBarter': Number(redDots?.exploreBarter || 0),
            'explorePaid': Number(redDots?.explorePaid || 0),
        };

        return dotMap[tabKey ?? ''] || 0;
    };

    return (
        <>
            <CustomAutocomplete dataArray={companyList} isWithImage={false} inputLabel="displayName"
                                queryFilterKey="companyId" changeEventKey="displayName"
                                setFilterState={setFilterParams} disableClearable={false}
                                classes="w-md-25"
                                inputLabelTranslation={t('influencer.explore.brand')}
                                handleChangeParams={changeAutocompleteHandler}/>
            <div
                className={`influencer-tab-filter primary my-3 ml-md-3
                    my-md-0
                    ${exploreFilters?.isFavorite ? 'active' : ''}`}
                onClick={() => filterHandler('isFavorite')}>
                <BiBookmark size={20}/>
                <span className="ml-1">{t('influencer.explore.bookmarks')}</span>
            </div>
            <div
                className={`influencer-tab-filter primary-dark my-3 ml-md-3
                    my-md-0 position-relative
                    ${exploreFilters?.isCharity ? 'active' : ''}`}
                onClick={() => filterHandler('isCharity')}>
                <BiGift size={20}/>
                <span className="ml-1">{!exploreFilters?.isCharity ? t('influencer.explore.barter') : t('influencer.explore.paid')} ({nbReverseOffers})</span>
                {!exploreFilters?.isCharity ? !!getNotificationNumber('exploreBarter') && <span className="sidebar-bullet">
                    {getNotificationNumber('exploreBarter')}</span> : !!getNotificationNumber('explorePaid') && <span className="sidebar-bullet">
                    {getNotificationNumber('explorePaid')}</span>}
            </div>
            <div
                className={`influencer-tab-filter w-md-fit warning ml-md-3 ${exploreFilters?.isPicked ? 'active' : ''}`}
                onClick={() => filterHandler('isPicked')}>
                {exploreFilters?.isPicked ?
                    <img height="20" src="/icons/pick_for_you_orange.svg" alt="Pick for you"/> :
                    <img height="20" src="/icons/pick_for_you_grey.svg" alt="Pick for you"/>
                }
                <span className="ml-1">influence.vision {t('influencer.explore.picks')} ({nbPickedOffers})</span>
            </div>
        </>
    );
};

export default ExploreHeader;
