import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import EmptyState from '../../../shared/components/placeholder/EmptyState';
import usePagination from '../../../shared/hooks/usePagination';
import {ErrorToast} from '../../../utils/toasters';
import {CampaignFilter} from '../campaign/component/CampaignFilter';
import {InfluencerCampaignListItem} from '../campaign/component/InfluencerCampaignListItem';
import {InfluencerCampaignsList} from '../campaign/component/InfluencerCampaignsList';
import {IMyCampaignItem} from '../campaign/dto/IMyCampaigns';
import {InfluencerMyCampaignsApi} from '../campaign/service';
import {AgencyService} from '../../agency/service';
import {AgencyCampaignListItem} from '../../agency/campaigns/components/AgencyCampaignListItem';
import {useLocation} from 'react-router-dom';
import {InternalRoutes} from '../../../enum/InternalRoutes';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import CardLayout from '../../../shared/components/card/CardLayout';

const DeclinedOffers = () => {
    const location = useLocation();
    const {selectedInfluencer} = useSelector((reduxState: IAllStates) => reduxState.agency);
    const isAgency = location.pathname.includes(InternalRoutes.AGENCY_DECLINED_OFFER);
    const {filterParams, setTotalPages, setFilterParams, count, setCount} = usePagination({});

    const [declinedOffers, setDeclinedOffers] = useState<IMyCampaignItem[]>([]);
    const [longTermShow, setLongTermShow] = useState<boolean>(false);
    const [hasFilterButtons, setHasFilterButtons] = useState(false);
    const [startScroll, setStartScroll] = useState(false);

    useEffect(() => {
        const service = isAgency ? 
            AgencyService.getMyCampaignsDeclined({...filterParams, influencerId: selectedInfluencer?.id}) 
            : InfluencerMyCampaignsApi.getMyCampaignsDeclined(filterParams);
        
        service.then(response => {
                setTotalPages(Math.ceil(response.count / response.perPage));
                setDeclinedOffers((prev) => (startScroll ? [...prev, ...response?.data] : response?.data));
                setHasFilterButtons(response?.info?.hasFilterButtons);
                setCount(response.count);
                setLongTermShow(filterParams?.isContracts === true ? true : response?.info?.hasContracts);
            }).catch(err => ErrorToast(err));
        setStartScroll(false);
    }, [JSON.stringify(filterParams), selectedInfluencer]);

    return (
        <Row>
            <Col xs={12} className="myCampaigns">
                <CampaignFilter 
                    filterParams={filterParams} 
                    setFilterParams={setFilterParams}
                    isDeclinedStatus={true}
                    showLongButton={longTermShow}
                    showButtonFilters={hasFilterButtons}
                />
                {!!declinedOffers?.length ?
                    <InfiniteScroll
                        dataLength={declinedOffers?.length}
                        next={() => {
                            setStartScroll(true);
                            setFilterParams((prev: any) => ({...prev, page: prev.page+1}));
                        }}
                        endMessage={
                            <p className={`text-center ${(declinedOffers?.length !== count || filterParams?.perPage <= 10) ? 'd-none' : ''}`}>
                                <b>You have seen all!</b>
                            </p>
                        }
                        hasMore={declinedOffers?.length < count}
                        loader={<h4 className="text-center">Loading...</h4>}
                    >
                        <InfluencerCampaignsList isAgency={isAgency} isContracts={filterParams?.isContracts}>
                            <>
                                {declinedOffers?.map((campaign, index) => (
                                    isAgency ? (
                                        <AgencyCampaignListItem key={`${index}, ${campaign.id}`} campaign={campaign} isContracts={filterParams?.isContracts} />
                                    ) : (
                                        <InfluencerCampaignListItem key={`${index}, ${campaign.id}`} campaign={campaign} isContracts={filterParams?.isContracts} />
                                    )
                                ))}
                            </>
                        </InfluencerCampaignsList>
                    </InfiniteScroll>
                    :
                    <CardLayout classCard="mt-3">
                        <EmptyState/>
                    </CardLayout>}
            </Col>
        </Row>
    );
};

export default DeclinedOffers;
