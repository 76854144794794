import {useTranslation} from 'react-i18next';
import {Checkbox, TextField} from '@material-ui/core';
import React, {useState} from 'react';
import {gtmService} from '../../../../utils/gtm.service';
import {IRegisterAdmin} from '../dto/IRegister';
import {StepperButtons} from './StepperButtons';
import {AuthService} from '../../../service';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../utils/toasters';
import {useHistory} from 'react-router-dom';
import {AiFillEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {Form} from 'react-bootstrap';
import {useRecaptcha} from '../../../../shared/hooks/useRecaptcha';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import PhoneInput from 'react-phone-input-2';
import {PasswordVerifySteps} from '../../../../shared/PasswordVerifySteps';

export interface IRegisterStepperProps {
    stepIdNumber: number;
    handleBackStep: () => void;
}

export const Step1 = ({stepIdNumber, handleBackStep}: IRegisterStepperProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState<IRegisterAdmin>({
        email: '',
        password: '',
        isGeneralTermsAccepted: false,
        repeatPassword: '',
    });
    const [isTypePassword, setIsTypePassword] = useState(true);
    const [isRepeatHidden, setIsRepeatHidden] = useState(true);
    const {setToken, executeRecaptcha} = useRecaptcha();

    const changeHandler = (event: React.ChangeEvent<any>, isCheckbox = false, isCompany = false) => {
        const {name, value, checked} = event.target;
        isCompany ?
            setState(prev => ({
                ...prev, company: {
                    ...prev?.company,
                    displayName: value,
                },
            }))
            :
            setState((prev) => ({
                ...prev,
                [name]: isCheckbox ? checked : value,
            }));
    };

    const handleNextStep = async () => {
        if (!executeRecaptcha) {
            return;
        }
        const result = await executeRecaptcha('register');
        setToken(result);
        if (state?.isGeneralTermsAccepted) {
            setIsLoading(true);
            AuthService.register(state).then(response => {
                if (response) {
                    gtmService('advertiser_lead');
                    history.push(`/brand/register/${stepIdNumber + 1}?token=${response?.verificationUUID}`);
                    SuccessToast(t('auth.register.brand.step1.pleaseCheckEmail'));
                }
            }).catch(error => ErrorToast(error)).finally(() => setIsLoading(false));
        } else {
            WarningToast(t('auth.register.brand.step1.termsNotSelected'));
        }
    };

    const changePhoneHandler = (value: string) => {
        setState(prev => ({
            ...prev, contact: {
                ...prev?.contact,
                phone: value,
            },
        }));
    };

    const changeLocaleHandler = (paramKey: string, paramValue: string | string[] | number) => {
        setState(prev => ({...prev, [paramKey]: paramValue}));
    };

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            handleNextStep();
        }}>
            <div className="row">
                <div className="col-md-6">
                    <TextField
                        label={t('auth.register.brand.step2.firstName')}
                        variant="outlined"
                        name="firstName"
                        onChange={changeHandler}
                        size="small"
                        required
                        value={state?.firstName ?? ''}
                        className="w-100 my-2"
                    />
                </div>
                <div className="col-md-6">
                    <TextField
                        label={t('auth.register.brand.step2.lastName')}
                        variant="outlined"
                        name="lastName"
                        onChange={changeHandler}
                        size="small"
                        value={state?.lastName ?? ''}
                        required
                        className="w-100 my-2"
                    />
                </div>
                <div className="col-md-6">
                    <PhoneInput
                        country="de"
                        value={state?.contact?.phone ?? ''}
                        onChange={changePhoneHandler}
                        inputClass="w-100 my-2"
                    />
                </div>
                <div className="col-md-6">
                    <CustomAutocomplete dataArray={['en', 'de']} isWithImage={false} inputLabel="locale"
                                        classes="my-2"
                                        defaultArray={state.locale}
                                        isMultiple={false}
                                        photoLabel=""
                                        queryFilterKey="locale"
                                        inputLabelTranslation={t('auth.register.brand.step7.language')}
                                        handleChangeParams={changeLocaleHandler}/>
                </div>
                <div className="col-md-6">
                    <TextField
                        label={t('auth.register.brand.step4.companyName')}
                        variant="outlined"
                        name="displayName"
                        size="small"
                        value={state?.company?.displayName ?? ''}
                        onChange={(event) => changeHandler(event, false, true)}
                        required
                        className="w-100 my-2"
                    />
                </div>
                <div className="col-md-6">
                    <TextField
                        label="E-mail"
                        variant="outlined"
                        size="small"
                        defaultValue={state?.email ?? ''}
                        required
                        type="email"
                        name="email"
                        onChange={changeHandler}
                        className="w-100 my-2"
                    />
                </div>
                <div className="col-md-6">
                    <div className="d-flex align-items-center">
                        <TextField
                            label={t('auth.register.brand.step1.password')}
                            variant="outlined"
                            size="small"
                            defaultValue={state?.password ?? ''}
                            name="password"
                            required
                            onChange={changeHandler}
                            type={isTypePassword ? 'password' : 'text'}
                            className="w-100 my-2"
                        />
                        <div style={{marginLeft: '-1.8rem', zIndex: 1}} className="cursor-pointer"
                             onClick={() => setIsTypePassword(prev => !prev)}>
                            {isTypePassword ? <AiFillEye size={20}/> :
                                <AiOutlineEyeInvisible size={20}/>}
                        </div>
                    </div>
                    <PasswordVerifySteps password={state.password ?? ''}/>
                </div>
                <div className="col-md-6">
                    <div className="d-flex align-items-center">
                        <TextField
                            label={t('auth.register.brand.step1.repeatPassword')}
                            variant="outlined"
                            onChange={changeHandler}
                            type={isRepeatHidden ? 'password' : 'text'}
                            defaultValue={state?.repeatPassword ?? ''}
                            size="small"
                            required
                            name="repeatPassword"
                            className="w-100 my-2"
                        />
                        <div className="cursor-pointer ml-1"
                             onClick={() => setIsRepeatHidden(prev => !prev)}>
                            {isRepeatHidden ? <AiFillEye size={20}/> :
                                <AiOutlineEyeInvisible size={20}/>}
                        </div>
                    </div>
                </div>
                <div className="col-12 my-2 d-flex align-items-center">
                    <Checkbox defaultChecked={state?.isGeneralTermsAccepted ?? false} className="px-0"
                              onChange={(e) => {
                                  changeHandler(e, true);
                              }}
                              size={'small'}
                              id="checkbox"
                              name="isGeneralTermsAccepted"
                              color="primary"/>
                    <label htmlFor="checkbox" className="ml-2 mb-0 text-left cursor-pointer"
                           dangerouslySetInnerHTML={{__html: t('auth.register.brand.step1.terms')}}/>
                </div>
                <div className="col-12 mt-4">
                    <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep}
                                    isLoading={isLoading}
                                    handleBackStep={handleBackStep}/>
                </div>
            </div>
        </Form>
    );
};
