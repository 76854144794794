import { formatDate } from '../../../functions/Functions';

const footerPdf = `
    <footer>
        <div class="text-center">
            <p>Influence.vision Gmbh</p>
            <p>Vienna | Bijeljina</p>
            <a>support@influencevision.com</a>
            <a>www.influencevision.com</a>
        </div>
        <div class="px-4">
            Printed on: ${formatDate(new Date())}
        </div>
    </footer>
  `;

export default footerPdf;
