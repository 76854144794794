import {IInviteContractInfluencer} from '../../model/book/Invites';
import {IInfluencerDistribution} from '../../app/influencer/offer/dto/IInfluencerDistribution';
import {createSlice} from '@reduxjs/toolkit';
import {ICampaignOverview} from '../../model/briefing/overview/ICampaignOverview';
import {IOfferItemsRequest} from '../../model/offer/IOfferItems';
import {User} from '../../model/user/User';
import {OfferStatusEnum} from '../../enum/OfferStatusEnum';

export interface IBrandContractStore {
    activeTabModal: string;
    contractModalStatus: string;
    campaign?: ICampaignOverview;
    campaignId: number;
    userId: number;
    isEditable?: boolean;
    canCounter?: boolean;
    user?: User;
    initialContract: IInviteContractInfluencer;
    additionalItemId: number;
    distributionArr: IInfluencerDistribution[];
    canWriteComment?: boolean;
    // isCharityOrPrivate: boolean;
    isCharity: boolean;
    isTransparentPricing: boolean;
    hasUnreadComments: boolean;
}

const initialContractState: IBrandContractStore = {
    activeTabModal: '0',
    contractModalStatus: 'preview',
    campaign: undefined,
    user: undefined,
    distributionArr: [],
    additionalItemId: 1,
    userId: 0,
    campaignId: 0,
    isEditable: true,
    initialContract: {
        description: '',
        personalOffer: '',
        price: 0,
        contractItems: [],
        distribution: [],
    },
    canWriteComment: false,
    // isCharityOrPrivate: false,
    isCharity: false,
    isTransparentPricing: false,
    hasUnreadComments: false,
};

export const contractBrand = createSlice({
    name: 'contractBrand',
    initialState: initialContractState,
    reducers: {
        setInitialState: (state, action) => {
            return initialContractState;
        },
        setActiveTab: (state, action) => {
            const activeTabModal = action.payload;
            if (activeTabModal === '4') {
                return {...state, activeTabModal, hasUnreadComments: false};
            }
            return {...state, activeTabModal};
        },
        setCampaignId: (state, action) => {
            const campaignId = action.payload;
            return {...state, campaignId};
        },
        setUserId: (state, action) => {
            const userId = action.payload;
            return {...state, userId};
        },
        setContractModalDetails: (state, action) => {
            const campaign: ICampaignOverview = action.payload;
            const contractItems: IOfferItemsRequest[] = [];
            let additionalItems: IInfluencerDistribution[] = [];
            campaign.contractPlaceholder?.totalPlatforms?.map((platform => (
                platform.contractItems.map((item) => (
                    contractItems.push(
                        {
                            mainPlatformId: platform.id,
                            socialPlatformId: item.socialPlatform.id,
                            nbPosts: item.nbPosts ?? 0,
                            price: item.price,
                            oldPrice: item.oldPrice,
                        })
                ))
            )));
            if (campaign?.contractPlaceholder?.additionalContractItems?.length) {
                campaign?.contractPlaceholder?.additionalContractItems.map((item) => additionalItems.push(item));
            }
            if (!campaign.contracts
                .length) {
                additionalItems = [...campaign?.contractPlaceholder?.distribution];
            }
            return {
                ...state,
                campaign: action.payload,
                distributionArr: campaign?.contractPlaceholder?.distribution,
                isEditable: campaign?.contractPlaceholder?.flags?.isEditable,
                canCounter: campaign?.contractPlaceholder?.flags?.canCounter,
                initialContract: {
                    ...state.initialContract,
                    contractItems, distribution: additionalItems,
                    description: campaign?.contracts[0]?.description ?? '',
                    personalOffer: campaign?.contracts[0]?.personalOffer ?? '',
                },
                canWriteComment: [OfferStatusEnum.NEGOTIATE, OfferStatusEnum.PENDING].includes(campaign?.contracts[0]?.status),
                // isCharityOrPrivate: (campaign?.status === CampaignStatusEnum.PRIVATE || campaign?.isCharity) ? true : false,
                isCharity: campaign?.isCharity,
                isTransparentPricing: campaign?.isTransparentPricing,
                hasUnreadComments: campaign?.contracts[0]?.hasUnreadComments,
            };
        },
        setContractModalStatus: (state, action) => {
            const contractModalStatus = action.payload;
            return {...state, contractModalStatus};
        },
        setProfileInfo: (state, action) => {
            const user = action.payload;
            return {...state, user};
        },
        setContractItems: (state, action) => {
            const contractItems = action.payload;
            return {...state, initialContract: {...state.initialContract, contractItems}};
        },
        addAdditionalItem: (state, action) => {
            const distributionItem = action.payload;
            const distributionArr: IInfluencerDistribution[] = [...state?.initialContract?.distribution];
            distributionArr.push({...distributionItem});
            if (distributionItem?.type === 'additional') {
                return {
                    ...state,
                    additionalItemId: state.additionalItemId + 1,
                    initialContract: {...state.initialContract, distribution: distributionArr},
                };
            }
            return {...state, initialContract: {...state.initialContract, distribution: distributionArr}};
        },
        deleteAdditionItem: (state, action) => {
            const index = action.payload;
            const distributionArr = [...state?.initialContract?.distribution];
            distributionArr.splice(index, 1);
            return {...state, initialContract: {...state.initialContract, distribution: distributionArr}};
        },
        setAdditionalItems: (state, action) => {
            const distribution = action.payload;
            return {...state, initialContract: {...state.initialContract, distribution}};
        },
        setDistributionItems: (state, action) => {
            const distributionArr = action.payload;
            return {...state, distributionArr};
        },
        setPersonalOfferDescription: (state, action) => {
            return {...state, initialContract: {...state.initialContract, personalOffer: action.payload}};
        },

    },
});

export const {
    setInitialState,
    setActiveTab,
    setContractModalDetails,
    setContractModalStatus,
    setCampaignId,
    setUserId,
    setContractItems,
    addAdditionalItem,
    deleteAdditionItem,
    setAdditionalItems,
    setDistributionItems,
    setPersonalOfferDescription,
    setProfileInfo,
} = contractBrand.actions;
