import moment from 'moment';
import React, {SetStateAction, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useHistory, useLocation} from 'react-router-dom';
import {Campaign} from '../../../../model/campaign/Campaign';
import CardLayout from '../../../../shared/components/card/CardLayout';
import EmptyState from '../../../../shared/components/placeholder/EmptyState';
import {ErrorToast} from '../../../../utils/toasters';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {IMessageUnreadQuery} from '../../../brand/campaign/messages/dto/IMessage';
import CreateEventModal, {INewEvent} from '../../planner/components/CreateEventModal';
import PreviewEventModal from '../../planner/components/PreviewEventModal';
import ProposalModal from '../../planner/components/ProposalModal';
import {InfluencerReportingService} from '../service';
import AddReportingView from './AddReportingView';
import AdditionalReportingModal from './modals/AdditionalReportingModal';
import FeedbackModal from './modals/FeedbackModal';
import ReportingModal from './modals/ReportingModal';
import ReportingAdditionalCard from './ReportingAdditionalCard';
import ReportingAlert from './ReportingAlert';
import ReportingCampaignCard, {ISelectedCampaign, ISelectedEvent} from './ReportingCampaignCard';
import ReportingPerCampaignHeader from './ReportingPerCampaignHeader';

interface ILocation {
    campaignId?: number;
    offerId?: number;
}

interface Props {
    updateReports: boolean;
    setUpdateReports: React.Dispatch<SetStateAction<boolean>>;
    handleShowFields: (key: 'showReporting' | 'showPerformance') => void;
    filterParams: any;
    setFilterParams: React.Dispatch<SetStateAction<any>>;
}


const ReportingPerCampaign = ({
                                  updateReports,
                                  setUpdateReports,
                                  filterParams,
                                  setFilterParams,
                              }: Props) => {
    const {state} = useLocation();
    const {t} = useTranslation();
    const history = useHistory();
    const [count, setCount] = useState(0)
    const [selectedEvent, setSelectedEvent] = useState<iManageList>();
    const [isPreviewEventOpened, setIsPreviewEventOpened] = useState(false);
    const [isProposalOpened, setIsProposalOpened] = useState(false)
    const [selectedReviewId, setSelectedReviewId] = useState(0);
    const [selectedOfferId, setSelectedOfferId] = useState(0);
    const [reportingCampaigns, setReportingCampaigns] = useState<Campaign[]>([]);
    const [isFeedbackOpened, setIsFeedbackOpened] = useState(false);
    const [isNewReporting, setIsNewReporting] = useState(false);
    const [isAdditional, setIsAdditional] = useState(false);
    const [isAdditionalModalOpened, setIsAdditionalModalOpened] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState<ISelectedCampaign>();
    const [isReportOpened, setIsReportOpened] = useState(false);
    const [selectedOfferItem, setSelectedOfferItem] = useState<ISelectedEvent | undefined>();
    const [selectedIdOffer, setSelectedIdOffer] = useState<number>();
    const [createEvent, setCreateEvent] = useState<INewEvent>({
        postingDate: null,
        caption: null,
        offerItemId: 0,
    });
    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
    const [startScroll, setStartScroll] = useState(false);

    const handleCloseReportingModal = () => {
        setIsReportOpened(false);
        setSelectedIdOffer(0);
        setSelectedOfferItem(undefined);
    };

    const handleCloseFeedbackModal = () => {
        setIsFeedbackOpened(false);
        setSelectedReviewId(0);
        setUpdateReports(prev => !prev);
    };

    useEffect(() => {
        InfluencerReportingService.getReports({
            ...filterParams,
            campaignId: (state as ILocation)?.campaignId ?? (filterParams as unknown as IMessageUnreadQuery)?.campaignId ?? '',
        }).then(response => {
            setCount(response.count);
            setReportingCampaigns((prev) => (startScroll ? [...prev, ...response?.data] : response?.data));
            if ((state as ILocation)?.campaignId) {
                history.replace({});
            }
        }).catch(error => ErrorToast(error));
        setStartScroll(false);
        // eslint-disable-next-line
    }, [JSON.stringify(filterParams), updateReports]);

    useEffect(() => {
        if (!!selectedIdOffer) {
            InfluencerReportingService.getCampaignReports(selectedIdOffer)
                .then(response => {
                    setSelectedOfferItem(response);
                    setIsReportOpened(true);
                })
                .catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [selectedIdOffer]);

    useEffect(() => {
        if (!!(state as ILocation)?.offerId) {
            setSelectedIdOffer((state as ILocation)?.offerId);
            history.replace({});
        }
        // eslint-disable-next-line
    }, [(state as ILocation)?.offerId])

    useEffect(() => {
        if (!filterParams?.campaignId) {
           setFilterParams((prev: any) => ({...prev, name: ''}))
        }
    }, [filterParams?.campaignId])

    return (
        <>
            <CreateEventModal createEvent={createEvent} isDisabled
                              setCreateEvent={setCreateEvent}
                              handleCloseModal={() => {
                                  setCreateEvent({
                                      postingDate: null,
                                      caption: null,
                                      offerItemId: 0,
                                  })
                                  setIsCreateModalOpened(false);
                                  setUpdateReports(prev => !prev);
                              }}
                              isCreateModalOpened={isCreateModalOpened}/>
            <ProposalModal setState={setSelectedEvent as React.Dispatch<SetStateAction<iManageList>>}
                           state={selectedEvent as iManageList} isModalOpened={isProposalOpened}
                           handleCloseModal={() => {
                               setIsProposalOpened(false);
                               setFilterParams((prev: any) => ({...prev, updatedAt: new Date()?.getTime()}))
                           }}/>
            <AdditionalReportingModal selectedOfferItem={selectedOfferItem as ISelectedEvent}
                                      isAdditional={isAdditional}
                                      setUpdateReports={setUpdateReports}
                                      setSelectedOfferItem={setSelectedOfferItem}
                                      isModalOpened={isAdditionalModalOpened} handleCloseModal={() => {
                setIsAdditionalModalOpened(false);
            }}/>
            <ReportingModal handleCloseModal={handleCloseReportingModal} isAdditional={isAdditional}
                            setSelectedOfferItem={setSelectedOfferItem as React.Dispatch<SetStateAction<ISelectedEvent | undefined>>}
                            setUpdateReports={setUpdateReports as React.Dispatch<SetStateAction<boolean>>}
                            selectedOfferItem={selectedOfferItem as ISelectedEvent}
                            isModalOpened={isReportOpened}/>
            <AddReportingView setUpdateReports={setUpdateReports} isAdditional={isAdditional}
                              offerId={selectedOfferId ?? 0}
                              selectedCampaign={selectedCampaign as ISelectedCampaign}
                              clearAdditional={() => {
                                  setIsAdditional(false);
                                  setSelectedOfferId(0);
                              }}
                              setSelectedCampaign={setSelectedCampaign}
                              setIsNewReporting={setIsNewReporting}
                              isNewReporting={isNewReporting}/>
            <FeedbackModal id={selectedReviewId as number} handleCloseModal={handleCloseFeedbackModal}
                           isModalOpened={isFeedbackOpened}/>
            <PreviewEventModal state={selectedEvent as iManageList} isModalOpened={isPreviewEventOpened}
                               setUpdateReports={setUpdateReports}
                               selectedYearMonth={{
                                   currentYear: '2023',
                                   currentMonth: '4'
                               }}
                               setState={setSelectedEvent as React.Dispatch<SetStateAction<iManageList>>}
                               handleCloseModal={() => setIsPreviewEventOpened(false)}/>
            <InfiniteScroll
                dataLength={reportingCampaigns?.length}
                next={() => {
                    setStartScroll(true);
                    setFilterParams((prev: any) => ({...prev, page: prev.page+1}));
                }}
                endMessage={
                    <p style={{textAlign: 'center'}}>
                        <b>{t('influencer.reporting.haveSeenAllReportings')}</b>
                    </p>
                }
                hasMore={reportingCampaigns?.length < count}
                loader={<h4>Loading...</h4>}
            >
                {!!reportingCampaigns?.length ? reportingCampaigns?.map((reporting) => {
                    const {
                        id,
                        title,
                        campaignImages,
                        postingDeadline,
                        company,
                        isReviewed,
                        offers,
                        isCharity,
                        totalReporting: {totalCalendar, totalPosts, reportStats},
                    } = reporting;
                    const isAddonsOnly = offers?.[0]?.isAddonsOnly;
                    const isLongTerm = offers?.[0]?.isLongTerm;
                    const statusName = offers?.[0]?.status === 'accepted' ? 'ongoing' : offers?.[0]?.status;
                    const isOverdue = new Date(postingDeadline) < new Date() && statusName !== 'completed';
                    const overdueStatus = isOverdue ? t(`general.campaign_status.overdue`) : t(`general.campaign_status.${statusName}`);
                    const statusClass = isOverdue ? 'overdue' : offers?.[0]?.status;
                    return (
                        <React.Fragment key={`${id}-${offers?.[0]?.id}`}>
                            {((totalCalendar < totalPosts && ((moment().diff(postingDeadline) >= 0) && (!offers?.[0]?.reviews?.length)))
                                    && offers?.[0]?.status === 'accepted') &&
                                <ReportingAlert name={company?.displayName ?? ''}/>}
                            <CardLayout classCard="my-3">
                                <ReportingPerCampaignHeader reportStats={reportStats?.[0]}
                                                            isAddonsOnly={isAddonsOnly}
                                                            isLongTerm={isLongTerm}
                                                            offerId={offers[0]?.id}
                                                            campaignId={id}
                                                            campaignImages={campaignImages?.[0]}
                                                            companyName={company?.displayName ?? '-'}
                                                            overdueStatus={overdueStatus}
                                                            postingDeadline={postingDeadline}
                                                            statusClass={statusClass}
                                                            totalCalendar={totalCalendar}
                                                            totalPosts={totalPosts}
                                                            title={title}
                                                            isInfluencerSide={true}
                                />

                                <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
                                    {offers?.[0]?.offerItems?.map((offerItem) => (
                                        <ReportingCampaignCard offerItem={offerItem} isReviewed={isReviewed}
                                                               setCreateEvent={setCreateEvent} campaign={reporting}
                                                               setIsCreateModalOpened={setIsCreateModalOpened}
                                                               setSelectedCampaign={setSelectedCampaign}
                                                               offerStatus={offers?.[0]?.status}
                                                               setIsNewReporting={setIsNewReporting}
                                                               setIsPreviewEventOpened={setIsPreviewEventOpened}
                                                               setIsProposalOpened={setIsProposalOpened}
                                                               setIsAdditional={setIsAdditional}
                                                               setSelectedEvent={setSelectedEvent}
                                                               setSelectedIdOffer={setSelectedIdOffer as React.Dispatch<SetStateAction<number>>}
                                                               key={offerItem?.id}
                                        />
                                    ))}
                                    {offers?.[0]?.additionalReportItems?.map((additionalItem) =>
                                        <ReportingAdditionalCard offerItem={additionalItem}
                                                                 setIsAdditional={setIsAdditional}
                                                                 setSelectedOfferItem={setSelectedOfferItem as React.Dispatch<SetStateAction<ISelectedEvent | undefined>>}
                                                                 setIsReportOpened={setIsAdditionalModalOpened}
                                                                 key={additionalItem?.id}/>
                                    )}
                                    <div className="reporting-card align-self-stretch mt-3 default-radius px-2">
                                        <CardLayout classBody="p-0">
                                            <div
                                                className="reporting-img-container">
                                                <img src="/icons/placeholders/upload.svg"
                                                     alt="upload-icon"
                                                />
                                                <p className="text-muted text-center mb-0">
                                                    {t('influencer.reporting.placeholder')}
                                                </p>
                                            </div>
                                            {!(Number(totalCalendar) < Number(totalPosts)) &&
                                                <div className="d-flex justify-content-center py-2">
                                                    {!(Number(totalCalendar) < Number(totalPosts)) &&
                                                        <Button variant="outline-primary"
                                                                onClick={() => {
                                                                    setSelectedCampaign({
                                                                        campaign: reporting,
                                                                        offerItemId: 0,
                                                                    });
                                                                    setSelectedOfferId(offers?.[0]?.id);
                                                                    setIsNewReporting(true);
                                                                    setIsAdditional(true);
                                                                }}>
                                                            {t('general.button.addMore')}
                                                        </Button>}
                                                </div>
                                            }
                                        </CardLayout>
                                    </div>
                                    <div className="col-12 mt-3">
                                        {!isCharity &&
                                            <div className="d-flex justify-content-end align-items-end h-100">
                                                <Button variant="outline-primary"
                                                        onClick={() => {
                                                            history.push('/influencer/invoices', {offerId: offers?.[0]?.id})
                                                        }}>
                                                    {t('influencer.invoice.invoice')}
                                                </Button>
                                            </div>}
                                    </div>
                                </div>
                            </CardLayout>
                        </React.Fragment>
                    );
                }) : <CardLayout>
                        <EmptyState/>
                     </CardLayout>
                }
            </InfiniteScroll>
        </>
    );
};

export default ReportingPerCampaign;
