import React, {ChangeEvent, SetStateAction, useCallback} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {CustomAutocomplete} from '../../../../../../shared/components/Autocomplete';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import {IDeclineOffer} from '../../../dto/IDeclineOffer';
import {UserRoles} from '../../../../../../enum/UserRoles';
import {hasAccessRights} from '../../../../../../shared/functions/Functions';
import {AccessRightEnum} from '../../../../../../enum/AccessRightEnum';

interface IDeclineOfferModalProps {
    show: boolean;
    closeModal: () => void;
    declineOffer: () => void;
    date: string;
    setDeclineReason?: React.Dispatch<SetStateAction<IDeclineOffer>>;
    isContract?: boolean;
}

export function DeclineOfferModal({show, closeModal, declineOffer, setDeclineReason, date, isContract}: IDeclineOfferModalProps) {
    const {t} = useTranslation();
    const {
        OfferDeclineReasonEnum,
        OfferDeclineReasonInfluencerEnum
    } = useSelector((states: IAllStates) => states.enums);
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    const isInfluencer = userType === UserRoles.INFLUENCER;
    const isAgency = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles);

    const changeHandler = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event?.target?.value;
        if (setDeclineReason) {
            setDeclineReason(prevState => ({...prevState, declineComment: value}));
        }
    }, [setDeclineReason]);

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        closeModal();
        declineOffer();
    };

    const multipleSelectHandler = (paramKey: string, paramValue: any) => {
        if (setDeclineReason) {
            setDeclineReason(prevState => ({...prevState, [paramKey]: !!`${paramValue}`?.length ? paramValue : ''}));
        }
    };

    return (
        <>
            <Modal show={show}
                   onHide={closeModal}
                   className="modal-dark"
                   size="sm"
                   centered={true}
            >
                <form onSubmit={onSubmit}>
                    <Modal.Body>
                        <h5 className="text-center">
                            {isContract ?
                                t('brand.campaign.book.contract.confirm_decline_contract')
                                : t('brand.campaign.book.offer.confirm_decline_offer')
                            }
                        </h5>
                        <p className="text-center">
                            {isContract ?
                                t('brand.campaign.book.contract.decline_contract_desc', {date}) :
                                t('brand.campaign.book.offer.decline_offer_desc', {date})
                            }
                        </p>
                        <CustomAutocomplete
                            dataArray={(isInfluencer || isAgency) ? OfferDeclineReasonInfluencerEnum : OfferDeclineReasonEnum}
                            isWithImage={false} inputLabel="decline_reason"
                            isMultiple={true}
                            queryFilterKey="declineReason"
                            inputLabelTranslation={`${t('brand.campaign.book.offer.reason')}`}
                            handleChangeParams={multipleSelectHandler}/>
                        <Form.Control
                            id="basic-textarea"
                            as="textarea"
                            onChange={changeHandler}
                            className="mt-3"
                            required
                            name="declineComment"
                            rows={3}
                            placeholder={t('influencer.offer_management.modal.add_comment')}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={closeModal} variant="outline-primary" className="btn-sm mx-2">
                            {t(`general.button.cancel`)}
                        </Button>
                        <Button type="submit" variant="primary" className="btn-sm mx-2">
                            {t(`general.button.yes`)}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};
