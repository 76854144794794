import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, OverlayTrigger, Table} from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import {useTranslation} from 'react-i18next';
import {BsExclamationOctagon} from 'react-icons/bs';
import {GoDotFill} from 'react-icons/go';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Campaign} from '../../../../model/campaign/Campaign';
import {ITotalPlanned} from '../../../../model/offer/IOfferItems';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import EmptyState from '../../../../shared/components/placeholder/EmptyState';
import {Pagination} from '../../../../shared/components/Pagination';
import {
    formatDate,
    getCampaignNumbers,
    getCorrectColor,
    getCorrectFilterTitle,
    getPlatformIcon,
    truncateString,
} from '../../../../shared/functions/Functions';
import usePagination from '../../../../shared/hooks/usePagination';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast} from '../../../../utils/toasters';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {IMyCampaignItem} from '../../campaign/dto/IMyCampaigns';
import {InfluencerPlannerService} from '../service';
import {INewEvent} from './CreateEventModal';

const statusesWithIcon = ['proposed'];

interface Props {
    setIsCreateModalOpened: React.Dispatch<SetStateAction<boolean>>;
    setIsEditModalOpened: React.Dispatch<SetStateAction<boolean>>;
    setCreateEvent: React.Dispatch<React.SetStateAction<INewEvent>>;
    isToDoSelected: boolean;
    setIsProposalOpened: React.Dispatch<SetStateAction<boolean>>;
    setSelectedEvent: React.Dispatch<SetStateAction<iManageList | undefined>>;
    isCreateModalOpened: boolean;
    isEditModalOpened: boolean;
    isProposalOpened: boolean;
}

export const statuses = ['proposed', 'scheduled', 'feedback_loop', 'approved', 'reported'];

const PlannerCalendarListView = ({
                                     isToDoSelected,
                                     setCreateEvent,
                                     setIsCreateModalOpened,
                                     setIsProposalOpened,
                                     setSelectedEvent,
                                     isCreateModalOpened,
                                     isEditModalOpened,
                                     isProposalOpened,
                                     setIsEditModalOpened,
                                 }: Props) => {
    const {t} = useTranslation();
    const [headerTotalPlanned, setHeaderTotalPlanned] = useState<ITotalPlanned>();
    const [isOpenedModal, setIsOpenedModal] = useState(false);
    const {
        filterParams: filterData,
        handlePages,
        totalPages,
        setTotalPages,
    } = usePagination({});
    const [plannerPlatforms, setPlannerPlatforms] = useState<IMyCampaignItem[]>([]);
    const [count, setCount] = useState(0);
    const {filterParams} = useSelector((state: IAllStates) => state.plannerInfluencer);
    useEffect(() => {
        const isModalClosed = !isEditModalOpened && !isCreateModalOpened;
        if (isModalClosed) {
            InfluencerPlannerService.getPlannerPlatforms({
                ...filterParams,
                ...filterData,
                page: isToDoSelected ? 1 : filterData?.page,
                onlyTodo: isToDoSelected,
                campaignId: filterParams?.campaignId,
            }).then(response => {
                setHeaderTotalPlanned(response.totalPlanned);
                setPlannerPlatforms(response.data);
                setCount(response?.count);
                setTotalPages(Math.ceil(response.count / response.perPage));
            }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [filterParams, filterData, isCreateModalOpened, isEditModalOpened, isProposalOpened, isOpenedModal, isToDoSelected]);

    const handleOpenModal = async (eventId: string) => {
        await InfluencerPlannerService.getPlannerModalData(eventId).then(data => {
            setSelectedEvent(data);
            setIsOpenedModal(prev => !prev);
            if (data.status === 'proposed') {
                setIsProposalOpened(true);
            } else {
                setIsEditModalOpened(true);
            }
        }).catch(err => ErrorToast(err));

        // if (data.status === 'reported') {
        //     history.push('/influencer/reporting', {
        //         offerId: data?.id
        //     });

    };

    // const handleOpenPlannerModal = async (eventId: string) => {
    //     const data = await InfluencerPlannerService.getPlannerModalData(eventId);
    //     setSelectedEvent(data);
    //     setIsOpenedModal(prev => !prev);
    //     setIsEditModalOpened(true);
    // }

    const handleOpenPlannerModal = async (eventId: number, offerItemId: number, campaign: IMyCampaignItem) => {
        if (!eventId) {
            setCreateEvent({
                campaign: campaign as unknown as Campaign,
                campaignId: campaign?.id,
                offerItemId,
                postingDate: new Date().toISOString(),
                caption: null,
            });
            setIsCreateModalOpened(true);
        } else {
            await handleOpenModal(eventId as unknown as string);
        }
    };
    return (
        <>
            {!!plannerPlatforms?.length ?
                <Table className="table-head-bg opacity-1 mt-3 w-max-content w-md-100" responsive>
                    <thead className="table-head-bg">
                    <tr>
                        <th>
                            <div className="d-flex flex-column w-fit-content text-center">
                        <span className="font-weight-semi-bold text-dark">
                            {count}
                        </span>
                                <span className="text-primary">
                            {t('influencer.planner.campaigns')}
                        </span>
                            </div>
                        </th>
                        <th>
                            <div className="text-center">
                                {t('influencer.planner.date')}
                            </div>
                        </th>
                        {statuses?.map(offerItem => {
                            return (
                                <th key={offerItem}>
                                    <div className="text-center d-flex flex-column align-items-center">
                                 <span className="font-weight-semi-bold text-dark">
                                     {
                                         getCampaignNumbers(offerItem as keyof ITotalPlanned, headerTotalPlanned as ITotalPlanned)}
                                     /
                                     {
                                         headerTotalPlanned?.totalNbPosts ?? 0}
                                </span>
                                        {statusesWithIcon?.includes(offerItem) ?
                                            <div className="mx-1 d-flex align-items-center">
                                                {offerItem === 'proposed' &&
                                                    <BsExclamationOctagon className="proposed-icon"/>}
                                                <span className="ml-1 text-dark font-weight-normal">
                                                    {getCorrectFilterTitle(offerItem, t)}
                                                </span>
                                            </div> : <span key={offerItem}
                                                           className={`calendar-button-dot
-                                            mx-1 text-dark font-weight-normal ${getCorrectColor(offerItem)}`}>
                                                                                   {getCorrectFilterTitle(offerItem, t)}
                                            </span>
                                        }
                                    </div>
                                </th>
                            );
                        })}
                        <th>{t('influencer.planner.actions')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {plannerPlatforms?.map((campaign) => {
                        return <React.Fragment key={`${campaign?.id}-${campaign?.offers?.[0]?.id}`}>
                            <tr className="text-dark">
                                <td>
                                    <Link className="d-flex align-items-center cursor-pointer text-dark"
                                          to={`/influencer/campaign/${campaign?.id}/overview`}
                                    >
                                        <CustomImageHandler
                                            photoPath={campaign?.campaignImages?.[0]?.path as string}
                                            altTag="Campaign logo" classes="campaign-icon"/>
                                        <span className="font-weight-bold ml-1">
                                    {truncateString(campaign?.title, 35)}
                                </span>
                                    </Link>
                                </td>
                                <td></td>
                                {statuses?.map(offerStatus => (
                                    <td key={offerStatus}>
                                        <div className="text-center">
                                            {getCampaignNumbers(offerStatus as keyof ITotalPlanned, campaign?.offers?.[0]?.totalPlanned)}
                                            /
                                            {campaign?.offers[0]?.totalNbPosts ?? 0}
                                        </div>
                                    </td>
                                ))}
                            </tr>

                            {campaign?.offers?.[0]?.offerItems?.map((offerItem) => {
                                return (
                                    <React.Fragment key={offerItem?.id}>
                                        {offerItem?.offerItemEvents?.map((event, index) => {
                                            return (
                                                <tr key={event?.id ?? index}
                                                    className={`${event?.status === 'proposed' || !event?.status ? 'bg-propose-light' : ''}`}>
                                                    <td className="pl-5 d-flex align-items-center">
                                                        <div className="d-flex align-items-center cursor-pointer"
                                                             onClick={
                                                                 () => handleOpenPlannerModal(event?.id, offerItem?.id, campaign)}>
                                                            <img src={offerItem?.socialPlatform?.path}
                                                                 alt={offerItem?.socialPlatform?.name}/>
                                                            <span className="font-weight-semi-bold text-dark ml-1">
                                                                {t(`general.platforms.${offerItem?.socialPlatform?.name}`) + ` ${event.platformPosition ?? '-'}/${offerItem?.nbPosts}`}
                                                            </span>
                                                        </div>
                                                        {campaign?.offers?.[0]?.isLongTerm &&
                                                            <div className={'custom-badge long-term ml-1'}>
                                                                {t(`brand.campaign.briefing.overview_card.linked`)}
                                                            </div>}
                                                        {campaign?.offers?.[0]?.isAddonsOnly &&
                                                            <div className={'custom-badge additional ml-1'}>
                                                                {t('influencer.offer_management.modal.additional')}
                                                            </div>}
                                                        {event?.status === 'proposed' &&
                                                            <div className="ml-2 d-flex align-items-center">
                                                                <BsExclamationOctagon className="proposed-icon"/>
                                                                <span className="pl-1">Proposed</span>
                                                            </div>}
                                                        {!event?.id && <OverlayTrigger overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {t('influencer.planner.notScheduled')}
                                                            </Tooltip>
                                                        }
                                                        >
                                                            <div className="ml-2 cursor-pointer">
                                                                <img src="/icons/tick-mark.png" alt="tick-mark icon"/>
                                                            </div>
                                                        </OverlayTrigger>}
                                                    </td>
                                                    <td>
                                                        {!!event?.date ? <p className="text-center my-2">
                                                            {formatDate(event?.date)}
                                                        </p> : ''}
                                                    </td>
                                                    {statuses?.map(status => (
                                                        <td key={status}>
                                                            {getPlatformIcon(
                                                                event?.totalPlanned,
                                                                status as keyof ITotalPlanned,
                                                            )}
                                                        </td>
                                                    ))}
                                                    <td>
                                                        <div className="position-relative w-fit-content w-100">
                                                            {event?.eventNotifications?.[0]?.isUnread ?
                                                                <GoDotFill size={15}
                                                                           className="text-danger top-right-bullet"
                                                                           style={{right: '-4%'}}
                                                                /> : ''}
                                                            <Button
                                                                className="w-100"
                                                                variant={`${!event?.id || event?.status === 'proposed' ? 'primary'
                                                                    : 'outline-primary'}`}
                                                                onClick={() => handleOpenPlannerModal(event?.id, offerItem?.id, campaign)}>
                                                                {t(!event?.id || event?.status === 'proposed' ? 'general.button.schedule' : 'general.open')}
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </React.Fragment>
                    })}
                    </tbody>
                </Table> : <div className="border-top">
                    <EmptyState/>
                </div>}
            {(totalPages > 1 && !isToDoSelected) && <div className="d-flex justify-content-end">
                <Pagination
                    page={filterData.page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                />
            </div>}
        </>
    );
};

export default PlannerCalendarListView;
