import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/es/storage';
import {User} from '../../model/user/User';
import {FavoriteCampaign} from '../../model/campaign/FavoriteCampaign';
import {CompletionInfo} from '../../model/shared/CompletionInfo';

export const authActionTypes = {
  LOGIN: '[Login] Action',
  LOGIN_FAILURE: '[LoginFailure] Action',
  LOGOUT: '[Logout] Action',
  REGISTER: '[Register] Action',
  SET_USER: '[Set User] Action',
  SET_2FA: '[2FA] Action',
  GET_USER: '[Get User] Action',
  UPDATE_USER: '[Update User] Action',
  UPDATE_BANNER: '[Update Banner] Action',
  UPDATE_TERMS: '[Update Terms] Action',
  UPDATE_INVOICE_ALERT: '[Update Invoice] Action',
  UPDATE_USER_COMPANY: '[Update Company] Action',
  UPDATE_COMPLETION_INFO: '[Update Completion] Action',
  UPDATE_FAVORITE_CAMPAIGNS: '[Update Favorite Campaign] Action',
  DELETE_FAVORITE_CAMPAIGN: '[Delete Favorite Campaign] Action',
  UPDATE_LANGUAGE: '[Update language] Action',
  SET_OLD_USER: '[Set old user] Action',
  SET_OLD_TOKEN: '[Set old token] Action',
  DELETE_IMPERSONATION: '[Delete impersonation] Action',
  UPDATE_EXTERNAL_TOKENS: '[Decrease searches] Action',
};

export interface IAuthState {
  user: User;
  authToken: string;
  oldImpersonatedUser: User;
  oldAuthToken: string;
  impersonatedByAdmin: string;
  isVerified: boolean;
}

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  impersonatedByAdmin: undefined,
  oldImpersonatedUser: undefined,
  oldAuthToken: undefined,
  isVerified: false,
};

export const reducer = persistReducer<any, any>(
  {storage, key: 'auth', whitelist: ['user', 'authToken', 'oldAuthToken', 'oldImpersonatedUser', 'isVerified']},
  (state = initialAuthState, action) => {
    switch (action.type) {
      case authActionTypes.LOGIN: {
        const {authToken} = action.payload;
        return {...state, authToken};
      }
      case authActionTypes.LOGOUT: {
        return initialAuthState;
      }
      case authActionTypes.SET_USER: {
        const {user} = action.payload;
        return {...state, user};
      }
      case authActionTypes.SET_2FA: {
        const {isVerified} = action.payload;
        return {...state, isVerified};
      }
      case authActionTypes.DELETE_IMPERSONATION: {
        return {...state, oldAuthToken: undefined, oldImpersonatedUser: undefined, impersonatedByAdmin: false};
      }
      case authActionTypes.SET_OLD_USER: {
        return {...state, oldImpersonatedUser: state.user};
      }
      case authActionTypes.SET_OLD_TOKEN: {
        const {oldAuthToken} = action.payload;
        return {...state, oldAuthToken};
      }
      case authActionTypes.UPDATE_USER: {
        const {user} = action.payload;
        return {
          ...state, user: {
            ...state.user, ...user,
          },
        };
      }
      case authActionTypes.UPDATE_BANNER: {
        const hasSeenBanner = action.payload;
        return {
          ...state, user: {
            ...state.user, hasSeenBanner,
          },
        };
      }
      case authActionTypes.UPDATE_TERMS: {
        const newTermsNotification = action.payload;
        return {
          ...state, user: {
            ...state.user, newTermsNotification,
          },
        };
      }
      case authActionTypes.UPDATE_INVOICE_ALERT: {
        const invoiceDataAlert  = action.payload;
        return {
          ...state, user: {
            ...state.user, invoiceDataAlert,
          },
        };
      }
      case authActionTypes.UPDATE_USER_COMPANY: {
        const {company} = action.payload;
        return {
          ...state, user: {
            ...state.user, company: {
              ...state.user.company, ...company,
            },
          },
        };
      }
      case authActionTypes.UPDATE_COMPLETION_INFO: {
        const {completion} = action.payload;
        return {
          ...state, user: {
            ...state.user, completionInfo: {
              ...state.user.completionInfo, ...completion,
            },
          },
        };
      }
      case authActionTypes.UPDATE_FAVORITE_CAMPAIGNS: {
        const {favoriteCamp} = action.payload;
        return {
          ...state, user: {
            ...state.user, favoriteCampaigns: [
              ...favoriteCamp,
            ],
          },
        };
      }
      case authActionTypes.DELETE_FAVORITE_CAMPAIGN: {
        const {id} = action.payload;
        const favoriteCamp = state?.user?.favoriteCampaigns?.filter((item: any) => item.id !== id);
        return {
          ...state, user: {
            ...state.user, favoriteCampaigns: [
              ...favoriteCamp,
            ],
          },
        };
      }
      case authActionTypes.UPDATE_LANGUAGE: {
        const locale = action.payload;
        return {
          ...state, user: {
            ...state.user, locale,
          },
        };
      }
      case authActionTypes.UPDATE_EXTERNAL_TOKENS: {
        const externalSearchTokens = state?.user?.company?.externalSearchTokens - 1;
        return {
          ...state, user: {
            ...state.user, company: {
              ...state.user.company, externalSearchTokens,
            },
          },
        };
      }
      default:
        return state;
    }
  },
);

export const actions = {
  login: (authToken: string) => ({type: authActionTypes.LOGIN, payload: {authToken}}),
  logout: () => ({type: authActionTypes.LOGOUT}),
  setUser: (user: User) => ({type: authActionTypes.SET_USER, payload: {user}}),
  setIsVerified: (isVerified: { isVerified: boolean }) => ({type: authActionTypes.SET_2FA, payload: {isVerified}}),
  updateUser: (user: User) => ({type: authActionTypes.UPDATE_USER, payload: {user}}),
  updateAgencyBanner: (isVisibleBanner: boolean) => ({type: authActionTypes.UPDATE_BANNER, payload: isVisibleBanner}),
  updateNewTermsService: (newTermsNotification: boolean) => ({type: authActionTypes.UPDATE_TERMS, payload: newTermsNotification}),
  updateInvoiceAlert: (isVisible: boolean) => ({type: authActionTypes.UPDATE_INVOICE_ALERT, payload: isVisible}),
  updateCompany: (company: any) => ({type: authActionTypes.UPDATE_USER_COMPANY, payload: {company}}),
  updateComletionInfo: (completion: CompletionInfo) => ({
    type: authActionTypes.UPDATE_COMPLETION_INFO,
    payload: {completion},
  }),
  updateFavoriteCampaign: (favoriteCamp: FavoriteCampaign[]) => ({
    type: authActionTypes.UPDATE_FAVORITE_CAMPAIGNS,
    payload: {favoriteCamp},
  }),
  deleteFavoriteCampaign: (id: number) => ({type: authActionTypes.DELETE_FAVORITE_CAMPAIGN, payload: {id}}),
  updateLanguage: (language: string) => ({type: authActionTypes.UPDATE_LANGUAGE, payload: language}),
  setOldUser: () => ({type: authActionTypes.SET_OLD_USER, payload: {}}),
  setOldToken: (oldAuthToken: string) => ({type: authActionTypes.SET_OLD_TOKEN, payload: {oldAuthToken}}),
  updateExternalTokens: () => ({type: authActionTypes.UPDATE_EXTERNAL_TOKENS}),
  deleteImpersonation: () => ({type: authActionTypes.DELETE_IMPERSONATION}),
};
