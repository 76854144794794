import React, {SetStateAction} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Invoice, MessagesIcon, OffersIcon, PlannerIcon, ReportingIcon} from '../../../../../shared/Icons';
import {SupportWidget} from '../../../../../shared/SupportWidget';
import {IMyCampaignsFilterProps} from '../../../campaign/dto/IMyCampaigns';
import {IMyOffers} from '../../dto/IDashboard';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {UserRoles} from '../../../../../enum/UserRoles';
import {openCalendlyPopup} from '../../../../../shared/functions/Functions';
import CardLayout from '../../../../../shared/components/card/CardLayout';

interface Props {
    myOffers: IMyOffers;
    setFilterParams: React.Dispatch<SetStateAction<IMyCampaignsFilterProps>>;
    filterParams: IMyCampaignsFilterProps;
}

const MyOffers = ({myOffers, setFilterParams, filterParams}: Props) => {
    const history = useHistory();
    const {t} = useTranslation();
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    const isAgency = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles)
    const handleActiveFilter = (active: 'planner' | 'reporting' | 'messages') => {
        if (active === filterParams?.dashboardFilter) {
            setFilterParams(prev => ({...prev, dashboardFilter: ''}));
        } else {
            setFilterParams(prev => ({...prev, dashboardFilter: active}));
        }
    }
    const navigateToOffers = (selected: 'to-do' | 'in-progress') => {
        isAgency ?
        history.push('/agency/offers', {selected}) :
        history.push('/influencer/offers', {selected});
    };

    const navigateToInvoices = () => {
        isAgency ?
        history.push('/agency/invoices') :
        history.push('/influencer/invoices');
    };
    return (
        <>
            <Row>
                <Col xs={8} className='px-0'>
                    <div className='pl-0 pr-1'>
                        <CardLayout classCard="border-dashed">
                            <div className="d-flex align-items-center">
                                <OffersIcon width="15" height="15"/>
                                <span className="font-weight-bold text-muted ml-1">
                                    {t('influencer.explore.offerManagement')}
                                </span>
                            </div>
                            <div className="mt-3 d-flex align-items-center">
                                <div className="d-flex align-items-end" onClick={() => navigateToOffers('to-do')}>
                                    <h4 className="mb-0 text-danger">{myOffers?.received ?? 0}</h4>
                                    <span className="ml-2">
                                        {t('brand.campaign.book.offer.filters.received')}
                                    </span>
                                </div>
                                <div className="d-flex align-items-end ml-3" onClick={() => navigateToOffers('in-progress')}>
                                    <h4 className="mb-0 text-primary">{myOffers?.sent ?? 0}</h4>
                                    <span className="ml-2">{t('influencer.dashboard.sentOffer')}</span>
                                </div>
                            </div>
                        </CardLayout>
                    </div>
                </Col>
                <Col className='col-4 px-0'>
                    <div className='pr-0 pl-1 h-100'>
                        <CardLayout classCard='h-100'>
                            <div className="d-flex align-items-center" onClick={navigateToInvoices}>
                                <Invoice width="15" height="15"/>
                                <span className="font-weight-bold text-muted ml-1">
                                    {t('influencer.profile.invoices')}
                                </span>
                            </div>
                            <div className="d-flex align-items-center mt-2">
                                <h4 className="text-primary mb-0">{myOffers?.availableInvoices ?? 0}</h4>
                                <span className="ml-2">{t('influencer.offer_management.modal.available')}</span>
                            </div>
                        </CardLayout>
                    </div>
                </Col>
            </Row>
            <div className="d-flex align-items-stretch justify-content-between mt-3 mb-4">
                <Card
                    className={`border-dashed d-flex justify-content-center ${filterParams?.dashboardFilter === 'planner' ? 'border-primary' : ''} p-3`}
                    onClick={() => handleActiveFilter('planner')}>
                    <div className="d-flex align-items-center">
                        <PlannerIcon width="15" height="15"/>
                        <span className="font-weight-bold text-muted ml-1">
                                {t('influencer.campaign.tabs.planner')}
                            </span>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <h4 className="text-primary mb-0">{myOffers?.plannerDone ?? 0}</h4>
                        <span className="font-weight-bold">/{myOffers?.plannerTodo ?? 0}</span>
                    </div>
                </Card>
                <Card
                    className={`border-dashed d-flex justify-content-center ${filterParams?.dashboardFilter === 'reporting' ? 'border-primary' : ''} mx-2 p-3`}
                    onClick={() => handleActiveFilter('reporting')}>
                    <div className="d-flex align-items-center">
                        <ReportingIcon width="15" height="15"/>
                        <span className="font-weight-bold text-muted ml-1">
                                {t('influencer.campaign.tabs.reporting')}
                            </span>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <h4 className="text-primary mb-0">{myOffers?.reportsTodo ?? 0}</h4>
                        <span className="font-weight-bold">/{myOffers?.reportsTotal ?? 0}</span>
                    </div>
                </Card>
                {isAgency ?
                <Card
                    className={`border-dashed d-flex justify-content-center border-primary p-3 box-shadow-primary`}>
                    <div className="d-flex align-items-start">
                        <div>
                            <MessagesIcon width="15" height="15"/>
                        </div>
                        <span className="font-weight-bold text-muted ml-1">
                           Creator Success Team
                        </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="text-underline text-primary cursor-pointer"
                             onClick={() => openCalendlyPopup('https://calendly.com/creator-success-team/creator-management-onboarding-call')!}>Book
                            meeting
                        </div>
                    </div>
                </Card> :
                <Card
                    className={`border-dashed d-flex justify-content-center border-primary p-md-3 p-2 box-shadow-primary`}>
                    <div className="d-flex align-items-start">
                        <div>
                            <MessagesIcon width="15" height="15"/>
                        </div>
                        <span className="font-weight-bold text-muted ml-1">
                            {t('influencer.dashboard.creatorSuccess')}
                        </span>
                    </div>
                    <div className="d-flex align-items-center ">
                        <img src="/tempAssets/Armin.png" className="user-img-rounded mr-md-2 mr-1" alt="Support profile"/>
                        <span className="font-weight-bold font-10">
                            <SupportWidget classes="mobile-support-icon" isForMobile/>
                            Support
                        </span>
                    </div>
                </Card>
                }
            </div>
        </>
    );
};

export default MyOffers;
