import {userSlideType} from '@fancyapps/ui/types/Carousel/types';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Fancybox as NativeFancybox} from '@fancyapps/ui';

interface IProps {
    classes?: string;
    platformItemId: number;
}

export const getSocialPlatformExampleNb = (socialId: number) => {
    switch (socialId) {
        case 11:
            return 1;
        case 1:
        case 4:
            return 3;
        case 8:
            return 4;
        case 7:
            return 6;
    }
    return 0;
};

const ReportingScreenshotExample = ({ classes = 'w-100 mt-3 mt-3', platformItemId}: IProps) => {
    const {t} = useTranslation();
    const nbOfImages = getSocialPlatformExampleNb(platformItemId);
    const images = [...Array(nbOfImages)].map((e, i) => `/tempAssets/reportingExamples/${platformItemId}/${i + 1}.png`);
    const galleryItems = images.map((image) => ({
        src: image,
        thumb: image,
        type: 'image',
    }));

    const openGallery = (e: any) => {
        e.preventDefault();
        NativeFancybox.show(galleryItems as unknown as userSlideType[]);
    };

    return (
      <>
          {nbOfImages > 0 && <div onClick={openGallery} style={{cursor: 'pointer'}}>
              <div className={`${classes} border border-primary default-radius p-2 cursor-pointer`}>
                  <img src="/icons/image-gallery.svg" alt="Gallery icon"/> <span
                className="font-weight-bold">{t('influencer.reporting.screenshotExample')}</span>
              </div>
          </div>}
      </>
    );
};

export default ReportingScreenshotExample;
