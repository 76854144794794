import moment from 'moment';
import React, {SetStateAction, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Campaign} from '../../../../model/campaign/Campaign';
import {IOffer} from '../../../../model/offer/IOffer';
import {Platforms} from '../../../../model/platform/Platforms';
import {Company} from '../../../../model/user/Company';
import {sortByPlatform} from '../../../../shared/functions/Functions';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {ManageService} from '../../../brand/campaign/manage/service';
import {InfluencerReportingService} from '../service';
import AddReportingStep2Modal from './AddReportingStep2Modal';
import AddReportingModal from './modals/AddReportingModal';
import {IReportingUpdate, ISelectedCampaign} from './ReportingCampaignCard';

interface Props {
    offerId: number;
    isNewReporting: boolean;
    isAdditional: boolean;
    selectedCampaign: ISelectedCampaign;
    setSelectedCampaign: React.Dispatch<SetStateAction<ISelectedCampaign | undefined>>;
    setIsNewReporting: React.Dispatch<SetStateAction<boolean>>;
    setUpdateReports: React.Dispatch<SetStateAction<boolean>>;
    clearAdditional: () => void;
}

const initialState = {
    clicks: 0,
    campaignId: 0,
    comments: 0,
    likes: 0,
    offerItemId: 0,
    reach: 0,
    saves: 0,
    shares: 0,
    postingDate: new Date().toISOString(),
    stickerEngagements: 0,
    swipeUpLinks: 0,
    views: 0,
    watchTime: 0,
    externalLinks: []
};

export interface ITempData {
    company?: Company;
    campaign?: Campaign;
    offers?: IOffer[];
    title?: string;
}

const AddReportingView = ({
                              offerId,
                              isAdditional,
                              clearAdditional,
                              isNewReporting,
                              setIsNewReporting,
                              setUpdateReports,
                              selectedCampaign,
                              setSelectedCampaign
                          }: Props) => {
    const {t} = useTranslation();
    const [tempData, setTempData] = useState<ITempData>(); // temp data to have images, states etc.. in local state
    const [platformDropdown, setPlatformDropdown] = useState<Platforms[]>([]);
    const [errorFile, setErrorFile] = useState(false);
    const [createPosting, setCreatePosting] = useState<IReportingUpdate>(initialState);
    const [isStep2Opened, setIsStep2Opened] = useState(false);

    const clearStates = () => {
        setCreatePosting(initialState);
        setIsStep2Opened(false);
        setUpdateReports((prev: boolean) => !prev);
        SuccessToast(t('influencer.reporting.successScheduledReporting'));
        setSelectedCampaign(undefined);
        setTempData({});
    }
    const handleSubmitData = async () => {
        const formData = new FormData();
        if (!!createPosting?.file?.length) {
            for (const item of createPosting?.file) {
                formData.append('files', item as unknown as Blob);
            }
        } else {
            setErrorFile(true);
            return;
        }
        if (!!createPosting?.attachments?.length) {
            for (const item of createPosting?.attachments) {
                formData.append('attachments', (item as string | Blob));
            }
        }

        formData.append('body', JSON.stringify(createPosting));
        if (isAdditional) {
            await InfluencerReportingService.addAdditionalReporting(formData, offerId).then(response => {
                if (response === '') {
                    clearStates();
                }
            }).catch(error => ErrorToast(error));
        } else {
            await InfluencerReportingService.addSingleReporting(formData).then(response => {
                if (response === '') {
                    clearStates();
                }
            }).catch(error => ErrorToast(error));
        }
    };
    useEffect(() => {
        if (selectedCampaign?.campaign?.id) {
            setTempData((prev: any) => ({
                ...prev, campaign: selectedCampaign?.campaign,
            }));
            setCreatePosting(prev => ({
                ...prev,
                campaignId: selectedCampaign?.campaign?.id,
                postingDate: moment(selectedCampaign?.campaign?.publishFrom).add(1, 'day').toISOString()
            }));
        }
        // eslint-disable-next-line
    }, [selectedCampaign?.campaign?.id]);

    useEffect(() => {
        if (!isNewReporting && !isStep2Opened) {
            setCreatePosting(initialState);
            setSelectedCampaign(undefined);
        }
        // eslint-disable-next-line
    }, [isNewReporting]);

    useEffect(() => {
        if (!isStep2Opened && !isNewReporting) {
            setCreatePosting(initialState);
            setSelectedCampaign(undefined);
            setTempData({});
        }
        // eslint-disable-next-line
    }, [isStep2Opened]);

    useEffect(() => {
        if (isAdditional) {
            ManageService.getAdminPlatforms({page: 1, perPage: 15, isEnabled: true})
                .then((response) => {
                    setPlatformDropdown(sortByPlatform(response.data.map((platform: Platforms) => ({
                        ...platform,
                        name: t(`general.platforms.${platform?.name}`)
                    }))))
                })
                .catch((error) => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [isAdditional])

    useEffect(() => {
        if (isAdditional && createPosting?.socialPlatformId) {
            const socialPlatform = platformDropdown?.find(platform => platform.id === createPosting?.socialPlatformId);

            setTempData(prev => ({
                ...prev,
                offerItem: {
                    socialPlatform: {
                        ...socialPlatform,
                        name: socialPlatform?.mobileIdentify
                    }
                }
            }))
        }
        // eslint-disable-next-line
    }, [isAdditional, createPosting?.socialPlatformId]);
    return (
        <>
            <AddReportingModal createPosting={createPosting as IReportingUpdate} selectedCampaign={selectedCampaign}
                               clearAdditional={clearAdditional}
                               setTempData={setTempData as React.Dispatch<SetStateAction<ITempData>>}
                               isAdditional={isAdditional} platformDropdown={platformDropdown}
                               setIsNewReporting={setIsNewReporting} setIsStep2Opened={setIsStep2Opened}
                               setCreatePosting={setCreatePosting as React.Dispatch<SetStateAction<IReportingUpdate>>}
                               isModalOpened={isNewReporting}/>

            <AddReportingStep2Modal createPosting={createPosting} handleCloseModal={() => setIsStep2Opened(false)}
                                    isAdditional={isAdditional}
                                    errorFile={errorFile}
                                    setCreatePosting={setCreatePosting as React.Dispatch<SetStateAction<IReportingUpdate>>}
                                    isModalOpened={isStep2Opened} tempData={tempData as ITempData}
                                    handleSubmitData={handleSubmitData}/>
        </>
    );
};

export default AddReportingView;
