import React, {SetStateAction, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {BsThreeDotsVertical} from 'react-icons/bs';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {AuthService} from '../../../authentication/service';
import {UserRoles} from '../../../enum/UserRoles';
import {ENotificationType, IUserNotification} from '../../../model/campaign/Campaign';
import {actions} from '../../../store/authentication/authRedux';
import {selectedCampaignSlice} from '../../../store/brand/activeCampaignSlice';
import {redDotsSlice} from '../../../store/influencer/redDotsSlice';
import {IAllStates} from '../../../store/rootReducer';
import {ErrorToast, WarningToast} from '../../../utils/toasters';
import {IconBook, IconMeasure} from '../../Icons';

interface IProps {
    notification: IUserNotification;
    setFilterParams: React.Dispatch<SetStateAction<any>>;
    setIsNotificationsOpened?: React.Dispatch<SetStateAction<boolean>>;
    isHeader?: boolean;
    setNotificationList?: (...args: any) => void;
}

const ITEM_HEIGHT = 48;

const getCorrectNotificationIcon = (notificationKey: ENotificationType) => {
    if ([ENotificationType.OFFER, ENotificationType.OFFER_COMMENT].includes(notificationKey)) {
        return <IconBook color={'#9ea5ab'} width={'25'} height={'25'}/>
    }
    // if (notificationKey === 'planner') {
    //     return <IconManage color={'#9ea5ab'} width={'25'} height={'25'}/>
    // }
    return <IconMeasure color={'#9ea5ab'} width={'25'} height={'25'}/>
}
const NotificationItem = ({notification, setFilterParams, setIsNotificationsOpened, isHeader = false, setNotificationList}: IProps) => {
    const {activeCampaignId} = useSelector((state: IAllStates) => state.selectedCampaign, shallowEqual);
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    const {redDots} = useSelector((state: IAllStates) => state.redDots);

    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    const isSeen = notification?.isViewed;
    const open = Boolean(anchorEl);
    const isInfluencer = userType === UserRoles.INFLUENCER;
    const isAgency = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickNotification = async (notificationItem: IUserNotification) => {

        await AuthService.markNotification(notificationItem?.id)
            .then(response => {
                if (response) {
                    if (!notificationItem?.isViewed) { // reduce bell counter if it's not read
                        dispatch(redDotsSlice.actions.setRedDots({
                            ...redDots,
                            notifications: Number(redDots?.notifications) - 1
                        }))
                    }
                    setNotificationList?.((prev: any) => 
                        prev?.map((item: any) => {
                            return item.id === notificationItem?.id ?  {...item, isViewed: !item?.isViewed }: item
                        })
                    )
                    // setFilterParams((prev: any) => ({...prev, updatedAt: new Date()?.getTime()}))
                }
            }).catch(err => ErrorToast(err));
    }
    const handleClose = (currNotification: IUserNotification, option?: string) => {
        return async () => {
            if (option === 'markAsRead' || option === 'markAsUnread') {
                await handleClickNotification(currNotification);
            } else if (option === 'open') {
                await onNotificationClick(currNotification);
            }
            setAnchorEl(null);
        };
    };

    const onNotificationClick = async (currNotification: IUserNotification) => {
        const {isViewed, id, param, campaignId, offerId, type} = currNotification;
        const paramId = Number(param);
        const campaign = Number(campaignId);
        const offer = Number(offerId);

        // Mark as read if unread
        if (!isViewed) {
            await AuthService.markNotificationAsRead(id);
            dispatch(redDotsSlice.actions.setRedDots({
                ...redDots,
                notifications: Number(redDots?.notifications) - 1,
            }));
        }

        const navigateTo = (path: string) => history.push(path);

        const routes = {
            OFFER: isInfluencer
                ? `/influencer/offers?campaignId=${campaign}&notificationType=${type}`
                : isAgency
                    ? `/agency/offers?campaignId=${campaign}&userId=${paramId}&notificationType=${type}`
                    : `/brand/campaign/book/${campaign}?userId=${paramId}&offerId=${offer}&notificationType=${type}`,

            PLANNER: isInfluencer
                ? `/influencer/planner?eventId=${paramId}&notificationType=${type}`
                : isAgency
                    ? `/agency/planner?eventId=${paramId}&notificationType=${type}`
                    : `/brand/campaign/manage/${campaign}?eventId=${paramId}&notificationType=${type}`,

            OFFER_COMMENT: isInfluencer
                ? `/influencer/offers?campaignId=${campaign}&notificationType=${type}`
                : isAgency
                    ? `/agency/offers?campaignId=${campaign}&userId=${paramId}&notificationType=${type}`
                    : `/brand/campaign/book/${campaign}?userId=${paramId}&offerId=${offer}&notificationType=${type}`,

            CONTRACT_COMMENT: isInfluencer
                ? `/influencer/offers?campaignId=${campaign}&notificationType=${type}`
                : isAgency
                    ? `/agency/offers?campaignId=${campaign}&userId=${paramId}&notificationType=${type}`
                    : `/brand/campaign/contracts/${campaign}?userId=${paramId}&offerId=${offer}&notificationType=${type}`,

            CONTRACT: isInfluencer
              ? `/influencer/offers?campaignId=${campaign}&userId=${paramId}&notificationType=${type}`
              : isAgency
                ? `/agency/offers?campaignId=${campaign}&userId=${paramId}&notificationType=${type}`
                : `/brand/campaign/contracts/${campaign}?userId=${paramId}&offerId=${offer}&notificationType=${type}`,
        };
        const getCampaignData = async (campaignId: number) => {
            const token = localStorage.getItem('token') as string;
            await AuthService.getPersonalInfo({campaignId, token: JSON.parse(token) ?? ''})
                .then((data) => {
                    dispatch(actions.setUser(data));
                    // setShowSuspendedModal(data?.isSuspended ?? false);
                }).catch(err => ErrorToast(err));
        }
        // Navigate based on notification type
        switch (type) {
            case ENotificationType.OFFER:
                if (campaign) navigateTo(routes.OFFER);
                break;
            case ENotificationType.OFFER_LONG:
                if (campaign) navigateTo(routes.OFFER);
                break;
            case ENotificationType.PLANNER:
                if (paramId) {
                    await getCampaignData(campaign);
                    navigateTo(routes.PLANNER);
                }
                break;
            case ENotificationType.OFFER_COMMENT:
                if (campaign) navigateTo(routes.OFFER_COMMENT);
                break;
            case ENotificationType.CONTRACT_COMMENT:
                if (campaign) {
                    await getCampaignData(campaign);
                    dispatch(selectedCampaignSlice.actions.setActiveCampaignId(String(campaign)));
                    sessionStorage.setItem('activeCampaignId', String(campaign));
                    setTimeout(() => navigateTo(routes.CONTRACT_COMMENT), !!activeCampaignId ? 500 : 0);
                }
                break;
            case ENotificationType.CONTRACT:
                if (campaign) {
                    await getCampaignData(campaign);
                    dispatch(selectedCampaignSlice.actions.setActiveCampaignId(String(campaign)));
                    sessionStorage.setItem('activeCampaignId', String(campaign));
                    setTimeout(() => navigateTo(routes.CONTRACT), !!activeCampaignId ? 500 : 0);
                }
                break;
            default:
                WarningToast(`No notification case for ${type}!`);
                break;
        }
        // Close notifications panel
        if (setIsNotificationsOpened) setIsNotificationsOpened(false);
    };

    return (
        <div className={`position-relative mb-1 item ${!isSeen ? 'unseen rounded' : ''}`}
             onClick={() => onNotificationClick(notification)}>
            {!isSeen && <div className="red-dot position-absolute z-index-1"/>}
            <div className="d-flex align-items-start">
                <div className="p-2 bg-white w-fit-content rounded-circle box-shadow aspect-ratio-1 notification-icon">
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        {getCorrectNotificationIcon(notification?.type)}
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between w-100 ml-2">
                    <div>
                        <h6 className={'mb-0 white-space-break-spaces'}>
                            {t(`notification.${notification?.translateKey}`)}
                        </h6>
                        <div className="text-muted white-space-break-spaces text-break">
                            {t(`notification.${isHeader ? notification.translateBody : notification?.translateBodyLong}`, {
                                campaignTitle: notification?.senderCampaign?.title,
                                senderName: notification?.sender ? (notification?.sender?.displayName || `${notification?.sender?.firstName} ${notification?.sender?.lastName}`) : null
                            })}
                            {!isHeader && <span className={'ml-1'}>
                                - {moment(notification?.createdAt)?.fromNow()}
                            </span>}
                        </div>
                    </div>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                            e.stopPropagation(); // Prevents onNotificationClick from being triggered
                            handleClick(e);
                        }}
                    >

                        <BsThreeDotsVertical className="font-16"/>
                    </IconButton>

                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose(notification)}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: 150,
                            },
                        }}
                    >
                        {[!isSeen ? 'markAsRead' : 'markAsUnread', 'open'].map((option) => {
                            return (
                                <MenuItem
                                    key={option}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevents onNotificationClick from being triggered
                                        handleClose(notification, option)();
                                    }}
                                >
                                    <span>{t(`notification.${option}`)}</span>
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </div>
            </div>
        </div>
    );
};


export default NotificationItem;
