import React from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {PlannerStatusEnum} from '../../../../enum/PlannerStatusEnum';
import {ISelectedEvent} from '../../../influencer/reporting/components/ReportingCampaignCard';

interface IProps {
    statusesToDisplayButton: PlannerStatusEnum[];
    isFeedbackLoopDisabled: boolean;
    handleCloseModal: () => void;
    selectedOfferItem: ISelectedEvent
}

const ReportingFooterButtons = ({
                                    statusesToDisplayButton,
                                    handleCloseModal,
                                    selectedOfferItem,
                                    isFeedbackLoopDisabled,
                                }: IProps) => {
    const {t} = useTranslation();
    return (
        <>
            <Button variant="outline-primary" onClick={handleCloseModal}>
                {t('general.button.cancel')}
            </Button>
            {(statusesToDisplayButton?.includes(selectedOfferItem?.event?.status as PlannerStatusEnum) || !isFeedbackLoopDisabled) &&
                <Button variant="primary" type="submit" className={'ml-2'}>
                    {t(`general.button.${selectedOfferItem?.event?.status === PlannerStatusEnum.REPORTED ? 'updateReport' : 'report'}`)}
                </Button>}
        </>
    );
};

export default ReportingFooterButtons;
