import {Col, Row} from 'react-bootstrap';
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import {getSharingTypeIcon} from '../../../../../shared/functions/Functions';
import {CampaignSharingType} from '../../../../../enum/CampaignSharingTypeEnum';
import {CustomAutocomplete} from '../../../../../shared/components/Autocomplete';
import React, {useState} from 'react';
import {ICreateCampaign, ICreateCampaignRequest} from '../dto/ICreateCampaign';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {PackagesEnum} from '../../../../../enum/PackageEnum';
import {useTranslation} from 'react-i18next';
import {IClientParams} from '../../../client/service';
import {Company} from '../../../../../model/user/Company';
import {UserRoles} from '../../../../../enum/UserRoles';
import {ILongTermCampaign, ILongTermFilter} from '../dto/ILongTermCamaign';

interface ICampaignCreateStepTwoProps {
    campaignData?: ICreateCampaign;
    createCampaign: ICreateCampaignRequest;
    setCreateCampaign: React.Dispatch<React.SetStateAction<ICreateCampaignRequest>>;
    isSubmitted: boolean;
    setFilterParams: React.Dispatch<React.SetStateAction<IClientParams>>;
    setLongTermFilterParams: React.Dispatch<React.SetStateAction<ILongTermFilter>>;
    companies: Company[];
    longTermCampaigns: ILongTermCampaign[];
    selectedLongCampaign?: ILongTermCampaign;
    setSelectedLongCampaign?: React.Dispatch<React.SetStateAction<ILongTermCampaign | undefined>>;
}

export function CampaignCreateStepTwo({
                                          campaignData,
                                          setCreateCampaign,
                                          createCampaign,
                                          isSubmitted,
                                          setFilterParams,
                                          companies,
                                          longTermCampaigns,
                                          setLongTermFilterParams,
                                          selectedLongCampaign,
                                          setSelectedLongCampaign,
                                      }: ICampaignCreateStepTwoProps) {
    const isManagementHidden = !campaignData?.hasSuppliers && !campaignData?.hasClients;
    const {CampaignSharingTypesEnum} = useSelector((state: IAllStates) => state.enums);
    const [selectedCompany, setSelectedCompany] = useState<Company>();
    const {user: {company}} = useSelector((state: IAllStates) => state.auth);
    const canDoLongTerm = company?.package?.canDoLongTerm;
    const {t} = useTranslation();
    const isEdit = !!campaignData?.info;
    const isBasic = company?.package?.name === PackagesEnum.BASIC;
    const contractingType = ['standard', 'long_term'];

    const functionCheckShareTypeVisibility = (type: string) => {
        if (type === CampaignSharingType.SUPPLIER && campaignData?.hasSuppliers && !isBasic && (!!selectedLongCampaign ? !!selectedLongCampaign?.shared?.length : true)) {
            return false;
        } else if (type === CampaignSharingType.CLIENT && campaignData?.hasClients && !isBasic && (!!selectedLongCampaign ? !!selectedLongCampaign?.shared?.length : true)) {
            return false;
        } else if (type === CampaignSharingType.OWN_MANAGEMENT) {
            return false;
        }
        return true;
    };
    const functionCheckContractingType = (type: string) => {
        if (type === 'long_term' && !canDoLongTerm) {
            return true;
        } else if (type === 'long_term' && isEdit) {
            return true;
        }
        return false;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = event.target;
        if (name === 'isLongTerm') {
            if (setSelectedLongCampaign) {
                setSelectedLongCampaign(undefined);
            }
            setCreateCampaign(prevState => ({
                ...prevState,
                [name]: Boolean(JSON.parse(value)),
                parentId: undefined,
            }));
            setFilterParams(prevState => ({...prevState, parentId: undefined}));
        } else {
            setCreateCampaign(prevState => ({
                ...prevState,
                [name]: value,
                partner: undefined,
            }));
        }
        if (value === CampaignSharingType.CLIENT || value === CampaignSharingType.SUPPLIER) {
            setFilterParams(prevState => ({...prevState, type: value === 'client' ? 'supplier' : 'client'}));
        }
    };
    const changeHandler = (paramKey: string, paramValue: number | string | string[]) => {
        if (paramKey === 'campaignTitle') {
            const selectedLong = longTermCampaigns.find(camp => camp.id === +paramValue);
            if (setSelectedLongCampaign) {
                setSelectedLongCampaign(selectedLong);
            }
            setSelectedCompany(undefined);
            setCreateCampaign(prevState => ({
                ...prevState,
                parentId: +paramValue,
                campaignSharingType: !selectedLong?.shared.length ? CampaignSharingType.OWN_MANAGEMENT : prevState.campaignSharingType,
                // partner: !selectedLong?.shared.length ? undefined : prevState.partner,
                partner: undefined,
            }));
            setFilterParams(prevState => ({...prevState, parentId: +paramValue}));
        } else {
            const selectedItem = companies?.find(company => company.id === Number(paramValue));
            setCreateCampaign({
                ...createCampaign,
                partner: selectedItem?.companyUsers?.find((item) => item.userType === UserRoles.BRAND_MASTER),
            });
            setSelectedCompany(selectedItem);
        }
    };

    return (
        <>
            {
                <Row>
                    <Col xs={12} className="px-0 px-md-1">
                        <h6>{t('brand.campaign.briefing.contracting_type')}</h6>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="isLongTerm"
                                onChange={handleChange}
                                value={createCampaign.isLongTerm}
                            >
                                {contractingType.map((type, index) => (
                                    <FormControlLabel value={type === 'long_term'}
                                                      hidden={functionCheckContractingType(type)}
                                                      key={type + index} control={<Radio/>}
                                                      label={
                                                          <div className="d-flex">
                                                              <h6 className="mb-0 pl-2">
                                                                  {t(`brand.campaign.briefing.${type}`)}
                                                              </h6>
                                                          </div>
                                                      }/>
                                ))
                                }
                            </RadioGroup>
                        </FormControl>
                        {(!createCampaign?.isLongTerm && canDoLongTerm) && <>
                            <h6>
                                {t('brand.campaign.briefing.belong_to_longterm')}
                            </h6>
                            <CustomAutocomplete dataArray={longTermCampaigns ?? []}
                                                isWithImage={false}
                                                inputLabel="title"
                                                isMultiple={false}
                                                classes="mb-3"
                                                queryFilterKey="campaignTitle"
                                                changeEventKey="campaignTitle"
                                                defaultArray={selectedLongCampaign as unknown as []}
                                                setFilterState={setLongTermFilterParams}
                                                inputLabelTranslation={t('brand.campaign.briefing.long_term_camp')}
                                                handleChangeParams={changeHandler}/>
                        </>
                        }
                    </Col>
                    {!isEdit &&
                        <Col xs={12} className={`px-0 px-md-1 ${isManagementHidden && 'd-none'}`}>
                            <div>
                                <h6>{t('brand.campaign.briefing.campaign_management')}</h6>
                                <p className="text-muted">{t('brand.campaign.briefing.campaign_management_desc')}</p>
                            </div>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="campaignSharingType"
                                    onChange={handleChange}
                                    value={createCampaign.campaignSharingType}
                                >
                                    {CampaignSharingTypesEnum?.map(shareType => (
                                        <FormControlLabel hidden={functionCheckShareTypeVisibility(shareType)}
                                                          value={shareType}
                                                          key={shareType} control={<Radio/>}
                                                          label={
                                                              <div className="d-flex">
                                                                  {getSharingTypeIcon(shareType)}
                                                                  <h6 className="mb-0 pl-2">
                                                                      {t(`brand.campaign.briefing.${shareType}`)}
                                                                  </h6>
                                                              </div>
                                                          }/>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                            {createCampaign.campaignSharingType !== CampaignSharingType.OWN_MANAGEMENT ?
                                <Row>
                                    <h6 className="mb-2">
                                        {
                                            createCampaign.campaignSharingType === CampaignSharingType.SUPPLIER ?
                                                t('brand.campaign.briefing.chose_agency_partner') :
                                                t('brand.campaign.briefing.chose_client')
                                        }
                                    </h6>
                                    <CustomAutocomplete dataArray={companies ?? []} isWithImage
                                                        inputLabel="displayName"
                                                        isMultiple={false}
                                                        key={`${createCampaign.campaignSharingType === CampaignSharingType.SUPPLIER ? 'client' : 'supplier'} + ${selectedLongCampaign?.id}`}
                                                        hasError={isSubmitted && !selectedCompany}
                                                        photoLabel="profilePhoto.path"
                                                        selectedStateImage={selectedCompany?.profilePhoto?.path as string}
                                                        queryFilterKey="displayName"
                                                        changeEventKey="displayName"
                                                        setFilterState={setFilterParams}
                                                        inputLabelTranslation={createCampaign.campaignSharingType === CampaignSharingType.SUPPLIER ? t('brand.campaign.dashboard.modal.agency_partner') : t('brand.campaign.dashboard.modal.your_clients')}
                                                        handleChangeParams={changeHandler}/>
                                    {createCampaign.campaignSharingType !== CampaignSharingType.CLIENT ? (
                                        <p className="mt-2">{t('brand.campaign.briefing.campaign_transfer_text')}</p>
                                    ) : (
                                        <p className="mt-2">{t('brand.campaign.briefing.campaign_share_text')}</p>
                                    )}
                                </Row> :
                                <Row>
                                    <p className="text-muted mb-3">
                                        {t('brand.campaign.briefing.campaign_launched_desc')}
                                    </p>
                                    <p className="text-muted mb-3">
                                        {t('brand.campaign.briefing.campaign_transfer_desc')}
                                    </p>
                                </Row>
                            }
                        </Col>
                    }
                </Row>
            }
        </>
    );
}
