import 'bootstrap-icons/font/bootstrap-icons.css';
import React, {SetStateAction, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Campaign} from '../../../../../model/campaign/Campaign';
import {BaseModel} from '../../../../../model/shared/BaseModel';
import {CustomCalendar} from '../../../../../shared/components/calendar/CustomCalendar';
import {useSearchQuery} from '../../../../../shared/hooks/useSearchQuery';
import {ErrorToast, SuccessToast,} from '../../../../../utils/toasters';
import {CampaignService} from '../../service';
import {ManageService} from '../service';
import BrandProposalModal from './components/BrandProposalModal';
import {CalendarHeaderFilters} from './components/CalendarHeaderFilters';
import {CalendarListView} from './components/CalendarListView';
import {CustomCalendarStyle} from './components/CustomCalendarStyle';
import {CalendarCreateModal} from './components/modal/CalendarCreateModal';
import {CalendarUpdateModal} from './components/modal/CalendarUpdateModal';
import {iManageCalendar, iManageList} from './dto/IManageCalendar';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {manageBrand} from '../../../../../store/brand/manageBrandSlice';
import CardLayout from '../../../../../shared/components/card/CardLayout';
import {useMediaQuery} from '@mui/material';
import {BootstrapBreakpoints} from '../../../../../enum/BootstrapBreakpoints';

export const calendarViews = [
    {
        id: 1,
        view: 'dayGridMonth',
        icon: 'monthView',
    },
    // {
    //     id: 2,
    //     view: 'dayGridWeek',
    //     icon: 'weekView',
    // },
    {
        id: 3,
        view: 'listWeek',
        icon: 'listView',
    },
];

export const ManageCalendar = ({
                                   campaignId,
                                   filterParams,
                                   setFilterParams,
                                   showModal,
                                   setShowModal,
                                   newEvent,
                                   setNewEvent,
                                   setTriggerUpdate,
                                   triggerUpdate,
                                   handleChangeParams,
                                   setFilterDropdownInfluencerList,
                                   influencerDropdownList,
                               }: iManageCalendar) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {previewEventModal} = useSelector((state: IAllStates) => state.manageBrand);
    const eventId = useSearchQuery('eventId');
    const {t} = useTranslation();
    const [campaign, setCampaign] = useState<Campaign>();
    const [allManages, setAllManages] = useState<iManageList[]>([]);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(0);
    const [isProposalOpened, setIsProposalOpened] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState();
    const isMobile = useMediaQuery(`(max-width: ${BootstrapBreakpoints.SM}px)`);
    const [selectedCalendarView, setSelectedCalendarView] = useState<'dayGridMonth' | 'dayGridWeek' | 'listWeek'>(isMobile ? 'listWeek' : 'dayGridMonth');

    const closeModal = () => {
        setShowModal(false);
        setSelectedEventId(0);
        // @ts-ignore
        setNewEvent({});
    };

    const createEvent = () => {
        ManageService.createManageEvent(campaignId, newEvent).then(res => {
            setAllManages(res.data);
            SuccessToast(t('general.toasts.successCreateEvent'));
            setTriggerUpdate(!triggerUpdate);
            setShowModal(false);
            setNewEvent({});
        }).catch(error => ErrorToast(error));
    };

    const changeBtnFormat = (selectedValue: string) => {
        setSelectedCalendarView(
            selectedValue as 'dayGridMonth' | 'dayGridWeek' | 'listWeek',
        );
    };

    const approvePosting = () => {
        ManageService.approveEvent(selectedEventId).then(response => {
            setSelectedEvent(response);
            setSelectedEventId(0);
            setTriggerUpdate(!triggerUpdate);
            SuccessToast(t('general.toasts.successfullyApproved'));
        }).catch(error => ErrorToast(error));
    };

    useEffect(() => {
        if (!!eventId && !!campaignId) {
            setSelectedEventId(Number(eventId));
            setShowUpdateModal(true);
            history.push(`/brand/campaign/manage/${campaignId}`)
        }
    }, [eventId, campaignId])

    useEffect(() => {
        ManageService.getManages(campaignId, {
            ...filterParams,
            perPage: selectedCalendarView !== 'listWeek' ? 0 : filterParams?.perPage
        })
            .then((response: BaseModel<iManageList[]>) => {
                setAllManages(response?.data);

                if (selectedCalendarView === 'listWeek') {
                    setFilterParams((prev) => ({...prev, totalPages: Math.ceil(response.count / response.perPage)}));
                }
            })
            .catch((error) => ErrorToast(error));
        // eslint-disable-next-line
    }, [JSON.stringify(filterParams), triggerUpdate, showUpdateModal, selectedCalendarView]);

    useEffect(() => {
        if (!!selectedEventId) {
            ManageService.getEventById(selectedEventId).then(response => {
                setSelectedEvent(response);
            }).catch(error => ErrorToast(error));
        }
    }, [selectedEventId]);

    useEffect(() => {
        CampaignService.getCampaign(Number(campaignId))
            .then((data) => {
                setCampaign(data);
            })
            .catch((error) => ErrorToast(error));
    }, [campaignId]);

    useEffect(() => {
        if (!!previewEventModal.showPreviewEventModal) {
            setShowUpdateModal(previewEventModal.showPreviewEventModal);
            setSelectedEventId(previewEventModal?.eventId);
        }
    }, [previewEventModal])

    return (
        <>
            <BrandProposalModal setState={setSelectedEvent as unknown as React.Dispatch<SetStateAction<iManageList>>}
                                state={selectedEvent as unknown as iManageList} isModalOpened={isProposalOpened}
                                setTriggerUpdate={setTriggerUpdate}
                                handleCloseModal={() => setIsProposalOpened(false)}/>
            <CalendarUpdateModal
                t={t}
                show={showUpdateModal}
                setState={setSelectedEvent}
                closeModal={() => {
                    setShowUpdateModal(false);
                    dispatch(manageBrand.actions.setOpenPreviewModal({
                        eventId: 0,
                        showPreviewEventModal: false,
                    }));
                }}
                setSelectedEventId={setSelectedEventId}
                onSend={approvePosting}
                state={selectedEvent}
            />
            <CalendarCreateModal
                campaignId={campaignId}
                triggerUpdate={triggerUpdate}
                show={showModal}
                state={newEvent}
                setState={setNewEvent}
                closeModal={closeModal}
                campaign={campaign}
                onSend={createEvent}
                t={t}
            />
            <CardLayout>
                <div className="position-relative">
                    <CalendarHeaderFilters
                                            calendarViews={calendarViews}
                                            changeBtnFormat={changeBtnFormat}
                                            selectedCalendarView={selectedCalendarView}
                                            filterParams={filterParams}
                                            setFilterInfluencers={setFilterDropdownInfluencerList}
                                            handleChangeParams={handleChangeParams}
                                            influencers={influencerDropdownList}
                    />

                    <CustomCalendarStyle>
                        {selectedCalendarView !== 'listWeek' ? (
                            <CustomCalendar
                                campaign={campaign}
                                setIsProposalOpened={setIsProposalOpened}
                                allManages={allManages}
                                setShowUpdateModal={setShowUpdateModal}
                                setSelectedEventId={setSelectedEventId}
                                setShowModal={setShowModal}
                                selectedCalendarView={selectedCalendarView}
                                setNewEvent={setNewEvent}/>
                        ) : (
                            <CalendarListView
                                listOfInfluencers={allManages}
                                setIsProposalOpened={setIsProposalOpened}
                                setSelectedEventId={setSelectedEventId}
                                setShowUpdateModal={setShowUpdateModal}
                                filterParams={filterParams}
                                setFilterParams={setFilterParams}
                            />
                        )}
                    </CustomCalendarStyle>
                </div>
            </CardLayout>
        </>
    );
};
