import React, {SetStateAction} from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FiInfo} from 'react-icons/fi';
import {IconMeasure, MessagesIcon, PlannerIcon} from '../../../../../shared/Icons';
import {IMyCampaignsFilterProps} from '../../../campaign/dto/IMyCampaigns';
import {IMyOffers} from '../../dto/IDashboard';
import CardLayout from '../../../../../shared/components/card/CardLayout';

interface Props {
    myOffers: IMyOffers;
    setFilterParams: React.Dispatch<SetStateAction<IMyCampaignsFilterProps>>;
    filterParams: IMyCampaignsFilterProps;
}

const PlannerReports = ({myOffers, setFilterParams, filterParams}: Props) => {
    const {t} = useTranslation();
    const handleSelectFilter = (selected: string) => {
        if (filterParams?.dashboardFilter === selected) { //clear filter if it's active clicked
            setFilterParams(prev => ({...prev, dashboardFilter: ''}));
        } else {
            setFilterParams(prev => ({...prev, dashboardFilter: selected}));
        }
    }
    return (
        <CardLayout>
            <div className="d-flex align-items-start  h-100">
                <div className="border-right pl-0 col-4 d-flex flex-column justify-content-between h-100">
                    <h6 className="d-flex align-items-center text-muted">
                        <PlannerIcon width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                        <div>{t('influencer.dashboard.contentPlanner')}</div>
                    </h6>
                    <div className="mt-3 d-flex align-items-center my-3">
                        <div className="col-6 px-0">
                            <h4 className="text-primary mb-0">{myOffers?.plannerTodo ?? 0}</h4>
                            <div className="d-flex align-items-center">
                                To Do
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="impressions-tooltip">
                                                    {t(`influencer.dashboard.contentPlannerToDoTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="col-6 px-0">
                            <h4 className="text-primary mb-0">{myOffers?.plannerDone ?? 0}</h4>
                            <div className="d-flex align-items-center">
                                Done
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="impressions-tooltip">
                                                    {t(`influencer.dashboard.contentPlannerDoneTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <Button variant={`${filterParams?.dashboardFilter !== 'planner' ? 'outline-' : ''}primary`}
                                onClick={() => handleSelectFilter('planner')}>
                            {t(filterParams?.dashboardFilter === 'planner' ? 'general.button.active' : 'general.button.show')}
                        </Button>
                    </div>
                </div>
                <div className="border-right col-4 d-flex flex-column justify-content-between h-100">
                    <h6 className="text-muted d-flex align-items-center">
                        <IconMeasure width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                        <div>{t('influencer.dashboard.reporting')}</div>
                    </h6>
                    <div className="mt-3 d-flex align-items-center my-3">
                        <div className="col-6 px-0">
                            <h4 className="text-primary mb-0">{myOffers?.reportsTodo ?? 0}</h4>
                            <div className="d-flex align-items-center">
                                To Do
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="impressions-tooltip">
                                                    {t(`influencer.dashboard.reportingToDoTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="col-6 px-0">
                            <h4 className="text-primary mb-0">{myOffers?.reportsDone ?? 0}</h4>
                            <div className="d-flex align-items-center">
                                Done
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="impressions-tooltip">
                                                    {t(`influencer.dashboard.reportingDoneTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <Button
                            variant={`${filterParams?.dashboardFilter !== 'reporting' ? 'outline-' : ''}primary`}
                            onClick={() => handleSelectFilter('reporting')}>
                            {t(filterParams?.dashboardFilter === 'reporting' ? 'general.button.active' : 'general.button.show')}
                        </Button>
                    </div>
                </div>
                <div className="col-4 pr-0 d-flex flex-column justify-content-between h-100">
                    <h6 className="text-muted d-flex align-items-center">
                        <MessagesIcon width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                        <div>{t('influencer.campaign.tabs.messages')}</div>
                    </h6>
                    <div className="mt-3 d-flex align-items-center my-3">
                        <div>
                            <h4 className="text-primary mb-0">{myOffers?.messages ?? 0}</h4>
                            <span>
                                {t('influencer.dashboard.newMessages')}
                            </span>
                        </div>
                    </div>
                    <div className="text-end">
                        <Button variant={`${filterParams?.dashboardFilter !== 'messages' ? 'outline-' : ''}primary`}
                                onClick={() => handleSelectFilter('messages')}>
                            {t(filterParams?.dashboardFilter === 'messages' ? 'general.button.active' : 'general.button.show')}
                        </Button>
                    </div>
                </div>
            </div>
        </CardLayout>
    );
};

export default PlannerReports;
