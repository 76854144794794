import moment from 'moment/moment';
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {plannerInfluencer} from '../../../store/influencer/plannerSlice';
import {IAllStates} from '../../../store/rootReducer';
import {ErrorToast} from '../../../utils/toasters';
import PlannerCalendarCard from './components/PlannerCalendarCard';
import PlannerCard from './components/PlannerCard';
import {IPlannerData, IPlannerEventsQuery, IPlannerOffers} from './dto/IPlanner';
import {InfluencerPlannerService} from './service';

export interface ISelectedYearMonth {
    currentYear: string;
    currentMonth: string;
}

interface ILocation {
    campaignId: number;
    publishFrom?: string;
}

const InfluencerPlanner = () => {
    const dispatch = useDispatch();
    const {state} = useLocation();
    const history = useHistory();
    const {filterParams} = useSelector((state: IAllStates) => state.plannerInfluencer);
    const [offers, setOffers] = useState<IPlannerOffers>();
    const [selectedYearMonth, setSelectedYearMonth] = useState<ISelectedYearMonth>({
        currentYear: moment(new Date()).format('Y'),
        currentMonth: moment(new Date()).format('M'),
    });
    const [selectedCalendarView, setSelectedCalendarView] = useState<'dayGridMonth' | 'dayGridWeek' | 'listWeek'>('listWeek');

    useEffect(() => {
        InfluencerPlannerService.getPlannerOffers({
            ...filterParams,
            campaignId: (state as ILocation)?.campaignId ?? filterParams?.campaignId
        }).then(response => {
            setOffers(
                {   ...response,
                    data: response?.data,
                    info: response?.info,
                    totalPlanned: response?.totalPlanned,
                },
            );
            dispatch(plannerInfluencer.actions.setFilterParams({totalPages: Math.ceil(response?.count / filterParams?.perPage)}));
            dispatch(plannerInfluencer.actions.setCampaigns(response.data?.map((campaign: IPlannerData) => (
                ({...campaign.campaign, offers: [campaign], offerId: campaign?.id, key: campaign?.id})
            ))));
            if ((state as ILocation)?.campaignId) {
                dispatch(plannerInfluencer.actions.setFilterParams({campaignId: (state as ILocation)?.campaignId}));
                history.replace({});
                dispatch(plannerInfluencer.actions.setSelectedCampaign(response?.data?.find((campaign: IPlannerData) => campaign.campaign.id === (state as ILocation)?.campaignId).campaign))
            }
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [(filterParams as unknown as IPlannerEventsQuery)?.campaignId, filterParams?.page]);

    useEffect(() => {
        if ((state as ILocation)?.publishFrom) {
            setSelectedYearMonth({
                currentYear: moment((state as ILocation)?.publishFrom).format('Y'),
                currentMonth: moment((state as ILocation)?.publishFrom).format('M'),
            })
        }
    }, [(state as ILocation)?.publishFrom])

    useEffect(() => {
        InfluencerPlannerService.getEvents({
            ...filterParams,
            currentYear: selectedYearMonth?.currentYear,
            currentMonth: +selectedYearMonth?.currentMonth,
        } as any).then(response => {
            dispatch(plannerInfluencer.actions.setEvents(response.data));
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [filterParams, JSON.stringify(selectedYearMonth), selectedCalendarView]);

    return (
        <Row className="mb-5 pb-5 flex-column-reverse flex-md-row">
            <Col lg={8} xl={9}>
                <PlannerCalendarCard selectedYearMonth={selectedYearMonth}
                                     selectedCalendarView={selectedCalendarView}
                                     setSelectedCalendarView={setSelectedCalendarView}
                                     setSelectedYearMonth={setSelectedYearMonth}
                                     publishFrom={(state as ILocation)?.publishFrom}/>
            </Col>
            <Col lg={4} xl={3}>
                <PlannerCard offers={offers as IPlannerOffers}/>
            </Col>
        </Row>
    );
};

export default InfluencerPlanner;
