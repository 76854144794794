import React, {SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Pagination} from '../../../shared/components/Pagination';
import {IAllStates} from '../../../store/rootReducer';
import {IMyCampaignsFilterProps} from '../campaign/dto/IMyCampaigns';
import MyCampaigns from './components/mobile/MyCampaigns';
import MyOffers from './components/mobile/MyOffers';
import {IMyOffers} from './dto/IDashboard';
import {TermsOfServiceAlert} from '../../../shared/components/alert/TermsOfServiceAlert';
import {ContractList} from './components/desktop/ContractList';
import {ICampaignOverview} from '../../../model/briefing/overview/ICampaignOverview';
import {IOfferWithCampaign} from '../profile/profile-view';
import {InvoiceDetailAlert} from '../../../shared/components/alert/InvoiceDetailAlert';


interface Props {
    myOffers: IMyOffers;
    offers?: IOfferWithCampaign[];
    contractCampaigns?: ICampaignOverview[];
    filterParams: IMyCampaignsFilterProps;
    setFilterParams: React.Dispatch<SetStateAction<IMyCampaignsFilterProps>>;
    totalPages: number;
    handlePages: (updatePage: number) => void;
    isInvoiceModalOpen?: boolean;
    setIsInvoiceModalOpen?: React.Dispatch<SetStateAction<boolean>>;

}

const MobileDashboard = ({
                             contractCampaigns,
                             myOffers,
                             offers,
                             setFilterParams,
                             filterParams,
                             handlePages,
                             totalPages,
                             setIsInvoiceModalOpen,
                             isInvoiceModalOpen,
                         }: Props) => {
    const {t} = useTranslation();
    const {user} = useSelector((state: IAllStates) => state.auth);
    return (
        <div className="d-md-none mt-2">
            <h4>{t('influencer.header.welcome_back')} {user?.firstName}!</h4>
            <TermsOfServiceAlert/>
            <InvoiceDetailAlert setIsBillingModalOpened={setIsInvoiceModalOpen}
                                isBillingModalOpened={isInvoiceModalOpen}
            />
            <MyOffers myOffers={myOffers} filterParams={filterParams} setFilterParams={setFilterParams}/>
            {!!contractCampaigns?.length &&
                <ContractList campaigns={contractCampaigns}/>
            }
            <MyCampaigns hasContracts={!!contractCampaigns?.length} offers={offers}/>
            {totalPages <= 1 ? (
                ''
            ) : (
                <Pagination
                    page={filterParams.page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                />
            )}
        </div>
    );
};

export default MobileDashboard;
