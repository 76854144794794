import React from 'react';
import {useTranslation} from 'react-i18next';
import {ICampaignOverview} from '../../../../../model/briefing/overview/ICampaignOverview';
import {ContractCard} from './ContractCard';
import {Col, Row} from 'react-bootstrap';
import {UserRoles} from '../../../../../enum/UserRoles';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {useHistory} from 'react-router-dom';
import {useMediaQuery} from '@mui/material';
import {BootstrapBreakpoints} from '../../../../../enum/BootstrapBreakpoints';

interface IContractListProps {
    campaigns?: ICampaignOverview[];

}

export function ContractList({campaigns}: IContractListProps) {
    const {t} = useTranslation();
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    const history = useHistory();
    const isMobile = useMediaQuery(`(max-width: ${BootstrapBreakpoints.MD}px)`);
    const navigateToMyCampaigns = () => {
        [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles) ?
            history.push('/agency/campaigns') :
            history.push('/influencer/campaigns');
    };
    return (
        <>
            <div className="my-2">
                <div className="d-flex align-items-center justify-content-between">
                    <h4>{t('influencer.dashboard.long_term_contracts')}</h4>
                    {isMobile &&
                        <p onClick={navigateToMyCampaigns} className="text-uppercase text-muted text-underline mb-0">
                            {t('influencer.header.myCampaigns')}
                        </p>
                    }
                </div>
                <Row>
                    {campaigns?.map((campaign) => (
                        <Col
                            key={campaign?.id}
                            className="my-2 pl-0 col-12 col-md-6 col-lg-4"
                        >
                            <ContractCard campaign={campaign}/>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
}