import React, {SetStateAction, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BsExclamationOctagon} from 'react-icons/bs';
import {BiRefresh} from 'react-icons/bi';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import EmptyState from '../../../../shared/components/placeholder/EmptyState';
import {Pagination} from '../../../../shared/components/Pagination';
import {
    formatDate,
    getCorrectColor,
    getCorrectFilterTitle,
    truncateString
} from '../../../../shared/functions/Functions';
import usePagination from '../../../../shared/hooks/usePagination';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast} from '../../../../utils/toasters';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {IMyCampaignItem} from '../../campaign/dto/IMyCampaigns';
import {InfluencerPlannerService} from '../service';

interface Props {
    setIsCreateModalOpened: React.Dispatch<SetStateAction<boolean>>;
    setSelectedEvent: React.Dispatch<SetStateAction<iManageList | undefined>>;
    setIsProposalOpened: React.Dispatch<SetStateAction<boolean>>;
    setIsEditModalOpened: React.Dispatch<SetStateAction<boolean>>;
}

const MobileCalendar = ({
                            setIsCreateModalOpened,
                            setIsProposalOpened,
                            setSelectedEvent,
                            setIsEditModalOpened
                        }: Props) => {
    const {t} = useTranslation();
    const {filterParams: filterData, handlePages, totalPages, setTotalPages} = usePagination({});
    const {filterParams} = useSelector((state: IAllStates) => state.plannerInfluencer);
    const [plannerPlatforms, setPlannerPlatforms] = useState<IMyCampaignItem[]>([]);

    const handleOpenModal = async (eventId: string) => {
        const data = await InfluencerPlannerService.getPlannerModalData(eventId);
        setSelectedEvent(data);
        if (data.status === 'proposed') {
            setIsProposalOpened(true);
        } else {
            setIsEditModalOpened(true);
        }
    };
    useEffect(() => {
        InfluencerPlannerService.getPlannerPlatforms({
            ...filterData,
            ...filterParams,
            perPage: 3,
        }).then(response => {
            setPlannerPlatforms(response.data);
            setTotalPages(Math.ceil(response.count / response.perPage));
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [filterParams, filterData?.page])
    return (
        <div className="mt-2">
            <div className="d-flex align-items-center justify-content-between">
                <Button variant="primary" className="w-100" onClick={() => setIsCreateModalOpened(true)}>
                    {t('influencer.planner.addNewPosting')}
                </Button>
            </div>

            <div className="row">
                {!!plannerPlatforms?.length ? plannerPlatforms?.map((campaign, index) => (
                        <React.Fragment key={campaign?.id+index}>
                            <div className={`col-12 mt-${index !== 0 ? 4 : 2}`}>
                                <div className="bg-light rounded">
                                    <Link className="d-flex align-items-center cursor-pointer text-dark"
                                          to={`/influencer/campaign/${campaign?.id}/overview`}
                                    >
                                        <CustomImageHandler
                                            photoPath={campaign?.campaignImages?.[0]?.path as string}
                                            altTag="Campaign logo" classes="campaign-icon"/>
                                        <span className="font-weight-bold ml-1">
                                        {truncateString(campaign?.title, 40)}
                                    </span>
                                    </Link>
                                </div>
                            </div>
                            {campaign?.offers?.[0]?.offerItems?.map((offerItem) => {
                                const statusesWithIcon = ['proposed', 'feedback_loop'];
                                return (
                                    <React.Fragment key={offerItem?.id}>
                                        {offerItem?.offerItemEvents?.filter(event => !!event.id)?.map((event) => {
                                            return (
                                                <React.Fragment key={event?.id}>
                                                    <div onClick={() => handleOpenModal(String(event?.id))}
                                                         className="col-6 border-bottom d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <img src={offerItem?.socialPlatform?.path}
                                                                 alt={offerItem?.socialPlatform?.name}/>
                                                            <span
                                                                className="font-weight-semi-bold text-dark ml-1 word-break">
                                                            {t(`general.platforms.${offerItem?.socialPlatform?.name}`)}
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => handleOpenModal(String(event?.id))}
                                                         className="col-6 border-bottom d-flex justify-content-end">
                                                        <div className="d-flex align-items-center my-1 pl-3">
                                                            {!statusesWithIcon?.includes(event?.status) && <span
                                                                className={`d-flex align-items-center justify-content-center calendar-button-dot ${getCorrectColor(event?.status)}`}/>}

                                                            <div className="d-flex flex-column align-items-end">
                                                                {statusesWithIcon?.includes(event?.status) ?
                                                                    <div className="m-1 d-flex align-items-center">
                                                                        {event?.status === 'proposed' ?
                                                                            <BsExclamationOctagon
                                                                                className="proposed-icon"/> :
                                                                            <BiRefresh
                                                                                className="proposed-icon text-dark"/>}
                                                                        <span className="ml-1">
                                                                        {getCorrectFilterTitle(event?.status, t)}
                                                                    </span>
                                                                    </div>
                                                                    : <span
                                                                        className={`d-flex align-items-center justify-content-start`}>
                                                                    {getCorrectFilterTitle(event?.status, t)}
                                                                </span>
                                                                }
                                                                <span className="text-muted">
                                                                {formatDate(event?.date)}
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </React.Fragment>
                    )) :
                    <EmptyState btnText={t('brand.campaign.dashboard.manage.planner')} iconPath="/icons/clock.png"
                                desc={t('brand.campaign.dashboard.manage.empty_manage')}/>}
            </div>

            {totalPages > 1 && <div className="d-flex justify-content-end">
                <Pagination
                    page={filterData.page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                />
            </div>}
        </div>
    );
};

export default MobileCalendar;
