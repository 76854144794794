import {IInviteInfluencer} from '../../model/book/Invites';
import {IInfluencerDistribution} from '../../app/influencer/offer/dto/IInfluencerDistribution';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICampaignOverview} from '../../model/briefing/overview/ICampaignOverview';
import {IOfferItemsRequest} from '../../model/offer/IOfferItems';
import {User} from '../../model/user/User';
import {OfferStatusEnum} from '../../enum/OfferStatusEnum';
import { CampaignStatusEnum } from '../../enum/CampaignStatusEnum';

export interface IBrandOfferStore {
    activeTabModal: string;
    offerModalStatus: string;
    campaign?: ICampaignOverview;
    campaignId: number;
    userId: number;
    isEditable?: boolean;
    canCounter?: boolean;
    user?: User;
    initialOffer: IInviteInfluencer;
    additionalItemId: number;
    distributionArr: IInfluencerDistribution[];
    canWriteComment?: boolean;
    isCharityOrPrivate: boolean;
    isCharity: boolean;
    isTransparentPricing: boolean;
    hasUnreadComments: boolean;
    canToggleAddons: boolean;
}

const initialOfferState: IBrandOfferStore = {
    activeTabModal: '0',
    offerModalStatus: 'preview',
    campaign: undefined,
    user: undefined,
    distributionArr: [],
    additionalItemId: 1,
    userId: 0,
    campaignId: 0,
    isEditable: true,
    initialOffer: {
        description: '',
        personalOffer: '',
        price: 0,
        offerItems: [],
        distribution: [],
        isAddonsOnly: false,
    },
    canWriteComment: false,
    isCharityOrPrivate: false,
    isCharity: false,
    isTransparentPricing: false,
    hasUnreadComments: false,
    canToggleAddons: false,
};

export const offerBrand = createSlice({
    name: 'offerBrand',
    initialState: initialOfferState,
    reducers: {
        setInitialState: (state, action) => {
            return initialOfferState;
        },
        setActiveTab: (state, action) => {
            const activeTabModal = action.payload;
            if (activeTabModal === '4') {
                return {...state, activeTabModal, hasUnreadComments: false};
            }
            return {...state, activeTabModal};
        },
        setCampaignId: (state, action) => {
            const campaignId = action.payload;
            return {...state, campaignId};
        },
        setUserId: (state, action) => {
            const userId = action.payload;
            return {...state, userId};
        },

        setOfferModalDetails: (state, action) => {
            const campaign: ICampaignOverview = action.payload;
            const offerItems: IOfferItemsRequest[] = [];
            let additionalItems: IInfluencerDistribution[] = [];
            campaign.offerPlaceholder?.totalPlatforms?.map((platform => (
                platform.offerItems.map((item) => (
                    offerItems.push(
                        {
                            mainPlatformId: platform.id,
                            socialPlatformId: item.socialPlatform.id,
                            nbPosts: item.nbPosts ?? 0,
                            price: item.price,
                            oldPrice: item.oldPrice,
                        })
                ))
            )));
            if (campaign?.offerPlaceholder?.additionalOfferItems?.length) {
                campaign?.offerPlaceholder?.additionalOfferItems.map((item) => additionalItems.push(item));
            }
            if (!campaign.offers.length) {
                additionalItems = [...campaign?.offerPlaceholder?.distribution];
            }
            return {
                ...state,
                campaign: action.payload,
                distributionArr: campaign?.offerPlaceholder?.distribution,
                isEditable: campaign?.offerPlaceholder?.flags?.isEditable,
                canCounter: campaign?.offerPlaceholder?.flags?.canCounter,
                initialOffer: {
                    ...state.initialOffer,
                    offerItems,
                    distribution: additionalItems,
                    description: campaign?.offers[0]?.description ?? '',
                    personalOffer: campaign?.offers[0]?.personalOffer,
                    isAddonsOnly: campaign?.offers[0]?.isAddonsOnly,
                },
                canWriteComment: [OfferStatusEnum.NEGOTIATE, OfferStatusEnum.PENDING].includes(campaign?.offers[0]?.status),
                isCharityOrPrivate: (campaign?.status === CampaignStatusEnum.PRIVATE || campaign?.isCharity) ? true : false,
                isCharity: campaign?.isCharity,
                isTransparentPricing: campaign?.isTransparentPricing,
                hasUnreadComments: campaign?.offers[0]?.hasUnreadComments,
                canToggleAddons: campaign?.offerPlaceholder?.flags?.canToggleAddons,
            };
        },
        setOfferModalStatus: (state, action) => {
            const offerModalStatus = action.payload;
            return {...state, offerModalStatus};
        },
        setProfileInfo: (state, action) => {
            const user = action.payload;
            return {...state, user};
        },
        setIsAddonsOnly: (state: IBrandOfferStore, {payload: isAddonsOnly}: PayloadAction<boolean>) => {
            return {...state, initialOffer: {...state.initialOffer, isAddonsOnly}};
        },
        setOfferItems: (state, action) => {
            const offerItems = action.payload;
            return {...state, initialOffer: {...state.initialOffer, offerItems}};
        },
        addAdditionalItem: (state, action) => {
            const distributionItem = action.payload;
            const distributionArr: IInfluencerDistribution[] = [...state?.initialOffer?.distribution];
            distributionArr.push({...distributionItem});
            if (distributionItem?.type === 'additional') {
                return {
                    ...state,
                    additionalItemId: state.additionalItemId + 1,
                    initialOffer: {...state.initialOffer, distribution: distributionArr},
                };
            }
            return {...state, initialOffer: {...state.initialOffer, distribution: distributionArr}};
        },
        deleteAdditionItem: (state, action) => {
            const index = action.payload;
            const distributionArr = [...state?.initialOffer?.distribution];
            distributionArr.splice(index, 1);
            return {...state, initialOffer: {...state.initialOffer, distribution: distributionArr}};
        },
        setAdditionalItems: (state, action) => {
            const distribution = action.payload;
            return {...state, initialOffer: {...state.initialOffer, distribution}};
        },
        setDistributionItems: (state, action) => {
            const distributionArr = action.payload;
            return {...state, distributionArr};
        },
        setPersonalOfferDescription: (state, action) => {
            return {...state, initialOffer: {...state.initialOffer, personalOffer: action.payload}};
        },

    },
});

export const {
    setInitialState,
    setActiveTab,
    setOfferModalDetails,
    setOfferModalStatus,
    setCampaignId,
    setUserId,
    setOfferItems,
    addAdditionalItem,
    deleteAdditionItem,
    setAdditionalItems,
    setDistributionItems,
    setPersonalOfferDescription,
    setProfileInfo,
    setIsAddonsOnly,
} = offerBrand.actions;
