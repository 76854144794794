import React from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {MessagesIcon} from '../../../../../shared/Icons';
import {SupportWidget} from '../../../../../shared/SupportWidget';

const SupportDashboardCard = () => {
    const {t} = useTranslation()
    return (
        <Card className="h-100 d-flex flex-column justify-content-between box-shadow-primary border-dashed border-primary">
            <Card.Body>
                <h6 className="text-muted d-flex align-items-center">
                    <MessagesIcon width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                    <div>{t('influencer.dashboard.creatorSuccess')}</div>
                </h6>
                <div className="d-flex align-items-center my-3">
                    <img src="/tempAssets/Armin.png" className="user-img-rounded mr-2" alt="Support profile"/>
                    <div>
                        <h6 className="mb-0">Armin</h6>
                        <a href="mailto:support@influencevision.com" className="word-break font-11">
                            Contact
                        </a>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <span className="pr-2">
                        {t('influencer.dashboard.feelFreeToText')}
                    </span>
                    <div className="support-icon box-shadow">
                        <SupportWidget/>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default SupportDashboardCard;
