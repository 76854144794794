import {TextField} from '@material-ui/core';
import React, {SetStateAction} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FaCalendarAlt, FaPencilAlt} from 'react-icons/fa';
import {formatDate} from '../../../../../../shared/functions/Functions';
import {IReportingUpdate, ISelectedEvent} from '../../ReportingCampaignCard';
import ReportingScreenshotExample from './ReportingScreenshotExample';

interface IProps {
    updateData: IReportingUpdate;
    setUpdateData: React.Dispatch<SetStateAction<IReportingUpdate>>
    selectedOfferItem: ISelectedEvent;
}

const ReportingLeftCard = ({selectedOfferItem, updateData, setUpdateData}: IProps) => {
    const {t} = useTranslation();
    let {isVerified, isRejected, status} = selectedOfferItem?.event ?? {};
    const isAdditional = selectedOfferItem?.offerItem?.additionalPost;
    if (isAdditional) {
        isVerified = selectedOfferItem?.offerItem?.isVerified;
        isRejected = selectedOfferItem?.offerItem?.isRejected;
        status = 'reported';
    }
    return (
        <div className={'col-md-3'}>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <img src={selectedOfferItem?.offerItem?.socialPlatform?.path}
                                 alt={selectedOfferItem?.offerItem?.socialPlatform?.name}/>
                            <div className="d-flex flex-column ml-2">
                                <span>
                                     {isAdditional && 'Additional '}{t(`general.platforms.${selectedOfferItem?.offerItem?.socialPlatform?.name}`)} {(!isAdditional && selectedOfferItem?.offerItem?.nbPosts) ? ` ${selectedOfferItem?.event?.platformPosition ?? '-'}/${selectedOfferItem?.offerItem?.nbPosts}` :''}
                                </span>
                                <p className="mb-0 text-muted">
                                    {formatDate(updateData?.postingDate ? updateData?.postingDate : selectedOfferItem?.event?.postingDate)}
                                </p>
                            </div>
                        </div>
                        {status === 'reported' && <>
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="impressions-tooltip">
                                                {isVerified ? 'Approved' : isRejected ? 'Rejected' : 'Being verified'}
                                            </Tooltip>}>
                                {isVerified ?
                                    <img src="/icons/approved-icon.svg" className={'cursor-pointer'}
                                         alt="Approved icon"/> :
                                    isRejected ?
                                        <img src="/icons/not-approved-icon.svg" className={'cursor-pointer'}
                                             alt="Not approved icon"/> :
                                        <img src="/icons/being-verified-icon.svg" className={'cursor-pointer'}
                                             alt="Being verified icon"/>}
                            </OverlayTrigger>
                        </>}
                    </div>
                    {isRejected && !!selectedOfferItem?.event?.rejectReason?.length && <div className={'mt-3'}>
                        <h6 className="mb-0">{t('influencer.reporting.rejection_reason')}</h6>
                        <span className="text-muted">{selectedOfferItem?.event?.rejectReason}</span>
                    </div>}
                </div>
            </div>

            {!isAdditional && <TextField
                fullWidth
                name="postingLink"
                value={updateData?.postingLink ?? ''}
                onChange={(e) => {
                    setUpdateData(prev => ({...prev, postingLink: e.target.value}))
                }}
                label={t('influencer.reporting.postingLink')}
                type="text"
                className={'mb-3 mb-md-0'}
                variant="outlined"
                size="small"
            />}
        </div>
    );
};

export default ReportingLeftCard;
