import React, {SetStateAction} from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {User} from '../../../../model/user/User';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {ErrorToast} from '../../../../utils/toasters';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {InfluencerReportingService} from '../service';
import {ISelectedEvent} from './ReportingCampaignCard';
import CardLayout from '../../../../shared/components/card/CardLayout';
import {PlannerStatusEnum} from '../../../../enum/PlannerStatusEnum';

interface Props {
    user?: User;
    offerItem: iManageList;
    setIsReportOpened: React.Dispatch<SetStateAction<boolean>>;
    setIsAdditional: React.Dispatch<SetStateAction<boolean>>;
    setSelectedOfferItem: React.Dispatch<SetStateAction<ISelectedEvent | undefined>>
}

const getCorrectReportIcon = (isVerified?: boolean, isRejected?: boolean) => {
    if (isRejected) {
        return 'not-approved-icon.svg';
    } else if (isVerified) {
        return 'approved-icon.svg';
    } else {
        return 'being-verified-icon.svg';
    }
};

const ReportingAdditionalCard = ({
                                     user,
                                     offerItem,
                                     setIsReportOpened,
                                     setIsAdditional,
                                     setSelectedOfferItem,
                                 }: Props) => {
    const {t} = useTranslation();
    const hasPicture = !!offerItem?.attachments?.[0]?.path || !!offerItem?.attachments?.[0]?.thumbnail;
    const handleClickReporting = async (offerId: number) => {
        await InfluencerReportingService.getAdditionalReporting(offerId).then(response => {
            setIsAdditional(true);
            setIsReportOpened(true);
            setSelectedOfferItem({
                event: {
                    ...response,
                    user,
                    reportItems: [{
                        reportImages: response?.reportImages
                    }]
                },
                campaign: response?.offer?.campaign,
                offerItem: response
            })
        }).catch(error => ErrorToast(error));
    }

    return (
        <div className="reporting-card align-self-stretch mt-3 default-radius px-2">
            <CardLayout classBody="p-0">
                <div
                    className={`position-absolute mt-3 ml-3 z-index-1`}>
                    <p className="status additional my-1 my-md-0">
                        {t('brand.campaign.measure.additional')}
                    </p>
                </div>
                <>
                    <OverlayTrigger placement="top"
                                    overlay={<Tooltip
                                      id="impressions-tooltip">
                                        {offerItem?.isVerified ? t('brand.campaign.manage.approved') : offerItem?.isRejected ? t('general.campaign_status.rejected') : t('brand.campaign.measure.being_verified')}
                                    </Tooltip>}>
                        <img className="position-top-right cursor-pointer"
                             src={`/icons/${getCorrectReportIcon(offerItem?.isVerified, offerItem?.isRejected)}`}
                             alt="report-icon"/>
                    </OverlayTrigger>
                </>
                <div className="reporting-img-container">
                    <CustomImageHandler
                        classes={` ${hasPicture && 'w-100 h-100'}`}
                        onClick={() => handleClickReporting(offerItem?.id)}
                        thumbnailPath={offerItem?.attachments?.[0]?.thumbnail}
                        photoPath={offerItem?.attachments?.[0]?.thumbnail ? offerItem?.attachments?.[0].thumbPath as string
                            : offerItem?.attachments?.[0]?.path as string}
                        altTag="Campaign photo"
                        placeholder="/icons/placeholders/add.svg"
                    />
                    <p className={`mt-1 mb-0 ${hasPicture && 'position-absolute bottom-10 bg-white default-radius px-2 py-1'}`}>
                        {t('general.additionalPost')}
                    </p>

                </div>
                <div className="p-2 d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <img src={offerItem?.socialPlatform?.path}
                             alt="Platform icon"
                        />
                        <div className="ml-1">
                            <p className="mb-0">
                                {t(`general.platforms.${offerItem?.socialPlatform?.name}`)}
                            </p>
                        </div>
                    </div>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleClickReporting(offerItem?.id)}
                    >
                        {t(`general.button.open`)}
                    </Button>
                </div>
            </CardLayout>
        </div>
    );
};

export default ReportingAdditionalCard;
