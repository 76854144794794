import React from 'react'
import CardLayout from '../../../../../shared/components/card/CardLayout'
import { Invoice } from '../../../../../shared/Icons'
import { t } from 'i18next';
import { Link } from 'react-router-dom';

interface IInvoiceCard {
    myOffers: any;
    isAgency: boolean;
}

export default function InvoiceCard({myOffers, isAgency}: IInvoiceCard) {
  return (
    <CardLayout classCard='h-100'>
        <div className="pr-0 d-flex flex-column justify-content-between h-100">
            <h6 className="text-muted d-flex align-items-center">
                <Invoice width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                <div>{t('influencer.profile.invoices')}</div>
            </h6>
            <div className="mt-3 d-flex align-items-center my-3">
                <div>
                    <h4 className="text-primary mb-0">{myOffers?.availableInvoices ?? 0}</h4>
                    <span>
                        {t('influencer.dashboard.available_invoices')}
                    </span>
                </div>
            </div>
            <div className="text-end">
                <Link to={isAgency ? '/agency/invoices' : '/influencer/invoices'} className={`btn btn-outline-primary`}
                        >
                    {'To invoices'}
                </Link>
            </div>
        </div>
    </CardLayout>
  )
}
