import React from 'react';
import {useTranslation} from 'react-i18next';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import {truncateString} from '../../../../../../shared/functions/Functions';
import {IReportingUpdate, ISelectedEvent} from '../../ReportingCampaignCard';

interface IReportingHeader {
    selectedOfferItem: ISelectedEvent;
    updateData: IReportingUpdate;
}

const ReportingHeader = ({selectedOfferItem, updateData}: IReportingHeader) => {
    const {t} = useTranslation();
    const isLongTerm = selectedOfferItem?.offerItem?.socialPlatform?.isLongTerm;
    const isAddonsOnly = selectedOfferItem?.offerItem?.socialPlatform?.isAddonsOnly;   

    return (
        <div
            className="col-12 border-bottom mb-3 pb-2 d-flex align-items-center
                    justify-content-between flex-column flex-md-row">
            <div
                className="d-flex align-items-center justify-content-between w-100 flex-column flex-md-row pb-2 pb-md-0">
                <div className="d-flex align-items-center w-100">
                    <CustomImageHandler
                        altTag="Company logo"
                        classes="reporting-header-logo img-cover"
                        photoPath={selectedOfferItem?.campaign?.campaignImages?.[0]?.path as string}
                    />
                    <div className="ml-3">
                        <h6 className="mb-0 d-none d-md-block">
                            {selectedOfferItem?.campaign?.title ?? '-'}
                        </h6>
                        <h6 className="mb-0 d-md-none">
                            {truncateString(selectedOfferItem?.campaign?.title ?? '-', 40)}
                        </h6>
                        <div className="d-flex align-items-center mt-1">
                            {selectedOfferItem?.event?.status && <div className="d-flex align-items-center">
                                <div
                                    className={`status ${selectedOfferItem?.event?.status} w-fit-content`}>
                                    {t(`brand.campaign.manage.${selectedOfferItem?.event?.status}`)}
                                </div>
                            </div>}
                            {isLongTerm &&
                                <div className={'custom-badge long-term ml-1'}>
                                    {t(`brand.campaign.briefing.overview_card.linked`)}
                                </div>}
                            {isAddonsOnly &&
                                <div className={'custom-badge additional ml-1'}>
                                    {t('influencer.offer_management.modal.additional')}
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportingHeader;
