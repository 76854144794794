import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Form, Modal, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {PlannerStatusEnum} from '../../../../../enum/PlannerStatusEnum';
import {IPicture} from '../../../../../model/shared/IPicture';
import {ConfirmationModal} from '../../../../../shared/components/alert/ConfirmationModal';
import {ErrorToast, SuccessToast} from '../../../../../utils/toasters';
import {iManageAttachments} from '../../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {InfluencerPlannerService} from '../../../planner/service';
import {InfluencerReportingService} from '../../service';
import {IReportingUpdate, ISelectedEvent} from '../ReportingCampaignCard';
import ReportingBody, {reportingFields} from './components/ReportingBody';
import ReportingHeader from './components/ReportingHeader';
import ReportingLeftCard from './components/ReportingLeftCard';
import ReportingScreenshotExample from './components/ReportingScreenshotExample';
import ReportingScreenshots from './components/ReportingScreenshots';
import YourContent from './components/YourContent';


interface Props {
    isAdditional: boolean;
    isModalOpened: boolean;
    handleCloseModal: () => void;
    selectedOfferItem: ISelectedEvent;
    setSelectedOfferItem: React.Dispatch<SetStateAction<ISelectedEvent | undefined>>;
    setUpdateReports: React.Dispatch<SetStateAction<boolean>>;
}

const initUpdateState = {
    clicks: 0,
    campaignId: 0,
    comments: 0,
    eventId: 0,
    likes: 0,
    offerItemId: 0,
    reach: 0,
    saves: 0,
    shares: 0,
    postingDate: '',
    postingLink: '',
    stickerEngagements: 0,
    swipeUpLinks: 0,
    views: 0,
    watchTime: 0,
};

const AdditionalReportingModal = ({
                                      isModalOpened,
                                      handleCloseModal,
                                      selectedOfferItem,
                                      isAdditional,
                                      setSelectedOfferItem,
                                      setUpdateReports,
                                  }: Props) => {
    const {t} = useTranslation();
    const [updateData, setUpdateData] = useState<IReportingUpdate>(initUpdateState);
    const [selectedFile, setSelectedFile] = useState<IPicture>();
    const [errorFile, setErrorFile] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isAttachmentClicked, setIsAttachmentClicked] = useState(false);
    const isVerified = selectedOfferItem?.offerItem?.isVerified;
    const updateReportingAction = async () => {
        const formData = new FormData();
        formData.append('body', JSON.stringify(updateData));
        if (!!updateData?.file?.length) {
            for (const item of updateData?.file) {
                formData.append('file', (item as string | Blob));
            }
        } else {
            setErrorFile(true);
            return;
        }
        if (!!updateData?.attachments?.length) {
            for (const item of updateData?.attachments) {
                formData.append('attachments', (item as string | Blob));
            }
        }
        InfluencerReportingService.updateAdditionalReporting(formData, updateData?.offerItemId ?? 0).then(response => {
            handleCloseModal();
            setErrorFile(false);
            setUpdateReports(prev => !prev);
            setSelectedOfferItem(undefined);
            setUpdateData(initUpdateState);
            SuccessToast(t('influencer.reporting.successUpdatedReporting'));
        }).catch(error => ErrorToast(error));
    };

    const handleSubmitData = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!isVerified) {
            return updateReportingAction();
        }

        if (!updateData?.file?.length) {
            setErrorFile(true);
            return;
        }
        setShowConfirmModal(true);
    };

    const getReportItemValue = () => {
        const storage: any = {};
        reportingFields.forEach(reportingKey => {
            storage[reportingKey] = (selectedOfferItem?.event as any)?.[reportingKey];
        });
        return storage;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, isNumber = true) => {
        const {name, valueAsNumber, value} = event.target;
        setUpdateData((prev) => ({...prev, [name]: isNumber ? valueAsNumber : value}));
    };

    const deleteImageHandler = async () => {
        if (isAttachmentClicked) {
            await InfluencerPlannerService.deleteAttachment(selectedFile?.id ?? 0).then(response => {
                SuccessToast(t('influencer.reporting.successDeleteImg'));
                setIsAttachmentClicked(false);
                setUpdateReports(prev => !prev);
                setSelectedOfferItem((prev: any) => ({
                    ...prev,
                    event: {
                        ...prev?.event,
                        attachments: prev?.event?.attachments?.filter((attachment: iManageAttachments) => attachment.id !== selectedFile?.id),
                    },
                }));
            }).catch(error => ErrorToast(error));
        } else {
            await InfluencerReportingService.deleteReportingImage(selectedFile?.id ?? 0).then(response => {
                SuccessToast(t('influencer.reporting.successDeleteImg'));
                setUpdateReports(prev => !prev);
                setSelectedOfferItem((prev: any) => ({
                    ...prev,
                    event: {
                        ...prev?.event,
                        reportItems: [{reportImages: response?.reportImages}],
                    },
                }));
            }).catch(error => ErrorToast(error));
        }

    };
    useEffect(() => {
        if (!!selectedOfferItem?.event?.id && isAdditional) {
            setUpdateData(prev => ({
                ...prev,
                campaignId: selectedOfferItem?.campaign?.id,
                eventId: selectedOfferItem?.event?.id,
                offerItemId: selectedOfferItem?.offerItem?.id,
                postingDate: selectedOfferItem?.event?.postingDate,
                postingLink: selectedOfferItem?.event?.postingLink,
                ...getReportItemValue(),
            }));
        }
        // eslint-disable-next-line
    }, [selectedOfferItem?.event?.id, isAdditional]);
    return (
        <>
            <Modal
                show={isModalOpened}
                centered
                size="xl"
                onHide={handleCloseModal}
                keyboard={false}
            >
                <Form onSubmit={handleSubmitData}>
                    <Modal.Body>
                        <Row className={'overflow-auto max-h-60vh-sm'}>
                            <ReportingHeader updateData={updateData} selectedOfferItem={selectedOfferItem}/>
                            <ReportingLeftCard selectedOfferItem={selectedOfferItem} updateData={updateData}
                                               setUpdateData={setUpdateData}/>
                            <div className="col-md-9">
                                <ReportingScreenshotExample
                                    classes={'w-fit-content px-md-5'}
                                    platformItemId={selectedOfferItem?.offerItem?.socialPlatform?.id}
                                />
                                <ReportingScreenshots
                                    setSelectedFile={setSelectedFile as React.Dispatch<SetStateAction<IPicture>>}
                                    status={selectedOfferItem?.event?.status}
                                    isButtonDisabled={false}
                                    errorFile={errorFile}
                                    setUpdateData={setUpdateData}
                                    reportItems={selectedOfferItem?.event?.reportItems}/>
                                {selectedOfferItem?.offerItem?.isVerified &&
                                    <ReportingBody
                                        isButtonDisabled={false}
                                        socialPlatform={selectedOfferItem?.event?.socialPlatform}
                                        updateData={updateData} handleChange={handleChange}/>}
                                <YourContent selectedAttachments={selectedOfferItem?.event?.attachments}
                                             isFeedbackLoop={false}
                                             offerEvent={selectedOfferItem?.event}
                                             eventId={selectedOfferItem?.event?.id}
                                             isUploadPossible
                                             setSelectedOfferItem={setSelectedOfferItem}
                                             setUpdateData={setUpdateData}
                                             hideExternalSearch
                                             showUpload
                                             setIsAttachmentClicked={setIsAttachmentClicked}
                                             setSelectedFile={setSelectedFile as React.Dispatch<SetStateAction<IPicture>>}
                                             classes={'px-0'}
                                             isButtonDisabled={false}
                                />
                            </div>
                        </Row>
                        <div
                            className="border-top d-flex align-items-center justify-content-end d-md-none">
                            <div className="pt-3">
                                <Button variant="outline-primary" onClick={handleCloseModal} className={'mr-2'}>
                                    {t('general.button.cancel')}
                                </Button>
                                <Button variant="primary" type="submit">
                                    {t(`general.button.updateReport`)}
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={'d-none d-md-flex'}>
                        <Button variant="outline-primary" onClick={handleCloseModal}>
                            {t('general.button.cancel')}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t(`general.button.updateReport`)}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ConfirmationModal
                title={t('influencer.reporting.confirm_update_reporting')}
                description={t('influencer.reporting.confirm_update_reporting_desc')}
                show={showConfirmModal}
                closeModal={() => setShowConfirmModal(false)}
                action={updateReportingAction}
                iconUrl="/icons/placeholders/upload.svg"
            />
            <ConfirmationModal
                title={t('influencer.planner.deleteImage')}
                description={`${t('influencer.planner.deleteImageDesc')} ${selectedFile?.externalLink ?? selectedFile?.title ?? selectedFile?.originalName}`}
                action={deleteImageHandler}
                show={!!selectedFile?.id} closeModal={() => setSelectedFile(undefined)}
            />
        </>
    );
};

export default AdditionalReportingModal;
