import React, {SetStateAction, useState} from 'react';
import {Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BiErrorCircle} from 'react-icons/bi';
import {FiInfo} from 'react-icons/fi';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {plannerInfluencer} from '../../../../store/influencer/plannerSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {ISelectedYearMonth} from '../index';
import {InfluencerPlannerService} from '../service';
import LeftPlannerCard from './LeftPlannerCard';
import PreviewHeader from './preview/PreviewHeader';
import RightPlannerCard from './RightPlannerCard';

interface IPreviewEventModalProps {
    isModalOpened: boolean;
    handleCloseModal: () => void;
    selectedYearMonth: ISelectedYearMonth;
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
    setUpdateReports?: React.Dispatch<SetStateAction<boolean>>;
}

const PreviewEventModal = ({
                               isModalOpened,
                               handleCloseModal,
                               setUpdateReports,
                               state,
                               setState,
                               selectedYearMonth,
                           }: IPreviewEventModalProps) => {
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {events} = useSelector((reduxState: IAllStates) => reduxState.plannerInfluencer);
    const [hasTextInsideField, setHasTextInsideField] = useState(false);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState(prev => ({...prev, [name]: value}));
    };


    const publishEvent = async (isPublishAndReport = false) => {
        await InfluencerPlannerService.updatePostingLink({
            postingDate: state?.postingDate,
            postingLink: state?.postingLink,
            offerItemEventId: state?.id,
            offerItemId: state?.offerItem?.id,
        }).then(response => {
            const copy = [...events];
            const indexToUpdate = copy?.findIndex(event => event.id === state?.id);
            copy[indexToUpdate] = response[0];
            handleCloseModal();
            SuccessToast(t('influencer.planner.successUpdated'));
            dispatch(plannerInfluencer.actions.setEvents(copy));
            if (isPublishAndReport) {
                history.push('/influencer/reporting', {
                    offerId: response[0]?.id
                })
            }
        }).catch(error => ErrorToast(error));
    };


    const updateContent = async () => {
        await InfluencerPlannerService.updateCaption({
            eventId: state?.id,
            caption: state?.caption,
            storyline: state?.storyline,
        }).then(() => {
            handleCloseModal();
            setUpdateReports && setUpdateReports(prev => !prev);
            SuccessToast(t('influencer.planner.successUpdated'));
        }).catch(error => ErrorToast(error))
    }

    const navigateToReporting = () => {
        history.push('/influencer/reporting', {
            offerId: state?.id
        })
    }

    return (
        <Modal
            show={isModalOpened}
            centered
            onHide={handleCloseModal}
            size="xl"
            keyboard={false}
        >
            {state?.id &&
                <Modal.Body className={'overflow-auto max-h-60vh-sm'}>
                    <div className="row">
                        <PreviewHeader state={state} setState={setState}/>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <LeftPlannerCard state={state} setState={setState}/>
                        </div>
                        <div className="col-md-9 max-h-70vh-lg overflow-auto">
                            <RightPlannerCard state={state} setState={setState} changeHandler={changeHandler}
                                              setHasTextInsideField={setHasTextInsideField}/>
                        </div>
                    </div>
                </Modal.Body>
            }
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleCloseModal}>
                    {t('general.button.cancel')}
                </Button>
                {state?.status === 'approved' &&
                    <>
                        <Button variant="warning" onClick={() => publishEvent(true)} disabled={hasTextInsideField}>
                            Publish & Report
                        </Button>
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip
                                            id="info">
                                            {t('influencer.planner.info_tooltip')}
                                        </Tooltip>}>
                            <FiInfo size={14} className="cursor-pointer ml-1"/>
                        </OverlayTrigger>
                    </>
                }
                {['scheduled', 'feedback_loop'].includes(state?.status) &&
                    <Button variant="primary" onClick={updateContent}
                            disabled={(!state?.caption?.length && !state?.storyline?.length) || hasTextInsideField}>
                        {t('general.button.update')}
                    </Button>
                }
                {state?.status === 'reported' &&
                    <Button variant="success" onClick={navigateToReporting}
                            disabled={hasTextInsideField}>
                        {t('general.button.updateReport')}
                    </Button>
                }
                {hasTextInsideField &&
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="country-tooltip">
                                {t('general.deleteSend')}
                            </Tooltip>
                        }
                    >
                        <BiErrorCircle size={20} className="text-danger cursor-pointer"/>
                    </OverlayTrigger>
                }
            </Modal.Footer>
        </Modal>
    );
};

export default PreviewEventModal;
