import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TextField} from '@material-ui/core';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {UploadImage} from '../../../../shared/components/UploadImage';
import {IRegisterCompany} from '../dto/IRegister';
import {StepperButtons} from './StepperButtons';
import {IRegisterStepperProps} from './Step1';
import {multipartFormData} from '../../../../utils';
import {AUTH_BRAND_URL} from '../../../../utils/endpoints';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {useHistory} from 'react-router-dom';
import {Form} from 'react-bootstrap';

export const Step4 = ({stepIdNumber, handleBackStep}: IRegisterStepperProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [currentCompany, setCurrentCompany] = useState<IRegisterCompany>({
        contact: {
            country: '',
        },
        displayName: '',
        nbEmployees: '',
        industry: '',
        userId: 0,
    });
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    const {country, CompanyIndustryEnum, CompanyEmployeesEnum} = useSelector((states: IAllStates) => states.enums);
    const savedUser = useSelector((state: IAllStates) => state.registration);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setCurrentCompany((prev: IRegisterCompany) => ({...prev, [name]: value}));
    };

    const changeAutoCompleteHandler = (paramKey: string, paramValue: string | number | string[], type?: string) => {
        if (type === 'country') {
            setCurrentCompany((prev: any) => ({
                ...prev, contact: {
                    [type]: paramValue,
                },
            }));
        } else {
            setCurrentCompany((prev: any) => ({...prev, [type as string]: paramValue}));
        }
    };

    const handleNextStep = () => {
        multipartFormData(files, `${AUTH_BRAND_URL}/company-info`, JSON.stringify({
            ...currentCompany,
            userId: savedUser?.id,
        })).then(response => {
            history.push(`/brand/register/${stepIdNumber + 1}?token=${response?.data?.verificationUUID}`);
            SuccessToast(t('auth.register.brand.step4.success'));
        }).catch(error => ErrorToast(error));
    };

    useEffect(() => {
        if (savedUser?.id) {
            setCurrentCompany(savedUser?.brandCompany);
        }
        // eslint-disable-next-line
    }, [savedUser?.id]);

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            handleNextStep();
        }}>
            <div className="row justify-content-center">
                <div className="col-md-7">
                    <UploadImage saveFile={setFiles} acceptedFormats="image/jpeg, image/png, image/jpg"
                                 previewImage={currentCompany?.profilePhoto?.path}
                                 isPreviewArray={false}
                                 isMultiple={false}/>
                </div>

                <div className="col-md-6">
                    <TextField
                        label={t('auth.register.brand.step4.companyName')}
                        variant="outlined"
                        name="displayName"
                        size="small"
                        value={currentCompany?.displayName ?? ''}
                        onChange={changeHandler}
                        required
                        className="w-100 my-2"
                    />
                </div>
                <div className="col-md-6 my-2">
                    <CustomAutocomplete dataArray={CompanyIndustryEnum ?? []}
                                        defaultArray={currentCompany?.industry as string}
                                        isWithImage={false} inputLabel="industry"
                                        isMultiple={false}
                                        photoLabel=""
                                        isRequired={true}
                                        queryFilterKey=""
                                        inputLabelTranslation={t('auth.register.brand.step4.industry')}
                                        handleChangeParams={(_, e) => changeAutoCompleteHandler(_, e, 'industry')}/>
                </div>
                <div className="col-md-6 my-2">
                    <CustomAutocomplete dataArray={CompanyEmployeesEnum ?? []}
                                        defaultArray={currentCompany?.nbEmployees as string} isWithImage={false}
                                        inputLabel="nbEmployees"
                                        isMultiple={false}
                                        photoLabel=""
                                        isRequired={true}
                                        queryFilterKey=""
                                        inputLabelTranslation={t('auth.register.brand.step4.employees')}
                                        handleChangeParams={(_, e) => changeAutoCompleteHandler(_, e, 'nbEmployees')}/>
                </div>
                <div className="col-md-6 my-2">
                    <CustomAutocomplete dataArray={country ?? []}
                                        defaultArray={currentCompany?.contact?.country as string}
                                        isWithImage={true} inputLabel="country"
                                        isMultiple={false}
                                        isRequired={true}
                                        photoLabel="countryFlag"
                                        queryFilterKey="country"
                                        inputLabelTranslation={t('auth.register.brand.step2.country')}
                                        handleChangeParams={(_, e) => changeAutoCompleteHandler(_, e, 'country')}/>
                </div>
                <div className="col-12 mt-4">
                    <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep} isBackHidden={true}
                                    handleBackStep={handleBackStep}/>
                </div>
            </div>
        </Form>
    );
};
