import React from 'react';
import {Link} from 'react-router-dom';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {formatDate} from '../../../../shared/functions/Functions';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FiInfo} from 'react-icons/fi';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';
import {IPicture} from '../../../../model/shared/IPicture';
import {useTranslation} from 'react-i18next';
import {IReportStats} from '../../../../model/campaign/Campaign';

interface ReportingPerCampaignHeaderProps {
    isAddonsOnly: boolean;
    isLongTerm: boolean;
    overdueStatus: string;
    statusClass: string;
    campaignImages: IPicture;
    companyName: string;
    offerId: number;
    totalCalendar: string;
    totalPosts: string;
    reportStats: IReportStats;
    title: string;
    postingDeadline: string;
    campaignId: number;
    isInfluencerSide?: boolean;

}

const ReportingPerCampaignHeader = ({
                                        isLongTerm,
                                        isAddonsOnly,
                                        statusClass,
                                        overdueStatus,
                                        totalPosts,
                                        totalCalendar,
                                        reportStats,
                                        campaignImages,
                                        offerId,
                                        companyName,
                                        title,
                                        postingDeadline,
                                        campaignId,
                                        isInfluencerSide,
                                    }: ReportingPerCampaignHeaderProps) => {
    const {t} = useTranslation();
    return (
        <>
            <div className="d-flex flex-wrap justify-content-between">
                <div className="d-flex align-items-center justify-content-between w-md-auto w-100 flex-wrap mb-2 mb-md-0">
                    <Link to={`/${isInfluencerSide ? 'influencer' : 'agency'}/campaign/${campaignId}/overview`}
                          className="d-flex align-items-center">
                        <CustomImageHandler
                            classes="campaign-icon img-cover"
                            photoPath={campaignImages?.thumbnail as string}
                            thumbnailPath={campaignImages?.thumbnail as string}
                            altTag="Company logo"/>
                        <div className="d-flex flex-column ml-2">
                            <div className="d-flex align-items-center">
                                                                <span className="font-weight-semi-bold text-dark">
                                                                    {title ?? '-'}
                                                                </span>
                                {isLongTerm &&
                                    <div
                                        className={'custom-badge long-term ml-1 text-dark'}>
                                        {t(`brand.campaign.briefing.overview_card.linked`)}
                                    </div>}
                                {isAddonsOnly &&
                                    <div
                                        className={'custom-badge additional ml-1 text-dark'}>
                                        {t('influencer.offer_management.modal.additional')}
                                    </div>}
                            </div>
                            <span className="text-muted">
                                                                {companyName ?? '-'}
                                                            </span>

                        </div>
                    </Link>
                </div>
                <div className="px-1">
                    <div
                        className="pr-2 text-primary font-weight-semi-bold">
                        {formatDate(postingDeadline)}
                    </div>
                    <div>
                        {t('influencer.reporting.dueDate')}
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip
                                            id="impressions-tooltip">
                                            {t(`influencer.reporting.dueDateTooltip`)}
                                        </Tooltip>}>
                            <FiInfo size={14}
                                    className="cursor-pointer ml-1"/>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="px-1">
                    <div
                        className="pr-2 text-primary font-weight-semi-bold">
                        {totalCalendar ?? 0} / {totalPosts ?? 0}
                    </div>
                    <div
                        className="pr-2">{t('influencer.reporting.reported')}</div>
                </div>
                <div className="px-1">
                    <div
                        className="pr-2 text-primary font-weight-semi-bold">
                        {priceSplitter(reportStats?.impressions)}
                    </div>
                    <div className="pr-2">
                        {t('influencer.reporting.impressions')}
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip
                                            id="impressions-tooltip">
                                            {t(`influencer.reporting.impressionsTooltip`)}
                                        </Tooltip>}>
                            <FiInfo size={14}
                                    className="cursor-pointer ml-1"/>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="px-1">
                    <div
                        className="pr-2 text-primary font-weight-semi-bold">
                        {priceSplitter(+reportStats?.reach)}
                    </div>
                    <div className="pr-2">
                        {t('influencer.reporting.reach')}
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip
                                            id="impressions-tooltip">
                                            {t(`influencer.reporting.reachTooltip`)}
                                        </Tooltip>}>
                            <FiInfo size={14}
                                    className="cursor-pointer ml-1"/>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="px-1">
                    <div
                        className="pr-2 text-primary font-weight-semi-bold">
                        {priceSplitter(+reportStats?.engagements)}
                    </div>
                    <div className="pr-2">
                        {t('influencer.reporting.engagements')}
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip
                                            id="impressions-tooltip">
                                            {t(`influencer.reporting.engagementsTooltip`)}
                                        </Tooltip>}>
                            <FiInfo size={14}
                                    className="cursor-pointer ml-1"/>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="px-1">
                    <div
                        className="pr-2 text-primary font-weight-semi-bold">
                        {priceSplitter(+reportStats?.comments || 0)}
                    </div>
                    <div
                        className="pr-2">{t('influencer.reporting.answers')}</div>
                </div>
                <div className="px-1">
                    <div
                        className="pr-2 text-primary font-weight-semi-bold">
                        {priceSplitter(+reportStats?.shares || 0)}
                    </div>
                    <div
                        className="pr-2">{t('influencer.reporting.shares')}</div>
                </div>
                <div className="px-1">
                    <div
                        className="pr-2 text-primary font-weight-semi-bold">
                        {priceSplitter(+reportStats?.totalClicks || 0)}
                    </div>
                    <div
                        className="pr-2">{t('influencer.reporting.swipeUps')}</div>
                </div>
                <div className="text-end">
                    <div className="text-muted">
                        Offer ID: #{offerId}
                    </div>
                    <span className={`status ${statusClass} mt-1`}>
                        {overdueStatus}
                    </span>
                </div>
            </div>

        </>
    );
};

export default React.memo(ReportingPerCampaignHeader)
