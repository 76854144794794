import {TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Button, Card, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {CustomAutocomplete} from '../../../../../shared/components/Autocomplete';
import {UploadImage} from '../../../../../shared/components/UploadImage';
import {requests} from '../../../../../utils';
import {BASE_URL} from '../../../../../utils/endpoints';
import {ErrorToast, SuccessToast} from '../../../../../utils/toasters';
import {ICampaignGoals} from '../../../../brand/campaign/briefing/dto/ICreateCampaign';
import {InfluencerService} from '../../../service';
import {ICollaborationData} from './HistoryTab';

interface Props {
    isEdit?: boolean;
    handleCancel?: () => void;
    handleDeleteSelectedImage?: (imageId?: number) => void;
    handleDelete?: () => void;
    selectedCollaboration?: ICollaborationData;
    collaborationFormRef?: any;
}

const initialCurrentCollaboration = {
    campaignName: '',
    brandName: '',
    campaignGoal: 0,
    id: 0,
    campaignCollaborationHistoryImages: []
}

const CreateHistoryTab = ({
                              isEdit = false,
                              handleCancel,
                              handleDelete,
                              handleDeleteSelectedImage,
                              selectedCollaboration,
                              collaborationFormRef
                          }: Props) => {
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    const {t} = useTranslation();
    const [currentCollaboration, setCurrentCollaboration] = useState<ICollaborationData>(initialCurrentCollaboration);
    const [campaignGoals, setCampaignGoals] = useState<ICampaignGoals[]>([]);
    const selectedGoal = campaignGoals?.find(goal => goal.id === ((currentCollaboration?.campaignGoal as ICampaignGoals)?.id ?? Number(currentCollaboration?.campaignGoal))) as unknown as []
    const changeCampaignFilterHandler = (paramKey: string, paramValue: string | string[] | number) => {
        setCurrentCollaboration(prev => ({...prev, [paramKey]: +paramValue}))
    };
    const submitHandler = async () => {
        const formData = new FormData();
        if (!!files?.length) {
            for (const item of files) {
                formData.append('files', item as unknown as Blob)
            }
        }

        try {
            if (isEdit) {

                formData.append('body', JSON.stringify({
                    brandName: currentCollaboration?.brandName,
                    campaignName: currentCollaboration?.campaignName,
                    campaignGoal: (currentCollaboration?.campaignGoal as ICampaignGoals)?.id
                }));
                const {data} = await InfluencerService.updateCollaborationHistory(formData, currentCollaboration?.id ?? 0);
                if (data) {
                    handleCancel && handleCancel();
                    SuccessToast(t('general.toasts.successCollaborationHistoryUpdate'))
                }
            } else {
                formData.append('body', JSON.stringify(currentCollaboration));
                const {data} = await InfluencerService.createCollaborationHistory(formData);
                if (data) {
                    handleCancel && handleCancel();
                    SuccessToast(t('general.toasts.successCollaborationHistoryCreate'))
                }
            }
        } catch (error) {
            ErrorToast(error);
            return error;
        }
    }
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setCurrentCollaboration(prev => ({...prev, [name]: value}))
    }
    useEffect(() => {
        requests.get(BASE_URL + 'admin/campaigns/goal', {isActive: true, perPage: 0}).then(response => {
            setCampaignGoals(response.data);
        }).catch(error => ErrorToast(error));
    }, [])
    useEffect(() => {
        if (selectedCollaboration?.id) {
            setCurrentCollaboration(selectedCollaboration);
        }
        // eslint-disable-next-line
    }, [JSON.stringify(selectedCollaboration)]);
    return (
        <Col md={6} lg={4} className="pl-md-0 mb-3">
            <Card>
                <Card.Body>
                    <UploadImage numberOfFiles={files?.length} saveFile={setFiles} isDeleteHidden={!isEdit}
                                 isPreviewArray={isEdit}
                                 deleteSelectedImage={handleDeleteSelectedImage}
                                 previewImage={currentCollaboration?.campaignCollaborationHistoryImages ?? []}
                                 classes="px-0" isSmall acceptedFormats="image/jpeg, image/png, image/jpg"
                                 maxSize={Number(process.env.REACT_APP_CAMPAIGN_COVER_MAX_FILE_SIZE)}
                                 isMultiple/>
                    <TextField
                        label={t('brand.campaign.briefing.campaign_name')}
                        variant="outlined"
                        size="small"
                        focused={!!currentCollaboration?.campaignName?.length}
                        value={currentCollaboration?.campaignName ?? ''}
                        name="campaignName"
                        onChange={changeHandler}
                        className="mt-2 w-100"
                    />
                    <TextField
                        label={t('auth.register.brand.step1.brandName')}
                        variant="outlined"
                        value={currentCollaboration?.brandName ?? ''}
                        focused={!!currentCollaboration?.brandName?.length}
                        name="brandName"
                        onChange={changeHandler}
                        size="small"
                        className="my-2 w-100"
                    />
                    <CustomAutocomplete dataArray={campaignGoals} isWithImage={false} inputLabel="title"
                                        disableClearable={false}
                                        defaultArray={selectedGoal ?? null}
                                        changeEventKey="name" queryFilterKey="campaignGoal"
                                        inputLabelTranslation={t('brand.campaign.briefing.campaign_goals')}
                                        handleChangeParams={changeCampaignFilterHandler}/>
                    <div className={`w-100 d-flex justify-content-${isEdit ? 'between' : 'end'}`}>
                        {isEdit && <div className="d-flex justify-content-between w-100">
                            <Button variant="outline-primary" className="mt-2" onClick={handleCancel}>
                                {t('general.button.cancel')}
                            </Button>
                            <Button variant="outline-danger" className="mt-2 mr-2" onClick={handleDelete}>
                                {t('general.button.delete')}
                            </Button>
                        </div>}
                        <Button variant="primary" className="mt-2" onClick={submitHandler} ref={collaborationFormRef}>
                            {t(`general.button.${isEdit ? 'update' : 'add'}`)}
                        </Button>
                    </div>

                </Card.Body>
            </Card>
        </Col>
    );
};

export default CreateHistoryTab;
