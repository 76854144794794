import React, {SetStateAction} from 'react';
import {Button, Modal, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {IPicture} from '../../../../model/shared/IPicture';
import ReportingFooterButtons from '../../../agency/reporting/components/ReportingFooterButtons';
import {ITempData} from './AddReportingView';
import ReportingBody from './modals/components/ReportingBody';
import ReportingHeader from './modals/components/ReportingHeader';
import ReportingLeftCard from './modals/components/ReportingLeftCard';
import ReportingScreenshotExample from './modals/components/ReportingScreenshotExample';
import ReportingScreenshots from './modals/components/ReportingScreenshots';
import YourContent from './modals/components/YourContent';
import {IReportingUpdate} from './ReportingCampaignCard';

interface Props {
    errorFile: boolean;
    isAdditional: boolean;
    isModalOpened: boolean;
    handleCloseModal: () => void;
    handleSubmitData: () => void;
    createPosting: IReportingUpdate;
    setCreatePosting: React.Dispatch<SetStateAction<IReportingUpdate>>;
    tempData: ITempData;
}

const AddReportingStep2Modal = ({
                                    errorFile,
                                    isAdditional,
                                    isModalOpened,
                                    handleSubmitData,
                                    handleCloseModal,
                                    createPosting,
                                    setCreatePosting,
                                    tempData,
                                }: Props) => {
    const {t} = useTranslation();
    const selectedOfferItem = {
        ...tempData,
        offerItem: isAdditional ? (tempData as any)?.offerItem : tempData?.offers?.[0]?.offerItems?.find(offerItem => offerItem?.id === createPosting?.offerItemId),
    };
    const isFeedbackLoopDisabled = selectedOfferItem?.campaign?.reviewDays === 0 || selectedOfferItem?.campaign?.reviewDays === null
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, valueAsNumber} = event.target;
        setCreatePosting(prev => ({...prev, [name]: valueAsNumber}));
    };    
    return (
        <>
            <Modal
                show={isModalOpened}
                centered
                size="xl"
                onHide={handleCloseModal}
                keyboard={false}
            >
                <Modal.Body>
                    <Row className={'overflow-auto max-h-60vh-sm'}>
                        <ReportingHeader updateData={createPosting}
                                         selectedOfferItem={selectedOfferItem as any}/>
                        <ReportingLeftCard selectedOfferItem={selectedOfferItem as any} updateData={createPosting}
                                           setUpdateData={setCreatePosting}/>
                        <div className="col-md-9">
                            <ReportingScreenshotExample
                                classes={'w-fit-content px-md-5'}
                                platformItemId={selectedOfferItem?.offerItem?.socialPlatform?.id}
                                />
                            <ReportingScreenshots
                                status={''}
                                errorFile={errorFile}
                                setUpdateData={setCreatePosting}
                                reportItems={[]}/>
                            {!isFeedbackLoopDisabled && !isAdditional && <ReportingBody
                                socialPlatform={isAdditional ? selectedOfferItem?.offerItem?.socialPlatform : selectedOfferItem?.campaign?.offers?.[0]?.offerItems
                                    ?.find(offer => offer.id === createPosting?.offerItemId)?.socialPlatform}
                                updateData={createPosting} handleChange={handleChange}/>}
                            <YourContent setUpdateData={setCreatePosting} classes={'px-0'}
                                         isFeedbackLoop={isAdditional ? false : selectedOfferItem?.campaign?.isReviewed}
                                         externalLinks={createPosting?.externalLinks ?? []}
                                         isAdditional={isAdditional}
                                         setSelectedOfferItem={() => {}}
                                         selectedAttachments={[]}
                                         hideExternalSearch
                                         isUploadPossible
                            />

                        </div>
                    </Row>
                    <div
                        className="border-top d-flex align-items-center justify-content-end d-md-none">
                        <div className="pt-3">
                            <Button variant="outline-primary" onClick={handleCloseModal} className={'mr-2'}>
                                {t('general.button.cancel')}
                            </Button>

                            <Button variant="primary" onClick={handleSubmitData}>
                                {t(`general.button.submit`)}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={'d-none d-md-flex'}>
                    <Button variant="outline-primary" onClick={handleCloseModal}>
                        {t('general.button.cancel')}
                    </Button>

                    <Button variant="primary" onClick={handleSubmitData}>
                        {t(`general.button.submit`)}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddReportingStep2Modal;
